/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon/android-icon-192x192.png';
import '!file-loader?name=[name].[ext]!./images/favicon/favicon-32x32.png';
import '!file-loader?name=[name].[ext]!./images/favicon/favicon-96x96.png';
import '!file-loader?name=[name].[ext]!./images/favicon/favicon-16x16.png';
import '!file-loader?name=[name].[ext]!./images/favicon/favicon.ico';
import '!file-loader?name=[name].[ext]!./images/b.png';
import '!file-loader?name=[name].[ext]!./images/paper-plane-2563.svg';
import '!file-loader?name=[name].[ext]!./images/b-48.png';
import '!file-loader?name=[name].[ext]!./images/microsoft.png';
import '!file-loader?name=[name].[ext]!./images/gdpr.png';
import '!file-loader?name=[name].[ext]!./images/ccpa.png';
import '!file-loader?name=[name].[ext]!./images/fox.png';
import '!file-loader?name=[name].[ext]!./images/fox2.png';
import '!file-loader?name=[name].[ext]!./images/og-home.jpeg';
import '!file-loader?name=[name].[ext]!./images/fox2-eu.png';
import '!file-loader?name=[name].[ext]!./images/fox2-white.png';
import '!file-loader?name=[name].[ext]!./images/google-calendar.png';
import '!file-loader?name=[name].[ext]!./images/outlook-calendar.png';
import '!file-loader?name=[name].[ext]!./images/fox2-black.png';
import '!file-loader?name=[name].[ext]!./images/fox2-purple.svg';
import '!file-loader?name=[name].[ext]!./images/reverse.svg';
import '!file-loader?name=[name].[ext]!./images/fox3.png';
import '!file-loader?name=[name].[ext]!./images/fox4.png';
import '!file-loader?name=[name].[ext]!./images/fox5.png';
import '!file-loader?name=[name].[ext]!./images/icons/search.svg';
import '!file-loader?name=[name].[ext]!./images/integrations/calendly.png';
import '!file-loader?name=[name].[ext]!./images/integrations/tremendous.png';
import '!file-loader?name=[name].[ext]!./images/integrations/salesforce.png';
import '!file-loader?name=[name].[ext]!./images/integrations/salesforce.svg';
import '!file-loader?name=[name].[ext]!./images/integrations/dscout.png';
import '!file-loader?name=[name].[ext]!./images/integrations/dscout-white.png';
import '!file-loader?name=[name].[ext]!./images/integrations/zoom.png';
import '!file-loader?name=[name].[ext]!./images/integrations/zoom2.svg';
import '!file-loader?name=[name].[ext]!./images/integrations/msteams.png';
import '!file-loader?name=[name].[ext]!./images/integrations/meet.png';
import '!file-loader?name=[name].[ext]!./images/caret-right.svg';
import '!file-loader?name=[name].[ext]!./images/caret-right-purple.svg';
import '!file-loader?name=[name].[ext]!./images/location-menu.png';
import '!file-loader?name=[name].[ext]!./images/testimonials/gary-reynolds.jpg';
import '!file-loader?name=[name].[ext]!./images/logo.svg';
import '!file-loader?name=[name].[ext]!./images/eu-flag.svg';
import '!file-loader?name=[name].[ext]!./images/us-flag.svg';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// init  DataDog
const service = 'panelfox-ui';
const local_dev = 'development';
const source_version = process.env.SOURCE_VERSION || local_dev;

// DataDog expects "prod" as production env (dscout convetion)
let datadog_env = process.env.DEPLOY_ENV || local_dev;
datadog_env = datadog_env == 'production' ? 'prod' : datadog_env;
let is_prod = datadog_env == 'prod';

// it's ok to hardcode the Id & clientToken because they're visible in the browser anyways
// but don't load DataDog in local dev (DataDog won't initialize if the keys aren't set)
let dd_application_id = '';
let dd_client_token = '';
if (datadog_env !== local_dev) {
    dd_application_id = 'e110963c-90e8-42c5-bdae-c7cf1d7bb334';
    dd_client_token = 'pub19d8e12dec28b92f869201825f238253';
}

datadogRum.init({
    applicationId: dd_application_id,
    clientToken: dd_client_token,
    site: 'datadoghq.com',
    service: service,
    env: datadog_env,
    version: source_version,
    sampleRate: is_prod ? 5 : 0,
    premiumSampleRate: is_prod ? 0 : 0, // 0 to disable. Earlier it was 100 for prod.
    trackInteractions: is_prod,
    defaultPrivacyLevel: 'mask',
    sessionReplaySampleRate: is_prod ? 0 : 0, // 0 to disable. Earlier it was 100 for prod.
    trackResources: is_prod,
    trackLongTasks: is_prod,
    trackSessionAcrossSubdomains: is_prod,
    useCrossSiteSessionCookie: is_prod
});
datadogRum.startSessionReplayRecording();

datadogLogs.init({
    clientToken: dd_client_token,
    site: 'datadoghq.com',
    service: service,
    env: datadog_env,
    version: source_version,
    forwardConsoleLogs: ['error']
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = messages => {
    ReactDOM.render(
        <Provider store={store}>
            <LanguageProvider messages={messages}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>,
        MOUNT_NODE
    );
};

if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['./i18n', 'containers/App'], () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render(translationMessages);
    });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
    new Promise(resolve => {
        resolve(import('intl'));
    })
        .then(() => Promise.all([import('intl/locale-data/jsonp/en.js'), import('intl/locale-data/jsonp/de.js')])) // eslint-disable-line prettier/prettier
        .then(() => render(translationMessages))
        .catch(err => {
            throw err;
        });
} else {
    render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
    require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
