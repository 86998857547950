import React from 'react';
import { Flex, Box } from 'rebass';
import { useParams } from 'react-router-dom';
import { FiChevronRight, FiMail, FiMessageCircle, FiUserCheck, FiUsers } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import { Helmet } from 'react-helmet';
import studies_service from 'services/studies';
import { useToasts } from 'react-toast-notifications';
import useQuery from 'hooks/useQuery';
import helpers from 'utils/helpers';
import services from 'services/services';
import Card, { CardBody, CardHeader, CardIcon } from 'components/Card';
import RichIconBackground, { colors } from 'components/RichIconBackground';
import Progress from 'components/Progress';
import Grid from 'components/Grid';
import Table from 'components/Table';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';
import cache from 'utils/cache';

/**
 * Study reports table component. Displays a table with screeners and their stats.
 *
 * @param {Object} param0
 * @param {Object} param0.screeners Screeners
 */
function StudyReportsTable({ screeners }) {
    if (screeners.length === 0) return <Box>No screeners</Box>;

    return (
        <Table>
            <Table.Head>
                <Table.Tr>
                    <Table.Th style={{ paddingLeft: '0px' }}>Screener Title</Table.Th>
                    <Table.Th>Started</Table.Th>
                    <Table.Th>Completed</Table.Th>
                    <Table.Th>Qualified</Table.Th>
                    <Table.Th>Disqualified</Table.Th>
                    <Table.Th>Incomplete</Table.Th>
                </Table.Tr>
            </Table.Head>
            <Table.Body>
                {screeners.map(screener => (
                    <Table.Tr key={screener.id}>
                        <Table.Td style={{ paddingLeft: '0px' }} className="text-primary bold">
                            {screener.title}
                        </Table.Td>
                        <Table.Td className="text-primary medium">{helpers.numberFormat(screener.all)}</Table.Td>
                        <Table.Td className="text-primary medium">
                            {helpers.numberFormat(screener.complete)}{' '}
                            <span className="fs-12 color-text-secondary normal">
                                ({helpers.getPercentage(screener.complete, screener.all, 2)}%)
                            </span>
                        </Table.Td>
                        <Table.Td className="text-primary medium">
                            {helpers.numberFormat(screener.qualified)}{' '}
                            <span className="fs-12 color-text-secondary normal">
                                ({helpers.getPercentage(screener.qualified, screener.all, 2)}%)
                            </span>
                        </Table.Td>
                        <Table.Td className="text-primary medium">
                            {helpers.numberFormat(screener.disqualified)}{' '}
                            <span className="fs-12 color-text-secondary normal">
                                ({helpers.getPercentage(screener.disqualified, screener.all, 2)}%)
                            </span>
                        </Table.Td>
                        <Table.Td className="text-primary medium">
                            {helpers.numberFormat(screener.incomplete)}{' '}
                            <span className="fs-12 color-text-secondary normal">
                                ({helpers.getPercentage(screener.incomplete, screener.all, 2)}%)
                            </span>
                        </Table.Td>
                    </Table.Tr>
                ))}
            </Table.Body>
        </Table>
    );
}

/**
 * Report log component. Displays a log with a progress bar and percentage.
 *
 * @param {Object} param0
 * @param {number} param0.total Total number
 * @param {number} param0.portion Portion number. This number will be used to calculate the percentage.
 * @param {string} param0.title Title of the log
 */
export function ReportLog({ total, portion, title }) {
    const percentage = helpers.getPercentage(portion, total, 2);

    return (
        <Box
            className="report-log grid items-end"
            style={{ gap: '8px', gridTemplateColumns: '1fr minmax(60px, auto)' }}
            py="8px"
            minHeight="62px"
        >
            <Box>
                <Box as="p" className="fs-accent-14 color-text-secondary">
                    {title}
                </Box>
                <Progress style={{ margin: '6px 0' }} value={percentage} />
            </Box>
            <Box className="flex items-end flex-col">
                <Box as="span" mb="2px" className="fs-body-12 nowrap color-text-secondary report-log-actual-number">
                    ({helpers.numberFormat(portion)})
                </Box>
                <Box as="p" className="fs-accent-14 text-primary nowrap">
                    {percentage}%
                </Box>
            </Box>
        </Box>
    );
}

/**
 * Report card component. Displays a card with an icon, title, total number.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {keyof colors} props.color
 * @param {React.ReactNode} props.icon
 * @param {string} props.title
 * @param {string} props.totalTitle
 * @param {number} props.total
 * @param {React.ReactNode} props.extra
 */
export function ReportCard({ children, color, icon, title, totalTitle, total, extra }) {
    return (
        <Card className="report-card">
            <CardIcon icon={<RichIconBackground color={color}>{icon}</RichIconBackground>} />
            <CardHeader className="flex justify-between items-top">
                <Box as="p" className="fs-title-16 text-primary">
                    {title}
                </Box>
                {extra}
            </CardHeader>
            <CardBody>
                <Box
                    className="flex items-center justify-between border-solid border-b-1 border-0 border-light"
                    height="62px"
                    py="12px"
                >
                    <Box as="p" className="fs-accent-14 color-text-secondary">
                        {totalTitle}
                    </Box>
                    <Box as="p" className="fs-headline-24 text-primary">
                        {helpers.numberFormat(total)}
                    </Box>
                </Box>
                {children}
            </CardBody>
        </Card>
    );
}

function StudyReportsPage({ studyId }) {
    const { addToast } = useToasts();
    const { data: reportingSummary, isLoading: isLoadingReportingSummary } = useQuery({
        queryFn: async () => studies_service.getStudyReportingSummary(studyId),
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    if (isLoadingReportingSummary || !reportingSummary) return <Box>Loading...</Box>;

    return (
        <>
            <Grid md={4} sm={2} xs={1} style={{ gap: '16px' }}>
                <ReportCard
                    color="purple"
                    icon={<FiUserCheck />}
                    title="Participation"
                    totalTitle="Respondents"
                    total={reportingSummary.overview.all}
                >
                    <ReportLog
                        title="Invited"
                        total={reportingSummary.overview.all}
                        portion={reportingSummary.overview.invited}
                    />
                    <ReportLog
                        title="Screener Answered"
                        total={reportingSummary.overview.invited}
                        portion={reportingSummary.overview.answered}
                    />
                    <ReportLog
                        title="Scheduled"
                        total={reportingSummary.overview.invited}
                        portion={reportingSummary.overview.scheduled}
                    />
                    <ReportLog
                        title="Paid"
                        total={reportingSummary.overview.invited}
                        portion={reportingSummary.overview.paid}
                    />
                </ReportCard>
                <ReportCard
                    color="blue"
                    icon={<FiMail />}
                    title="Emails"
                    totalTitle="Sent"
                    total={reportingSummary.email_stats.sent}
                    extra={
                        <Link to={`/studies/${studyId}/emails`} className="fs-accent-14">
                            Go to Campaigns
                            <FiChevronRight style={{ margin: 0, padding: '2px', height: '20px', width: '20px' }} />
                        </Link>
                    }
                >
                    <ReportLog
                        title="Opened"
                        total={reportingSummary.email_stats.sent}
                        portion={reportingSummary.email_stats.open}
                    />
                    <ReportLog
                        title="Bounced"
                        total={reportingSummary.email_stats.sent}
                        portion={reportingSummary.email_stats.bounced}
                    />
                    <ReportLog
                        title="Spam"
                        total={reportingSummary.email_stats.sent}
                        portion={reportingSummary.email_stats.complaint}
                    />
                    <ReportLog
                        title="Failed"
                        total={reportingSummary.email_stats.sent}
                        portion={reportingSummary.email_stats.failed}
                    />
                </ReportCard>
                <ReportCard
                    color="yellow"
                    icon={<FiMessageCircle />}
                    title="SMS"
                    totalTitle="Sent"
                    total={reportingSummary.sms_stats.total}
                    extra={
                        <Link to={`/studies/${studyId}/emails`} className="fs-accent-14">
                            Go to Campaigns
                            <FiChevronRight style={{ margin: 0, padding: '2px', height: '20px', width: '20px' }} />
                        </Link>
                    }
                >
                    <ReportLog
                        title="Successful"
                        total={reportingSummary.sms_stats.total}
                        portion={reportingSummary.sms_stats.sent}
                    />
                    <ReportLog
                        title="Failed"
                        total={reportingSummary.sms_stats.total}
                        portion={reportingSummary.sms_stats.failed}
                    />
                </ReportCard>
                <ReportCard
                    color="green"
                    icon={<FiUsers />}
                    title="Screeners"
                    totalTitle="Started"
                    total={reportingSummary.screeners_overview.all}
                >
                    <ReportLog
                        title="Completed"
                        total={reportingSummary.screeners_overview.all}
                        portion={reportingSummary.screeners_overview.complete}
                    />
                    <ReportLog
                        title="Qualified"
                        total={reportingSummary.screeners_overview.complete}
                        portion={reportingSummary.screeners_overview.qualified}
                    />
                    <ReportLog
                        title="Disqualified"
                        total={reportingSummary.screeners_overview.complete}
                        portion={reportingSummary.screeners_overview.disqualified}
                    />
                    <ReportLog
                        title="Incomplete"
                        total={reportingSummary.screeners_overview.all}
                        portion={reportingSummary.screeners_overview.incomplete}
                    />
                </ReportCard>
            </Grid>
            <Box as="h2" mb={2} className="text-primary fs-headline-20">
                Screeners
            </Box>
            <StudyReportsTable screeners={reportingSummary.screeners} />
        </>
    );
}

const withLayout = Component => props => {
    const { id: studyId } = useParams();
    const { data: study, isLoading: isStudyLoading } = useQuery({
        queryFn: async () => studies_service.getStudy(studyId),
        onSuccess: study => cache.set(`study.${studyId}`, study),
        defaultData: cache.get(`study.${studyId}`),
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    return (
        <AppPageWrapper>
            <Helmet>
                <title>Reports Summary</title>
            </Helmet>
            <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                <Box>
                    <BreadcrumbBack to="/studies">Back to all studies</BreadcrumbBack>
                    <BreadcrumbTitle title={study && study.title} isLoading={isStudyLoading} />
                </Box>
            </AppPageWrapperSectionHeader>
            <Flex>
                <StudyPageSubNav study_id={studyId} study={study} />
            </Flex>
            <AppPageWrapperSectionBody>
                <Box mx={4} my={3} width="100%">
                    <Component {...props} study={study} studyId={studyId} />
                </Box>
            </AppPageWrapperSectionBody>
        </AppPageWrapper>
    );
};

export default withLayout(StudyReportsPage);
