import styled from 'styled-components';
import React from 'react';
import { Flex, Box, Button } from 'rebass';
import { Label, Input, Radio, Checkbox, Select, Switch } from '@rebass/forms';
import {
    FiChevronRight,
    FiFileText,
    FiMail,
    FiCalendar,
    FiHash,
    FiTag,
    FiUsers,
    FiToggleLeft,
    FiLayers,
    FiX,
    FiPlus,
    FiCheck,
    FiMove
} from 'react-icons/fi';
import helpers from 'utils/helpers';
import OutsideClickHandler from 'react-outside-click-handler';
import DatePicker from 'react-datepicker';
import TooltipIcon from 'components/TooltipIcon';
import ReactTooltip from 'react-tooltip';
import { List, arrayMove } from 'react-movable';
import { PROPERTY_TABLE_ORDER } from 'utils/constants';
import InputSearch from 'components/InputSearch';
import cloneDeep from 'lodash/cloneDeep';
import DragIcon from 'images/icons/drag';

function wrapperStyle(options, extra = {}) {
    return {
        position: 'absolute',
        top: options.positionTop,
        bottom: options.positionBottom,
        left: options.positionLeft,
        right: options.positionRight,
        minWidth: options.width ? options.width : '260px',
        maxWidth: options.maxWidth ? options.maxWidth : '310px',
        background: 'white',
        borderRadius: '8px',
        zIndex: '150',
        // border: '1px solid black',
        // maxHeight: '420px',
        //boxShadow: 'rgba(36, 18, 77, 0.05) 0px 0px 0px 1px, rgba(36, 18, 77, 0.1) 0px 2px 10px 0px',
        boxShadow:
            '0px 17px 100px rgba(0, 0, 0, 0.06), 0px 5.125px 30.1471px rgba(0, 0, 0, 0.0390953), 0px 2.12866px 12.5216px rgba(0, 0, 0, 0.03), 0px 0.769896px 4.5288px rgba(0, 0, 0, 0.0209047)',
        fontSize: '14px',
        textAlign: 'left',
        ...extra
    };
}

function scrollableStyle(options) {
    //console.log('options',options)
    return {
        overflow: options.overflow,
        position: 'relative',
        maxHeight:
            options && options.listType === 'time' ? '170px' : options && options.height ? options.height : '400px',
        padding: '4px 0'
    };
}

const bottomStickyButtonWrapperStyle = {
    textAlign: 'center',
    background: 'white',
    //height: '42px',
    padding: '12px 16px',
    borderTop: '1px solid #E7E8F1',
    borderRadius: '0 0 8px 8px'
};
const DropdownLabel = styled.div`
    padding: 4px 16px;
    font-weight: 400;
    vertical-align: top;
    color: #666;
    background: #eee;
    font-size: 12px;
`;
const DropdownItem = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    padding: 0 16px;
    margin: 2px 0;
    min-height: 32px;
    line-height: 32px;
    font-weight: 400;
    vertical-align: top;
    color: ${props => (props.disabled ? '#929DB0' : '#001233')};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    label {
        cursor: pointer;
    }
    .nice-dd-item-suffix {
        flex-shrink: 0;
    }

    &.dd-item-columns .nice-dd-item-suffix > span {
        margin: 8px 0 0 8px !important;
    }

    &.dd-item-columns .dd-label svg {
        vertical-align: top;
        margin: 8px 6px 0 0;
        stroke-width: 1.5px;
        font-size: 16px;
    }

    button {
        vertical-align: top;
    }

    input[type='radio'] {
        cursor: pointer;
        margin: 5px 8px 0 0;
        vertical-align: top;
    }

    input[type='text']:not(.datepicker-input) {
        margin: 0 0 0 4px;
    }

    &:hover {
        color: ${props => (props.disabled ? '#666' : 'rgb(98, 0, 255)')};
        background: #fafafa;
    }
`;

/**
 * Renders a dropdown with a list of items.
 *
 * @param {Object} props - The component props.
 * @param {string} props.value - The selected value.
 * @param {string} props.value_secondary - The secondary value.
 * @param {number} props.height - The height of the dropdown.
 * @param {Array} props.items - The list of dropdown items.
 * @param {React.ReactNode} props.items.title - The title of the dropdown item.
 * @param {React.ReactNode} props.items.icon - The icon of the dropdown item.
 * @param {React.ReactNode} props.items.suffix - The suffix of the dropdown item.
 * @param {React.ReactNode} props.items.onDisabledClick - The function called when the disabled item is clicked.
 * @param {Function} props.onChange - The function called when the value changes.
 * @param {Function} props.onReorder - The function called when the items are reordered.
 * @param {Function} props.onClose - The function called when the dropdown is closed.
 * @param {boolean} props.canReorder - Determines if the items can be reordered.
 * @param {boolean} props.narrowHeight - Determines if the dropdown has a narrow height.
 * @param {boolean} props.revealEllipsis - Determines if the ellipsis should be revealed on hover.
 * @param {React.CSSProperties} props.style - The style of the dropdown.
 */
class NiceDropdown extends React.Component {
    constructor(props) {
        super(props);
        // onChange
        // id
        // value
        // items
        this.state = {
            value: this.props.value,
            value_secondary: this.props.value_secondary,
            searchKeyword: '',
            innerHeight: this.props.height
        };
        this.onChangeSearchKeyword = this.onChangeSearchKeyword.bind(this);
        this.onClickDone = this.onClickDone.bind(this);
        this.getItemIcon = this.getItemIcon.bind(this);
        this.renderList = this.renderList.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);

        this.parentRef = React.createRef();
    }

    onChangeSearchKeyword(e) {
        this.setState(
            {
                searchKeyword: e.target.value
            },
            () => {
                this.updateHeight();
            }
        );
    }

    getItemIcon(item) {
        let icon = '';

        if (item.icon) {
            return item.icon;
        }

        switch (item.type) {
            case 'date':
                icon = <FiCalendar />;
                break;
            case 'number':
                icon = <FiHash />;
                break;
            case 'tag':
                icon = <FiTag />;
                break;
            case 'study':
                icon = <FiLayers />;
                break;
            case 'boolean':
                icon = <FiToggleLeft />;
                break;
            case 'email':
                icon = <FiMail />;
                break;
            case 'screener':
                icon = <FiFileText />;
                break;
            case 'checkbox':
            case 'dropdown':
            case 'string':
            case 'Person':
            case 'panelist':
                icon = <FiUsers />;
                break;
            default:
        }
        return icon;
    }

    renderList() {
        let renderItems = [];
        let itemsList = [];
        this.props.items.forEach(item => {
            if (!item) {
                return;
            }

            if (item && item.hide == true) {
                return;
            }

            if (item.submenu && item.submenu.length == 0) {
                console.warn(`*** Panelfox: hiding NiceDropdown ${item.title} item because 0 submenu items`);
                return;
            }

            if (
                this.state.searchKeyword.length == 0 ||
                item.label == true ||
                helpers.isNeedleInHaystack(this.state.searchKeyword, item.title)
            ) {
                itemsList.push(item);
            }
        });

        if (itemsList.length > 0) {
            renderItems = (
                <List
                    values={itemsList}
                    onChange={({ oldIndex, newIndex }) => {
                        if (this.props.onReorder) {
                            const newOrder = this.rearrangeDragDropItems(oldIndex, newIndex);
                            let newOrder_toSave = [];
                            newOrder.forEach((item, index) => {
                                newOrder_toSave.push(item.id);
                            });
                            this.props.onReorder(newOrder_toSave);
                        }
                    }}
                    renderList={({ children, props }) => <div {...props}>{children}</div>}
                    renderItem={({ value, index, props, isDragged, isSelected }) => {
                        let item = value;

                        const colorStyle = {};
                        if (item.color) {
                            colorStyle.color = item.color;
                        }
                        if (this.state.value && this.state.value == item.id) {
                            colorStyle.background = 'rgba(98, 0, 255)';
                            colorStyle.color = '#fff';
                        }

                        if (item.label && item.label == true) {
                            return <DropdownLabel className="ellipsis">{item.title}</DropdownLabel>;
                        } else if (item.divider && item.divider == true) {
                            return <hr style={{ margin: '8px 0' }} />;
                        } else {
                            return (
                                <div {...props} id={`list-item-row-${item.id}`} key={item.id}>
                                    <div
                                        style={{ position: 'relative' }}
                                        className={item.submenu && item.submenu.length > 0 ? 'nice-submenu' : ''}
                                    >
                                        <DropdownItem
                                            className={`dd-parent dd-item-columns ${
                                                this.props.narrowHeight ? 'dd-item-narrow' : ''
                                            }`}
                                            onClick={e => {
                                                if (item.disabled) {
                                                    return item.onDisabledClick ? item.onDisabledClick(e) : null;
                                                }
                                                if (item.onClick) {
                                                    item.onClick(e);
                                                } else {
                                                    if (this.props.onChange) {
                                                        this.props.onChange(item.id, e);
                                                    }
                                                }
                                                this.props.onClose();
                                            }}
                                            disabled={item.disabled}
                                            style={{ ...colorStyle }}
                                        >
                                            <span
                                                className={`dd-label ${
                                                    this.props.revealEllipsis ? 'ellipsis-with-hover' : 'ellipsis'
                                                }`}
                                            >
                                                {this.renderReorderElement(
                                                    this.props.canReorder,
                                                    isDragged,
                                                    'dd-move-list'
                                                )}
                                                {this.getItemIcon(item)}{' '}
                                                {item.title_wrapper_style ? (
                                                    <span style={item.title_wrapper_style}>{item.title}</span>
                                                ) : (
                                                    item.title
                                                )}
                                            </span>
                                            {item.suffix ? (
                                                <span className="nice-dd-item-suffix">{item.suffix}</span>
                                            ) : (
                                                ''
                                            )}
                                            {item.feature_launched_at &&
                                                helpers.newFeatureTag(item.feature_launched_at, {
                                                    margin: '1px 0 0 4px'
                                                })}
                                            {item.tooltip && (
                                                <TooltipIcon for={`tooltip-${item.id}`} margin="8px 0 0 8px" />
                                            )}
                                        </DropdownItem>
                                        <ReactTooltip id={`tooltip-${item.id}`} effect="solid" place="top">
                                            {item.tooltip}
                                        </ReactTooltip>
                                        {item.submenu && item.submenu.length > 0 && (
                                            <>
                                                <div className="nice-submenu-content-wrapper">
                                                    <div className="nice-submenu-content">
                                                        {item.submenu.map((submenu_item, idx) => {
                                                            return (
                                                                <DropdownItem
                                                                    className={`dd-item-columns ellipsis`}
                                                                    onClick={e => {
                                                                        if (submenu_item.disabled) {
                                                                            return submenu_item.onDisabledClick
                                                                                ? submenu_item.onDisabledClick(e)
                                                                                : null;
                                                                        }
                                                                        if (submenu_item.onClick) {
                                                                            submenu_item.onClick(e);
                                                                        } else {
                                                                            if (this.props.onChange) {
                                                                                this.props.onChange(submenu_item.id, e);
                                                                            }
                                                                        }
                                                                        this.props.onClose();
                                                                    }}
                                                                    disabled={submenu_item.disabled}
                                                                    style={colorStyle}
                                                                    key={`dd-item-${idx}`}
                                                                >
                                                                    <span className="dd-label">
                                                                        {this.getItemIcon(submenu_item)}{' '}
                                                                        {submenu_item.title}
                                                                    </span>{' '}
                                                                    {submenu_item.feature_launched_at &&
                                                                        helpers.newFeatureTag(
                                                                            submenu_item.feature_launched_at,
                                                                            { margin: '1px 0 0 4px' }
                                                                        )}
                                                                    {submenu_item.tooltip && (
                                                                        <TooltipIcon
                                                                            for={`tooltip-${submenu_item.id}`}
                                                                            margin="4px 0 0 4px"
                                                                        />
                                                                    )}
                                                                </DropdownItem>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        }
                    }}
                />
            );
        } else {
            renderItems.push(
                <div
                    style={{
                        textAlign: 'center',
                        margin: '8px 0'
                    }}
                >
                    <strong> 0 results </strong>{' '}
                </div>
            );
        }
        return renderItems;
    }

    renderRadio() {
        let renderItems = [];
        this.props.items.forEach(item => {
            renderItems.push(
                <div>
                    <Label alignItems="center" className="text-primary" sx={{ marginBottom: 0 }}>
                        <Radio
                            checked={this.state.value == item.id}
                            onClick={() => {
                                this.setState({
                                    value: item.id
                                });
                            }}
                        />
                        {item.title}{' '}
                    </Label>{' '}
                </div>
            );
        });
        return renderItems;
    }

    rearrangeDragDropItems(oldIndex, newIndex) {
        let allItems = this.props.items;
        allItems = arrayMove(allItems, oldIndex, newIndex);
        return allItems;
    }

    renderReorderElement(canReorder, isDragged, buttonClass) {
        /*
            we use data-movable-handle so that <List> doesn't use the entire component to drag/drop
            and so that the rest of the children can be clickable

            if we want to disable D&D, we still need to render data-movable-handle but mark it as hidden
        */
        return (
            <Box sx={{ display: canReorder ? 'inline-block' : 'none' }} className="va-top">
                <Button
                    data-movable-handle
                    type="button"
                    variant="transparent-icon-nobg"
                    className={`${buttonClass}`}
                    tabIndex={-1}
                    style={{
                        cursor: isDragged ? 'grabbing' : 'grab',
                        //background: isDragged ? 'rgba(234, 222, 252, 0.95)' : 'inherit',
                        // this is a little hack so that user can click on the <label> to trigger the toggle
                        // when the element is NOT drag-droppable
                        display: 'block'
                    }}

                    /*onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    console.log('okkk', e)
                }}*/
                >
                    <DragIcon style={{ width: '14px', fill: '#929DB0' }} />
                </Button>
            </Box>
        );
    }

    renderToggles() {
        let renderItems = [];

        let checkboxClass = 'size-tiny';

        let itemsList = [];
        this.props.items.forEach(item => {
            if (
                this.state.searchKeyword.length == 0 ||
                helpers.isNeedleInHaystack(this.state.searchKeyword, item.title)
            ) {
                itemsList.push(item);
            }
        });

        if (itemsList.length > 0) {
            renderItems = (
                <List
                    values={itemsList}
                    onChange={({ oldIndex, newIndex }) => {
                        //console.log(oldIndex, newIndex)
                        if (this.props.onReorder) {
                            const newOrder = this.rearrangeDragDropItems(oldIndex, newIndex);
                            let newOrder_toSave = [];
                            // update the "table_order" property (rearrangeDragDropItems only sorts the array)
                            newOrder.forEach((item, index) => {
                                let item_new = item;
                                // name it table_order instead of order, because order is used for ordering
                                // properties on the panelist profile page
                                // note: onReorder is also used by Study page.. and PROPERTY_TABLE_ORDER is not necessary
                                item_new[PROPERTY_TABLE_ORDER] = index;
                                newOrder_toSave.push(item_new);
                            });
                            this.props.onReorder(newOrder_toSave);
                        }
                    }}
                    renderList={({ children, props }) => <div {...props}>{children}</div>}
                    renderItem={({ value, index, props, isDragged, isSelected }) => {
                        let item = value;

                        return (
                            <div {...props} id={`toggle-item-row-${item.id}`}>
                                <Flex
                                    sx={{
                                        margin: '0 8px 0',
                                        justifyContent: 'space-between',
                                        minHeight: '32px',
                                        lineHeight: '32px'
                                    }}
                                >
                                    <Box
                                        style={{ alignSelf: 'stretch', marginTop: '3px' }}
                                        className={`dd-label ${
                                            item.checked ? 'text-primary' : 'text-secondary-dark'
                                        } dd-item-columns ${
                                            this.props.revealEllipsis ? 'ellipsis-with-hover' : 'ellipsis'
                                        }`}
                                    >
                                        {this.renderReorderElement(this.props.canReorder, isDragged, 'dd-move-toggle')}
                                        {/*this.getItemIcon(item)*/}
                                        {item.title}
                                    </Box>
                                    <Box style={{ flexShrink: 0, alignSelf: 'stretch' }}>
                                        <Switch
                                            id={`nice-dd-id-${item.id}`}
                                            className={checkboxClass}
                                            style={{ margin: '12px 8px 0 0', verticalAlign: 'top' }}
                                            checked={item.checked}
                                            onClick={e => {
                                                this.setState({
                                                    value: item.id
                                                });

                                                this.props.onChange(item.id, !item.checked);
                                            }}
                                        />
                                    </Box>
                                </Flex>
                            </div>
                        );
                    }}
                />
            );
        } else {
            renderItems.push(<Box m={3}>0 results</Box>);
        }

        return renderItems;
    }

    renderCheckboxes() {
        let renderItems = [];

        let checkboxClass = 'size-tiny';
        let labelFontSize = '14px';

        this.props.items.forEach((item, key) => {
            if (
                this.state.searchKeyword.length == 0 ||
                helpers.isNeedleInHaystack(this.state.searchKeyword, item.title)
            ) {
                if (item.label && item.label == true) {
                    renderItems.push(
                        <DropdownLabel key={key} className="ellipsis">
                            {item.title}
                        </DropdownLabel>
                    );
                } else {
                    const checked = this.props.renderDoneButton ? this.state.value.includes(item.id) : item.checked;
                    let checkboxElement = (
                        <Checkbox
                            className={checkboxClass}
                            checked={checked}
                            onChange={e => this.onChangeCheckbox(item, e.target.checked)}
                        />
                    );

                    renderItems.push(
                        <DropdownItem key={key}>
                            <Label
                                alignItems="center"
                                className="text-primary"
                                style={{
                                    margin: 0,
                                    flexGrow: 1,
                                    fontSize: labelFontSize,
                                    cursor: 'pointer',
                                    fontWeight: 400
                                }}
                            >
                                <Box style={{ flexShrink: 0 }}>{checkboxElement}</Box>
                                <Box className="ellipsis dd-label" mt={'2px'}>
                                    {/*this.getItemIcon(item)*/}
                                    {item.title}
                                </Box>
                            </Label>
                        </DropdownItem>
                    );
                }
            }
        });

        if (renderItems.length == 0) {
            renderItems.push(
                <Box key="-1" m={3}>
                    0 results
                </Box>
            );
        }

        return renderItems;
    }

    renderPanelFiltersRadio() {
        let renderItems = [];
        this.props.items.forEach(item => {
            const subItems = [];
            if (item.id == this.state.value) {
                // console.log('item',item);
                let local_num_values;
                if (item.num_values == -1) {
                    local_num_values = this.state.value_secondary.length == 0 ? 1 : this.state.value_secondary.length;
                } else {
                    local_num_values = item.num_values;
                }

                for (let i = 0; i < local_num_values; i++) {
                    const local_element = [];
                    if (['after_date', 'before_date', 'on_date'].includes(item.id)) {
                        subItems.push(
                            <div
                                style={{
                                    margin: '2px 0 0 29px'
                                }}
                            >
                                <DatePicker
                                    className="theme-input datepicker-input"
                                    selected={
                                        this.state.value_secondary[i] instanceof Date
                                            ? this.state.value_secondary[i]
                                            : ''
                                    }
                                    onChange={date => {
                                        const vs = cloneDeep(this.state.value_secondary);
                                        vs[i] = date;
                                        this.setState({
                                            value_secondary: vs
                                        });
                                    }}
                                    // inline
                                />
                            </div>
                        );
                    } else {
                        if (i != 0 && item.num_values == -1) {
                            subItems.push(
                                <Box className="text-primary" sx={{ margin: '4px 0 6px 4px' }}>
                                    {['is_not', 'does_not_contain'].includes(this.state.value) ? 'and' : 'or'}
                                </Box>
                            );
                        }
                        if (this.props.column.type == 'dropdown' || this.props.column.type == 'checkbox') {
                            const dd_options = this.props.column.definition.map((def_item, idx) => (
                                <option value={def_item.value} key={`dropdown-item-${idx}`}>
                                    {def_item.label}
                                </option>
                            ));
                            dd_options.unshift(<option></option>);

                            local_element.push(
                                <Select
                                    ref={input => {
                                        this.compareValueInput = input;
                                    }}
                                    mr={0}
                                    sx={{
                                        flexGrow: 1
                                    }}
                                    value={this.state.value_secondary[i]}
                                    onChange={e => {
                                        const vs = cloneDeep(this.state.value_secondary);
                                        vs[i] = e.target.value;
                                        this.setState({
                                            value_secondary: vs
                                        });
                                        // removed on purpose because people wanted to add multiple selects at once
                                        //this.onClickDone();
                                    }}
                                >
                                    {dd_options}
                                </Select>
                            );
                        } else {
                            local_element.push(
                                <Input
                                    ref={input => {
                                        this.compareValueInput = input;
                                    }}
                                    mr={0}
                                    sx={{
                                        flexGrow: 1
                                    }}
                                    value={this.state.value_secondary[i]}
                                    onKeyDown={e => {
                                        if (helpers.isKeyDownEnter(e)) {
                                            this.onClickDone();
                                        }
                                    }}
                                    onChange={e => {
                                        const vs = cloneDeep(this.state.value_secondary);
                                        vs[i] = e.target.value;
                                        this.setState({
                                            value_secondary: vs
                                        });
                                    }}
                                />
                            );
                        }

                        if (local_num_values > 1 && item.num_values == -1) {
                            local_element.push(
                                <Box>
                                    <Button
                                        type="button"
                                        ml={2}
                                        variant="secondary-gray"
                                        className="secondary-icon"
                                        onClick={e => {
                                            const value_secondary = cloneDeep(this.state.value_secondary);
                                            // remove index from array
                                            value_secondary.splice(i, 1);
                                            this.setState({ value_secondary });
                                            //const doNotClose = true;
                                            //this.onClickDone(doNotClose);
                                        }}
                                    >
                                        <FiX />
                                    </Button>
                                </Box>
                            );
                        }

                        subItems.push(<Flex className="children-flex-grow">{local_element}</Flex>);
                    }
                }

                if (item.num_values == -1) {
                    subItems.push(
                        <Box>
                            <Button
                                type="button"
                                variant="link"
                                onClick={() => {
                                    const value_secondary = cloneDeep(this.state.value_secondary);
                                    value_secondary.push('');
                                    this.setState({ value_secondary });
                                }}
                                mt={1}
                                fontWeight={400}
                            >
                                {/* if using "is_not" then we show AND instead of OR */}+ add{' '}
                                {['is_not', 'does_not_contain'].includes(this.state.value) ? '"and"' : '"or"'}
                            </Button>
                        </Box>
                    );
                }
            }

            if (item.for_type.includes(this.props.column.type)) {
                renderItems.push(
                    <DropdownItem disabled={item.disabled} style={{ display: 'block' }}>
                        <div
                            onClick={() => {
                                if (item.disabled) {
                                    return item.onDisabledClick ? item.onDisabledClick() : null;
                                }
                                this.setState(
                                    {
                                        value: item.id
                                    },
                                    () => {
                                        if (this.compareValueInput) {
                                            this.compareValueInput.focus();
                                        }
                                    }
                                );
                            }}
                        >
                            <Label alignItems="center" className="text-primary for-radio" sx={{ marginBottom: 0 }}>
                                <Radio checked={this.state.value == item.id} />
                                {item.title}
                            </Label>
                        </div>
                        {subItems && subItems.length ? <div style={{ marginTop: '8px' }}>{subItems}</div> : ''}
                    </DropdownItem>
                );
            }
        });
        return renderItems;
    }

    onClickDone(doNotClose) {
        console.log('onClickDone', doNotClose, this.state.value, this.state.value_secondary);
        this.props.onChange(this.state.value, this.state.value_secondary);
        if (!doNotClose) {
            this.props.onClose();
        }
    }

    renderDoneButton() {
        return (
            <Flex style={bottomStickyButtonWrapperStyle}>
                <Button variant="secondary" mr={3} sx={{ flex: 1 }} onClick={() => this.props.onClose()}>
                    Cancel
                </Button>
                <Button variant="primary" mr={0} sx={{ flex: 1 }} onClick={() => this.onClickDone()}>
                    Done
                </Button>
            </Flex>
        );
    }

    renderBottomStickyButton() {
        return <div style={bottomStickyButtonWrapperStyle}>{this.props.renderBottomStickyButton}</div>;
    }

    componentDidMount() {
        if (this.props.listType == 'panel-filters-radios') {
            // between has 2 inputs, and becomes buggy
            // console.log('LENGTH', this.state.value_secondary)
            if (this.compareValueInput) {
                this.compareValueInput.focus();
            }
        }

        if (this.props.adjustHeight) {
            this.updateHeight();
            window.addEventListener('scroll', this.updateHeight, { passive: true });
            window.addEventListener('resize', this.updateHeight, { passive: true });
        }
    }

    componentWillUnmount() {
        if (this.props.adjustHeight) {
            window.removeEventListener('scroll', this.updateHeight);
            window.removeEventListener('resize', this.updateHeight);
        }
    }

    updateHeight() {
        const dims = this.parentRef.current.getBoundingClientRect();
        const bottomPadding = 32;
        const minHeight = 64;
        // this is the window height minus the padding; defines the max allowed location of the bottom of the dropdown
        let windowHeightTheshold = window.innerHeight - bottomPadding;
        //console.log('NC bottom: ', dims.bottom, 'window innerHeight threshold:', windowHeightTheshold)
        const allowedParentHeight = this.getMaxParentHeight();
        //console.log('dims', dims, window.innerHeight)
        let yOffset = dims.bottom - window.innerHeight;
        //console.log('windowHeightTheshold', windowHeightTheshold)
        //console.log('allowedParentHeight', allowedParentHeight)

        if (dims.bottom > windowHeightTheshold || yOffset < allowedParentHeight) {
            let currentInnerHeight = this.state.innerHeight;
            currentInnerHeight = currentInnerHeight ? currentInnerHeight : allowedParentHeight;
            currentInnerHeight = parseInt(currentInnerHeight) - yOffset - bottomPadding;

            let newInnerHeight = currentInnerHeight;
            if (currentInnerHeight > allowedParentHeight) {
                newInnerHeight = allowedParentHeight;
            } else if (currentInnerHeight < minHeight) {
                newInnerHeight = minHeight;
            } else {
                newInnerHeight = currentInnerHeight;
            }

            this.setState({ innerHeight: newInnerHeight });
        }
    }

    onChangeCheckbox(item, checked) {
        if (this.props.renderDoneButton) {
            // do not call onChange until Done button is pressed
            let study_ids_included_checked = this.state.value;
            if (checked) {
                study_ids_included_checked.push(item.id);
            } else {
                // remove, if exists
                const studyIdFound = study_ids_included_checked.indexOf(item.id);
                if (studyIdFound > -1) {
                    study_ids_included_checked.splice(studyIdFound, 1);
                }
            }

            this.setState({ value: study_ids_included_checked });
        } else {
            this.setState({
                value: item.id
            });
            this.props.onChange(item.id, checked);
        }
    }

    getSearchHeight() {
        let search_height = 0;
        let search_els = document.getElementsByClassName('nice-dropdown-search');
        if (this.props.showSearch && search_els && search_els[0]) {
            const search_dims = search_els[0].getBoundingClientRect();
            search_height = search_dims.height;
            //console.log(search_dims, search_els);
        }
        return search_height;
    }

    getMaxParentHeight() {
        if (this.props.height) {
            return this.props.height;
        } else {
            const maxParentHeight = 420;
            return maxParentHeight - this.getSearchHeight();
        }
    }

    render() {
        let renderItems = [];
        switch (this.props.listType) {
            case 'radio':
                renderItems = this.renderRadio();
                break;
            case 'checkbox':
                renderItems = this.renderCheckboxes();
                break;
            case 'toggle':
                renderItems = this.renderToggles();
                break;
            case 'panel-filters-radios':
                renderItems = this.renderPanelFiltersRadio();
                break;
            case 'list':
            default:
                renderItems = this.renderList();
        }

        return (
            <div
                ref={this.parentRef}
                style={wrapperStyle(
                    {
                        listType: this.props.listType,
                        positionLeft: this.props.positionLeft ? this.props.positionLeft : 'auto',
                        positionRight: this.props.positionRight ? this.props.positionRight : 'auto',
                        positionTop: this.props.positionTop ? this.props.positionTop : '36px',
                        positionBottom: this.props.positionBottom ? this.props.positionBottom : 'auto',
                        width: this.props.width,
                        maxWidth: this.props.maxWidth
                    },
                    this.props.style
                )}
            >
                <OutsideClickHandler onOutsideClick={this.props.onClose}>
                    {this.props.showSearch && (
                        <Box sx={{ padding: '8px 12px', borderBottom: '1px solid #e9ecef' }}>
                            <InputSearch
                                className="nice-underlined nice-dropdown-search"
                                value={this.state.searchKeyword}
                                onChange={this.onChangeSearchKeyword}
                                placeholder="Search"
                                autoFocus={1}
                            />
                        </Box>
                    )}{' '}
                    {/*
                        for "toggles" those include scrollable/drag-drop so we want to apply style
                        to the internal <List> element
                    */}
                    <div
                        style={scrollableStyle({
                            listType: this.props.listType,
                            height: this.state.innerHeight,
                            overflow: this.props.hasSubmenu ? 'visible' : 'auto' // auto does the scrolling
                        })}
                    >
                        {' '}
                        {renderItems}{' '}
                    </div>{' '}
                    {this.props.listType === 'radio' ||
                    this.props.listType === 'panel-filters-radios' ||
                    this.props.renderDoneButton === true
                        ? this.renderDoneButton()
                        : ''}
                    {this.props.renderBottomStickyButton ? this.renderBottomStickyButton() : ''}
                </OutsideClickHandler>
            </div>
        );
    }
}

NiceDropdown.displayName = 'NiceDropdown';

export default NiceDropdown;
