import xhr from 'xhr.js';
import auth from 'services/auth';

const integrations = {
    createZoomToken: async code => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/integrations/zoom/token?account_id=${account_id}`,
            {},
            {
                withCredentials: true,
                params: {
                    code
                }
            }
        );
        return res.data;
    },
    disconnectZoomAccount: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/integrations/zoom/disconnect?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    disconnectCalendlyAccount: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/integrations/calendly/disconnect?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    disconnectTremendousAccount: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/integrations/tremendous/disconnect?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getSalesforceAuthRedirectUrl: async domain => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(
            `/integrations/salesforce/authorize-redirect?account_id=${account_id}&domain=${domain}`,
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    disconnectSalesforceAccount: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/integrations/salesforce/disconnect?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getSalesforceProperties: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/integrations/salesforce/contact-properties?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Map Salesforce properties to Panelfox properties
     *
     * @param {Object} body
     * @param {Object} body.map The mapping of Salesforce properties to Panelfox properties
     * @param {Object} params
     * @param {string} params.panelId The panel ID
     * @param {string} params.salesforceListviewId The Salesforce listview ID
     */
    mapSalesforceToPanelfox: async ({ map }, { panelId, salesforceListviewId }) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/integrations/salesforce/map-properties`,
            {
                map
            },
            {
                withCredentials: true,
                params: {
                    account_id,
                    salesforce_listview_id: salesforceListviewId,
                    panel_id: panelId
                }
            }
        );
        return res.data;
    },
    runSalesforceSync: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/integrations/salesforce/run?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    /**
     * Get Salesforce listviews
     */
    getSaleforceListviews: async () => {
        const accountId = auth.getAccountId();
        const res = await xhr.get('/integrations/salesforce/listviews', {
            withCredentials: true,
            params: {
                account_id: accountId
            }
        });
        return res.data;
    },
    /**
     * Get Salesforce integration info
     */
    getSaleforceIntegrationInfo: async () => {
        const accountId = auth.getAccountId();
        const res = await xhr.get('/integrations/salesforce/info', {
            withCredentials: true,
            params: {
                account_id: accountId
            }
        });
        return res.data;
    }
};

export default integrations;
