import styled from 'styled-components';

export default styled.div.attrs(props => ({
    padding: props.padding || '16px',
    width: props.width || 'auto'
}))`
    border-radius: 8px;
    margin: 0 auto;
    padding: ${props => props.padding};
    width: ${props => props.width};
    box-shadow: 0px 17px 100px rgba(0, 0, 0, 0.06), 0px 5.125px 30.1471px rgba(0, 0, 0, 0.0390953),
        0px 2.12866px 12.5216px rgba(0, 0, 0, 0.03), 0px 0.769896px 4.5288px rgba(0, 0, 0, 0.0209047);
    overflow: hidden;

    &.purple {
        box-shadow: rgba(98, 0, 255, 0.8) 0px 0px 0px 1px, rgba(98, 0, 255, 0.3) 0px 16px 24px -8px;
    }
`;
