/*
 * StudiesPage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import xhr from 'xhr.js';
import ReactModal from 'react-modal';
import NiceModal from 'components/NiceModal';
import { withToastManager } from 'react-toast-notifications';
import analytics from 'utils/analytics';

import { FiSun, FiPlus, FiBookOpen, FiLayers } from 'react-icons/fi';
import NiceWrapper from 'components/NiceWrapper';
import InputSearch from 'components/InputSearch';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import { Select } from '@rebass/forms';
import StudyItem from 'components/Study/StudyItem';
import NewStudy from 'components/Study/NewStudy';
import { STUDY_STATUS, STUDY_STATUS_MAP } from 'utils/constants';
import service_studies from 'services/studies';
import services from 'services/services';

import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';

import NiceSelect from 'components/NiceSelect';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import { ActionButton } from 'components/Button/ActionButton';

class StudiesPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            studies_paginated: {
                data: [],
                total: 0,
                current_page: 1,
                last_page: 0,
                per_page: 0
            },
            filters: {
                searchKeyword: '',
                status: 'active',
                tag_id: ''
            },
            loading: true,
            studies_loading_more: false,
            tags: [],
            mapTagIdToLabel: {},
            allStudies: []
        };

        this.onStudyFilterChange = this.onStudyFilterChange.bind(this);
        this.handleOpenModalNewStudy = this.handleOpenModalNewStudy.bind(this);
        this.handleCloseModalNewStudy = this.handleCloseModalNewStudy.bind(this);
        this.getStudies = this.getStudies.bind(this);
        this.loadMoreStudies = this.loadMoreStudies.bind(this);
        this.handleNewStudyKeyPress = this.handleNewStudyKeyPress.bind(this);
        this.getCopyStudyDropdownItems = this.getCopyStudyDropdownItems.bind(this);
        this.getCopyStudyDropdownValueMap = this.getCopyStudyDropdownValueMap.bind(this);
        this.xhrGetAllStudies = this.xhrGetAllStudies.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleOpenModalNewStudy() {
        this.setState({ showModal: true });
    }

    handleCloseModalNewStudy() {
        this.setState({ showModal: false });
    }

    onStudyFilterChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        let { filters } = this.state;
        filters[name] = value;
        this.setState({ filters }, () => {
            this.setState({ loading: true });
            this.getStudies();
        });
    }

    loadMoreStudies() {
        this.setState({ studies_loading_more: true });
        const next_page = this.state.studies_paginated.current_page + 1;
        this.getStudies(next_page);
    }

    getStudyTags() {
        const { toastManager } = this.props;
        service_studies
            .getStudyTags()
            .then(tags => {
                let mv2l = {};
                // allow the ability to "Reset" this filter
                tags.unshift({
                    title: 'All Tags',
                    id: ''
                });
                tags.forEach(t => {
                    mv2l[t.id] = t.title;
                });

                this.setState({ tags, mapValueToLabel: mv2l });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getStudies(page) {
        const { toastManager } = this.props;

        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        this.saveDelay = setTimeout(
            function() {
                xhr.get(`/studies?account_id=${this.props.auth.account.id}&pagination=1&page=${page}`, {
                    withCredentials: true,
                    params: {
                        search_kw: this.state.filters.searchKeyword,
                        status: this.state.filters.status,
                        tag_id: this.state.filters.tag_id
                    }
                })
                    .then(res_obj => {
                        const studies_paginated_new = res_obj.data;

                        // if this is page 2, 3, 4, etc then lets add the response to the current dataset
                        // if its page 1, then just render this data
                        if (page > 1) {
                            const { studies_paginated } = this.state;
                            const new_data = studies_paginated_new.data;
                            studies_paginated_new.data = studies_paginated.data.concat(new_data);
                        }

                        this.setState({
                            studies_paginated: studies_paginated_new,
                            loading: false,
                            studies_loading_more: false
                        });
                    })
                    .catch(error => {
                        const errorText = services.parseAndTrackXhrErrors(error);

                        toastManager.add(errorText, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
            }.bind(this),
            500
        );
    }

    handleNewStudyKeyPress(e) {
        let keynum;

        if (window.event) {
            // IE
            keynum = e.keyCode;
        } else if (e.which) {
            // Netscape/Firefox/Opera
            keynum = e.which;
        }

        //console.log(e.target.nodeName)

        if (['INPUT', 'TEXTAREA'].includes(e.target.nodeName)) {
            // do nothing
        } else {
            // if its letter "n"
            if (keynum == 78) {
                analytics.track('keyboard-shortcut-study-create');
                this.handleOpenModalNewStudy();
                // this prevents "n" from being the first typed character in the Title <input />
                e.stopPropagation();
                e.preventDefault();
            }

            //console.log(keynum, e.target.nodeName, e)
        }
    }

    getCopyStudyDropdownItems() {
        let items = [];
        items.push({
            id: 0,
            title: 'Do not copy'
        });
        this.state.allStudies.forEach(s => {
            let study_title = s.title;
            if (study_title && study_title.length > 20) {
                study_title = study_title.substr(0, 20) + '...';
            }
            items.push({
                id: s.id,
                title: `${study_title}`
            });
        });
        return items;
    }

    getCopyStudyDropdownValueMap() {
        let items = {};
        items[0] = 'Do not copy';

        this.state.allStudies.forEach(s => {
            items[s.id] = `${s.title}`;
        });
        return items;
    }

    xhrGetAllStudies() {
        const { toastManager } = this.props;
        service_studies
            .getStudiesSimple()
            .then(studies => {
                studies.sort(function(a, b) {
                    return b.id - a.id;
                });
                //console.log(screeners);
                this.setState({
                    allStudies: studies
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    handleChange(id) {
        var s = document.getElementById('new-form-copy-study-id');
        s.value = id;
    }

    componentDidMount() {
        this.getStudies();
        this.getStudyTags();
        this.xhrGetAllStudies();
        document.addEventListener('keydown', this.handleNewStudyKeyPress);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleNewStudyKeyPress);
    }

    render() {
        const elements = [];
        const that = this;
        this.state.studies_paginated.data.forEach(study => {
            elements.push(
                <StudyItem
                    auth={that.props.auth}
                    key={study.id}
                    {...study}
                    onTagClick={tag_id => {
                        this.onStudyFilterChange({ target: { name: 'tag_id', value: tag_id } });
                    }}
                />
            );
        });

        return (
            <AppPageWrapper>
                <Helmet>
                    <title>Studies</title>
                </Helmet>

                <AppPageWrapperSectionHeader className="experience" style={{ paddingTop: '24px' }}>
                    <Box>
                        <H1>Studies</H1>
                        <Box className="experience-description">
                            Fly through recruiting, re-use workflows, and easily be on top of everything.{' '}
                            <a
                                href="#"
                                data-beacon-article="62fae0314181f8597e7d2a1f"
                                className="help-question"
                                style={{ display: 'inline-block', margin: 0 }}
                            >
                                ?
                            </a>
                        </Box>
                    </Box>

                    <Box mt={2}>
                        {/* <Button onClick={this.handleOpenModalNewStudy}>New Study<span style={{borderRadius: '3px', background: '#DECFFD', color: 'rgb(98,0,255)', fontSize: '10px', verticalAlign: 'top', lineHeight: '15px', padding: '0 4px', margin: '4px 0 0 8px', fontWeight: 500, display: 'inline-block'}}>N</span></Button> */}
                        <ActionButton
                            title="New Study"
                            action={this.handleOpenModalNewStudy}
                            keyLabel="N"
                            ml={3}
                            mr={0}
                        />
                    </Box>
                </AppPageWrapperSectionHeader>

                <AppPageWrapperSectionBody>
                    <Box flex="1 1 0%">
                        <Flex style={{ width: '100%' }} pt={3} px={4} pb={1} justifyContent="space-between">
                            <Box className="text-primary bold" sx={{ marginTop: '6px' }}>
                                {!this.state.loading && (
                                    <>
                                        {this.state.studies_paginated.total}{' '}
                                        {this.state.studies_paginated.total == 1 ? 'study' : 'studies'} matched
                                    </>
                                )}
                            </Box>
                            <Flex sx={{ flexDirection: 'row', gridGap: 3 }}>
                                <Box>
                                    <InputSearch
                                        name="searchKeyword"
                                        value={this.state.filters.searchKeyword}
                                        onChange={this.onStudyFilterChange}
                                    />
                                </Box>
                                {this.state.tags && this.state.tags.length > 0 && (
                                    <Box sx={{ position: 'relative' }}>
                                        <NiceSelect
                                            showSearch
                                            defaultLabel="Tags"
                                            defaultValue={this.state.filters.tag_id}
                                            positionRight="0px"
                                            items={this.state.tags}
                                            mapValueToLabel={this.state.mapValueToLabel}
                                            onChange={tag_id =>
                                                this.onStudyFilterChange({ target: { name: 'tag_id', value: tag_id } })
                                            }
                                        />
                                    </Box>
                                )}
                                <Box>
                                    <NiceSelect
                                        defaultLabel="Status"
                                        defaultValue={this.state.filters.status}
                                        positionRight="0px"
                                        items={STUDY_STATUS.map(ss => {
                                            return { id: ss.value, title: ss.title };
                                        })}
                                        mapValueToLabel={STUDY_STATUS_MAP}
                                        onChange={status =>
                                            this.onStudyFilterChange({ target: { name: 'status', value: status } })
                                        }
                                    />
                                </Box>
                                {/*<Box>
                                    <Select mr={0}>
                                        <option>Active</option>
                                        <option>Paused</option>
                                        <option>Completed</option>
                                    </Select>
                                </Box>*/}
                            </Flex>
                        </Flex>

                        {this.state.loading ? (
                            <div style={{ width: '100%' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        ) : elements.length > 0 ? (
                            <Box style={{ position: 'relative' }} mt={3}>
                                <ItemWrapper
                                    className="header-row"
                                    style={{ paddingLeft: '32px', paddingRight: '32px' }}
                                >
                                    <Flex style={{ width: '100%', gridGap: 32 }}>
                                        <Box width={7 / 20}>Title & Description</Box>
                                        <Box width={3 / 40}>Status</Box>
                                        <Box width={5 / 40}>Created</Box>
                                        <Box width={3 / 20}>Collaborators</Box>
                                        <Box width={7 / 20}>Progress</Box>
                                    </Flex>
                                </ItemWrapper>
                                {elements}
                                <div>
                                    {this.state.studies_paginated.data.length < this.state.studies_paginated.total && (
                                        <Button
                                            mt={4}
                                            mb={2}
                                            type="button"
                                            variant="secondary"
                                            onClick={this.loadMoreStudies}
                                        >
                                            {this.state.studies_loading_more ? 'Loading...' : 'Load More'}
                                        </Button>
                                    )}
                                </div>
                            </Box>
                        ) : (
                            <NiceWrapper style={{ padding: '32px', margin: '32px' }}>
                                <H1>
                                    <FiSun style={{ color: 'rgba(98,0,255,1)', fontSize: '22px' }} /> Automate your
                                    recruiting.
                                </H1>

                                <Box>
                                    Set up repeatable workflows, manage your participants, and track progress.
                                    <br />
                                    <br />
                                </Box>

                                <Button className="huge" onClick={this.handleOpenModalNewStudy}>
                                    <FiPlus style={{ marginRight: '8px' }} /> Create a study
                                </Button>
                            </NiceWrapper>
                        )}
                    </Box>
                </AppPageWrapperSectionBody>

                <NiceModal
                    isOpen={this.state.showModal}
                    shouldCloseOnOverlayClick
                    onRequestClose={this.handleCloseModalNewStudy}
                    title="Create a new study"
                    overflow="visible"
                >
                    <NewStudy
                        auth={this.props.auth}
                        onClose={this.handleCloseModalNewStudy}
                        items={this.getCopyStudyDropdownItems()}
                        mapValueToLabel={this.getCopyStudyDropdownValueMap()}
                        handleChange={id => {
                            this.handleChange(id);
                        }}
                    />
                </NiceModal>
            </AppPageWrapper>
        );
    }
}

export default withToastManager(StudiesPage);
