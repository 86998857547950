import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import ReactModalActions from 'components/ReactModalActions';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';

import { Flex, Box, Button } from 'rebass';
import { Input, Select, Label } from '@rebass/forms';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_accounts from 'services/accounts';
import { FiDollarSign, FiEdit } from 'react-icons/fi';
import moment from 'moment-timezone';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import InputSearch from 'components/InputSearch';
import { BalanceCard } from 'components/BalanceCard';
import Grid from 'components/Grid';
import { SMS_SUBSCRIPTION_UNLIMITED } from 'utils/constants';

import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';

function SettingsUsagePage(props) {
    const account = props.auth.account;

    const [emailUsageByMonth, setEmailUsageByMonth] = useState([]);
    const [smsUsageByMonth, setSmslUsageByMonth] = useState([]);
    const [tremendousBalance, setTremendousBalance] = useState(null);
    const [firstPartyBalance, setFirstPartyBalance] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { addToast } = useToasts();

    useEffect(() => {
        getAccountUsageXHR();
    }, []);

    function getAccountUsageXHR() {
        service_accounts.getUsage().then(usage_numbers => {
            setEmailUsageByMonth(usage_numbers['email_usage']);
            setSmslUsageByMonth(usage_numbers['sms_usage']);
            setTremendousBalance(usage_numbers['tremendous_balance']);
            setFirstPartyBalance(usage_numbers['first_party_balance']);
            setIsLoading(false);
        });
    }

    function renderEmailUsage() {
        let elements = [];
        emailUsageByMonth.forEach(monthUsage => {
            elements.push(
                <ItemWrapper className="text-primary" key={monthUsage.id}>
                    <Box width={1 / 4} style={{ marginTop: '6px' }}>
                        {moment(monthUsage.start_date).format('MMM YYYY')}
                    </Box>
                    <Box width={1 / 4}>{helpers.numberWithCommas(monthUsage.monthly_emails_count)}</Box>
                    <Box width={1 / 4} style={{ marginTop: '6px' }}>
                        {helpers.numberWithCommas(monthUsage.total_bulk_sent)}
                    </Box>
                    <Box width={1 / 4}>{helpers.numberWithCommas(monthUsage.extra)}</Box>
                </ItemWrapper>
            );
        });

        if (elements.length == 0) {
            elements.push(
                <Box fontSize={1} m={3}>
                    We dont have any usage recorded.
                </Box>
            );
        }

        return elements;
    }

    function renderSmsUsage() {
        let elements = [];
        smsUsageByMonth.forEach(monthUsage => {
            const accountSmsUsageUnlimited =
                account.subscriptions &&
                account.subscriptions[0] &&
                account.subscriptions[0].sms_sub_type == SMS_SUBSCRIPTION_UNLIMITED
                    ? true
                    : false;

            elements.push(
                <ItemWrapper className="text-primary" key={monthUsage.id}>
                    <Box width={1 / 5} style={{ marginTop: '6px' }}>
                        {moment(monthUsage.start_date).format('MMM YYYY')}
                    </Box>
                    <Box width={1 / 5} style={{ marginTop: '6px' }}>
                        {helpers.numberWithCommas(monthUsage.total_sent_segments)}
                    </Box>
                    <Box width={1 / 5} style={{ marginTop: '6px' }}>
                        {helpers.numberWithCommas(monthUsage.total_received_segments)}
                    </Box>

                    <Box width={1 / 5} style={{ marginTop: '6px' }}>
                        {helpers.numberWithCommas(monthUsage.total_segments)}
                    </Box>
                    <Box width={1 / 5}>
                        {accountSmsUsageUnlimited ? (
                            'Included'
                        ) : (
                            <>${helpers.numberWithCommas(monthUsage.segments_cost)}</>
                        )}
                    </Box>
                </ItemWrapper>
            );
        });

        if (elements.length == 0) {
            elements.push(
                <Box fontSize={1} m={3} key={'no-usage'}>
                    We dont have any usage recorded.
                </Box>
            );
        }

        return elements;
    }

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Usage</title>
                </Helmet>
                <Flex>
                    <SettingsPageSubNav />
                </Flex>
                <AppPageWrapperSectionHeader className="experience">
                    <Box>
                        <H1>Usage</H1>
                        <Box className="experience-description">Your usage reports.</Box>
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mx={4} my={4} width="100%">
                        {(tremendousBalance !== null || firstPartyBalance !== null) && (
                            <Grid md={2} sm={2}>
                                {tremendousBalance !== null && (
                                    <BalanceCard title="Tremendous Balance" icon={<FiDollarSign />} iconColor="blue">
                                        {helpers.renderCurrencyAmount(account.incentive_currency, tremendousBalance)}
                                    </BalanceCard>
                                )}
                                {firstPartyBalance !== null && (
                                    <BalanceCard title="Incentive Balance" icon={<FiDollarSign />} iconColor="purple">
                                        {helpers.renderCurrencyAmount(account.incentive_currency, firstPartyBalance)}
                                    </BalanceCard>
                                )}
                            </Grid>
                        )}
                        <H2 style={{ marginBottom: '16px' }}>Your email usage thoughout the month.</H2>
                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <ItemWrapper className="header-row">
                                <Box width={1 / 4}>Month</Box>
                                <Box width={1 / 4}>Base Emails</Box>
                                <Box width={1 / 4}>Used</Box>
                                <Box width={1 / 4}>Extra</Box>
                            </ItemWrapper>
                            {isLoading ? (
                                <Box m={3} style={{ minHeight: '24px' }}>
                                    <LoadingWrapper>
                                        <LoadingIndicator />
                                    </LoadingWrapper>
                                </Box>
                            ) : (
                                renderEmailUsage()
                            )}
                        </ListWrapper>

                        <H2 style={{ marginTop: '32px', marginBottom: '16px' }}>Your SMS usage thoughout the month.</H2>
                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <ItemWrapper className="header-row">
                                <Box width={1 / 5}>Month</Box>
                                <Box width={1 / 5}>Segments Sent</Box>
                                <Box width={1 / 5}>Segments Received</Box>
                                <Box width={1 / 5}>Total Segments</Box>
                                <Box width={1 / 5}>Cost</Box>
                            </ItemWrapper>
                            {isLoading ? (
                                <Box m={3} style={{ minHeight: '24px' }}>
                                    <LoadingWrapper>
                                        <LoadingIndicator />
                                    </LoadingWrapper>
                                </Box>
                            ) : (
                                renderSmsUsage()
                            )}
                        </ListWrapper>
                    </Box>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default SettingsUsagePage;
