import config from 'config';
import xhr from 'xhr.js';
import auth from 'services/auth';
import { INCENTIVE_PAYMENT_METHOD } from 'utils/constants';

const service_studies = {
    getStudies: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies?account_id=${account_id}`, {
            withCredentials: true
        });

        return res.data;
    },
    getStudiesSimple: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies-simple?account_id=${account_id}`, {
            withCredentials: true
        });

        return res.data;
    },
    getStudy: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}?account_id=${account_id}`, {
            withCredentials: true
        });

        return res.data;
    },
    getStudyPublic: async study_uuid => {
        const res = await xhr.get(`/studies/${study_uuid}/public`, {
            withCredentials: true
        });

        return res.data;
    },
    checkIfRespondentsScheduledInFuture: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/future-scheduled?account_id=${account_id}`, {
            withCredentials: true
        });

        return res.data;
    },
    getAllAccountScreeners: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/screeners-all?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getScreeners: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/screeners?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getScreener: async (study_id, screener_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/screeners/${screener_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getScreenerPublic: async (screener_uuid, is_preview, sp_uuid) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/screeners-public/${screener_uuid}?is_preview=${is_preview}&sp_uuid=${sp_uuid}`, {
            withCredentials: true
        });
        return res.data;
    },
    getStudyAvailabilityLinkViaScreener: async (screen_uuid, sp_uuid) => {
        const res = await xhr.get(`/screeners-public/${screen_uuid}/availability-link?sp_uuid=${sp_uuid}`, {
            withCredentials: true
        });
        return res.data;
    },
    deleteScreener: async (study_id, screener_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/studies/${study_id}/screeners/${screener_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    createScreener: async (study_id, title, description, copyScreenerId, panel_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/screeners?account_id=${account_id}`,
            { title, description, copy_screener_id: copyScreenerId, panel_id },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updateScreener: async (study_id, screener_id, screener_object) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/screeners/${screener_id}?account_id=${account_id}`,
            screener_object,
            {
                withCredentials: true
            }
        );
        return res.data;
    },

    studyGetUsers: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/users?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    studyAddUser: async (study_id, user_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/users/${user_id}?account_id=${account_id}`,
            {
                // no body params
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    studyRemoveUser: async (study_id, user_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/studies/${study_id}/users/${user_id}?account_id=${account_id}`, {
            withCredentials: true
        });

        return res.data;
    },
    studyUpdateUser: async ({ studyId, userId, data }) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/studies/${studyId}/users/${userId}?account_id=${account_id}`, data, {
            withCredentials: true
        });
        return res.data;
    },
    getStudyPerson: async (study_id, study_person_id, lock_action) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/study-people/${study_person_id}`, {
            withCredentials: true,
            params: {
                account_id: account_id,
                lock_action: lock_action
            }
        });
        return res.data;
    },
    getStudyPeopleByStudyState: async (study_id, study_state_id, page) => {
        page = page || 1;
        const account_id = auth.getAccountId();
        const res = await xhr.get(
            `/studies/${study_id}/study-states/${study_state_id}/study-people?account_id=${account_id}&page=${page}`,
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    /*
        options: {
            study_id: int
            segment: string
            search_keyword: string
            page: int
        }
    */
    getStudyPeople: async options => {
        const study_id = options.study_id;
        const page = options.page || 1;
        const pagination_limit = options.pagination_limit || 20;
        const segment = options.segment || 'new';
        const search_keyword = options.search_keyword || '';
        const filters = options.filters || '';
        const export_csv = options.export_csv || '';
        const account_id = auth.getAccountId();
        const sort = options.sort || '';

        const url =
            `/studies/${study_id}/study-people?account_id=${account_id}&page=${page}&segment=${segment}&search_keyword=${search_keyword}` +
            `&filters=${filters}&export_csv=${export_csv}&sort=${sort}&pagination_limit=${pagination_limit}`;

        if (export_csv == true) {
            console.log(url);
            return url;
        } else {
            const res = await xhr.get(url, {
                withCredentials: true
            });
            return res.data;
        }
    } /*
        options: {
            study_id: int
            segment: string
            search_keyword: string
            page: int
        }
    */,
    getStudyPeoplePOST: async options => {
        const study_id = options.study_id;
        const page = options.page || 1;
        const pagination_limit = options.pagination_limit || 20;
        const segment = options.segment || 'new';
        const search_keyword = options.search_keyword || '';
        const filters = options.filters || []; // FILTERS ARE PASSED IN AS JSON HERE
        const export_csv = options.export_csv || '';
        const account_id = auth.getAccountId();
        const sort = options.sort || '';

        let url =
            `/studies/${study_id}/study-people?account_id=${account_id}&page=${page}&segment=${segment}&search_keyword=${search_keyword}` +
            `&export_csv=${export_csv}&sort=${sort}&pagination_limit=${pagination_limit}`;

        if (export_csv == true) {
            return url;
        } else {
            const res = await xhr.post(
                url,
                {
                    filters: filters
                },
                {
                    withCredentials: true
                }
            );
            return res.data;
        }
    },
    studyRemoveStudyPerson: async (study_id, study_person_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/studies/${study_id}/study-people/${study_person_id}?account_id=${account_id}`, {
            withCredentials: true
        });

        return res.data;
    },
    studyRemoveStudyPeople: async (study_id, people, filters, search_keyword, sort, custom_count_number, randomize) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/study-people-delete?account_id=${account_id}`,
            {
                people_ids: people,
                filters,
                search_keyword,
                sort,
                custom_count_number,
                randomize
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    studyRemoveStudyPeopleScreenerResponse: async (
        study_id,
        people,
        filters,
        search_keyword,
        sort,
        custom_count_number,
        randomize,
        study_screener_id
    ) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/study-people-delete-screener-data/${study_screener_id}?account_id=${account_id}`,
            {
                people_ids: people,
                filters,
                search_keyword,
                sort,
                custom_count_number,
                randomize
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    studyAddStudyState: async (study_id, title, sf) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/study-states?account_id=${account_id}`,
            {
                title,
                filters: sf
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updateStudyStatesOrder: async (study_id, study_states) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-states-order?account_id=${account_id}`,
            {
                study_states
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    studyMoveStudyPeople: async (study_id, study_people, study_states, new_study_state_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-people-move?account_id=${account_id}`,
            { study_people, study_states, new_study_state_id },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    duplicateStudy: async (study_id, title, description) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/duplicate?account_id=${account_id}`,
            { title, description },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getStudyAvailability: async (study_id, show_taken) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/availability`, {
            params: {
                account_id: account_id,
                show_taken: show_taken ? 1 : 0
            },
            withCredentials: true
        });
        return res.data;
    },
    updateStudyPersonSpot: async (study_id, study_person_id, spot) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-people/${study_person_id}/spot?account_id=${account_id}`,
            {
                spot
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updateStudyPersonTimezone: async (study_id, study_person_id, timezone) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-people/${study_person_id}/timezone?account_id=${account_id}`,
            {
                timezone
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    cancelStudyPersonSpot: async (study_id, study_person_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(
            `/studies/${study_id}/study-people/${study_person_id}/spot?account_id=${account_id}`,
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updateStudyPersonLocation: async (study_id, study_person_id, location) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-people/${study_person_id}/location?account_id=${account_id}`,
            {
                location
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getTremendousCampaigns: async () => {
        const res = await xhr.get(`/integrations/tremendous/campaigns`, {
            withCredentials: true,
            params: {
                account_id: auth.getAccountId()
            }
        });
        return res.data ? res.data.campaigns : [];
    },
    /**
     * Process manual payment
     *
     * @param {number} studyId
     * @param {number} studyPersonId
     * @param {Object} data
     * @param {string} data.incentiveStatus
     * @param {number} data.incentiveTotalPaid
     * @param {string} data.paymentDate
     */
    processManualPayment: async (studyId, studyPersonId, data) => {
        const accountId = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${studyId}/study-people/${studyPersonId}/manual-payment`,
            {
                incentive_status: data.incentiveStatus,
                incentive_total_paid: data.incentiveTotalPaid,
                payment_date: data.paymentDate
            },
            {
                withCredentials: true,
                params: {
                    account_id: accountId
                }
            }
        );
        return res.data;
    },
    /**
     * Update manual payment
     *
     * @param {number} studyId study id
     * @param {number} studyPersonId study person id
     * @param {Object} data
     * @param {string} data.amount amount
     * @param {string} data.paymentDate payment date
     */
    updateManualPayment: async (studyId, studyPersonId, data) => {
        const accountId = auth.getAccountId();
        const response = await xhr.put(
            `/studies/${studyId}/study-people/${studyPersonId}/manual-payment-update`,
            {
                amount: data.amount,
                payment_date: data.paymentDate
            },
            {
                withCredentials: true,
                params: {
                    account_id: accountId
                }
            }
        );
        return response.data;
    },
    updateScreenerPageLogic: async (study_id, screener_id, page_logic) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/screeners/${screener_id}/page_logic?account_id=${account_id}`,
            { page_logic: page_logic },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updatePanelSignupScreenerPageLogic: async (panel_form_id, page_logic) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/panel-signup-forms/${panel_form_id}/page_logic?account_id=${account_id}`,
            { page_logic: page_logic },
            {
                withCredentials: true
            }
        );
        return res.data;
    },

    updatePersonStatus: async (study_id, study_person_id, status) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/studies/${study_id}/study-people/${study_person_id}/status?account_id=${account_id}`,
            {
                status
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getStudyTags: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/tags?account_id=${account_id}&type=Study`, {
            withCredentials: true
        });

        return res.data;
    },
    getStudyQuotasExportLink: study_id => {
        const account_id = auth.getAccountId();
        const baseURL = config.API_URL;
        return baseURL + `/studies/${study_id}/quotas?account_id=${account_id}&export_csv=1`;
    },
    getStudyQuotas: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/quotas?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    calculateStudyQuota: async (study_id, quota_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/quotas/${quota_id}/calculate?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    studyQuotaCreate: async (study_id, quota) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/quotas?account_id=${account_id}`,
            // send the object
            quota,
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    studyQuotaEdit: async (study_id, quota) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(`/studies/${study_id}/quotas/${quota.id}?account_id=${account_id}`, quota, {
            withCredentials: true
        });
        return res.data;
    },
    studyQuotaDelete: async (study_id, quota_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/studies/${study_id}/quotas/${quota_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getQuotaRespondents: async (study_id, quota_id, rule_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/quotas/${quota_id}/people?account_id=${account_id}`, {
            withCredentials: true,
            params: {
                rule_id: rule_id
            }
        });
        return res.data;
    },
    getEmailCampaignPublic: async uuid => {
        const res = await xhr.get(`/emails-public/${uuid}`, {
            withCredentials: true
        });
        return res.data;
    },

    assignUserToPeopleInStudy: async (study_id, people_ids, user_id, filtering_options, randomize) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/study-people-assign?account_id=${account_id}`,
            {
                people_ids,
                user_id,
                filters: filtering_options.filters,
                search_keyword: filtering_options.search_keyword,
                custom_count_number: filtering_options.custom_count_number,
                sort: filtering_options.sort,
                randomize
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    bulkSpEdit: async (
        study_id,
        people,
        filters,
        search_keyword,
        sort,
        custom_count_number,
        bulk_edit_field_id,
        bulk_edit_value,
        randomize
    ) => {
        const account_id = auth.getAccountId();
        //console.log('randomize', randomize)
        const res = await xhr.post(
            `/studies/${study_id}/sp-bulk-edit`,
            {
                people_ids: people,
                filters,
                search_keyword,
                sort,
                custom_count_number,
                account_id,
                bulk_edit_field_id,
                bulk_edit_value,
                randomize
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    bulkPayVerify: async (study_id, people, filters, search_keyword, sort, custom_count_number, randomize) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/bulk-pay-incentive-verify?account_id=${account_id}`,
            {
                people_ids: people,
                filters,
                search_keyword,
                sort,
                custom_count_number,
                randomize
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    /**
     * Pay participants in bulk
     *
     * @param {number} study_id study id
     * @param {Array<{ id: number }>} people people to pay
     * @param {string} type payment type
     * @param {number} amount amount
     * @param {string?} paymentDate payment date
     */
    bulkPayAccept: async (study_id, people, type, amount, paymentDate) => {
        const account_id = auth.getAccountId();

        const body = {
            people_ids: people,
            amount,
            type
        };

        // attach payment date if it's mark as paid
        if (type === INCENTIVE_PAYMENT_METHOD.MARK_AS_PAID) {
            body.payment_date = paymentDate;
        }

        const res = await xhr.post(`/studies/${study_id}/bulk-pay-incentive-accept?account_id=${account_id}`, body, {
            withCredentials: true
        });
        return res.data;
    },
    deleteScreenerCustomLink: async (study_id, screener_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(
            `/studies/${study_id}/screeners/${screener_id}/custom-link?account_id=${account_id}`,
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    setScreenerCustomLink: async (study_id, screener_id, slug) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/screeners/${screener_id}/custom-link?account_id=${account_id}`,
            { slug },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    setUserViewingStudy: async (study_id, user_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/user-viewing/${user_id}?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    removeUserViewingStudy: async (study_id, user_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/user-viewing-remove/${user_id}?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getRemoveUserViewingStudy: (study_id, user_id) => {
        const baseURL = config.API_URL;
        const account_id = auth.getAccountId();
        return baseURL + `/studies/${study_id}/user-viewing-remove/${user_id}?account_id=${account_id}`;
    },
    getRemoveUserViewingStudyPerson: (study_id, study_person_id) => {
        const baseURL = config.API_URL;
        const account_id = auth.getAccountId();
        return baseURL + `/studies/${study_id}/study-people/${study_person_id}/viewing-remove?account_id=${account_id}`;
    },
    // people: [{id: ... }, {id: ... }, {id: ... }]
    sendSmsCampaign: async (
        study_id,
        people,
        study_states,
        filters,
        content,
        action,
        test_phone_number,
        search_keyword,
        custom_count_number,
        sort,
        randomize
    ) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/sms-campaign?account_id=${account_id}`,
            {
                recipientsTo: people,
                study_states,
                filters,
                content,
                action,
                test_phone_number,
                search_keyword,
                custom_count_number,
                sort,
                randomize
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    createStudyReminder: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/reminders?account_id=${account_id}`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updateStudyReminder: async (study_id, reminder) => {
        if (reminder && reminder.id) {
            const account_id = auth.getAccountId();
            const res = await xhr.put(
                `/studies/${study_id}/reminders/${reminder.id}?account_id=${account_id}`,
                { ...reminder },
                {
                    withCredentials: true
                }
            );
            return res.data;
        }
    },
    getStudyReminders: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/reminders?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    deleteStudyReminder: async (study_id, reminder_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/studies/${study_id}/reminders/${reminder_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getStudyChartData: async (study_id, screener_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/chart-data/${screener_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getAllScreenerVersions: async (study_id, screener_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/screeners/${screener_id}/versions`, {
            withCredentials: true,
            params: {
                account_id: account_id,
                study_id: study_id
            }
        });
        return res.data;
    },
    getScreenerVersionById: async (study_id, screener_id, screener_version_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/screeners/${screener_id}/versions/${screener_version_id}`, {
            withCredentials: true,
            params: {
                account_id: account_id,
                study_id: study_id
            }
        });
        return res.data;
    },
    replaceScreenerWithVersion: async (study_id, screener_id, screener_version_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/screeners/${screener_id}/versions/${screener_version_id}`,
            {
                // empty form data array
            },
            {
                withCredentials: true,
                params: {
                    account_id: account_id,
                    study_id: study_id
                }
            }
        );
        return res.data;
    },
    updateScreenerQuotas: async (study_id, screener_id, quotas) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/screeners/${screener_id}/quotas`,
            {
                quotas,
                account_id,
                study_id
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    refreshScreenerQuotaCount: async (study_id, screener_id, quota_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/screeners/${screener_id}/quotas/${quota_id}`, {
            params: {
                account_id,
                study_id
            },
            withCredentials: true
        });
        return res.data;
    },
    resendEmail: async (study_id, email_individual_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/resend-email/${email_individual_id}`, {
            params: {
                account_id
            },
            withCredentials: true
        });
        return res.data;
    },
    bulkDownloadFile: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/bulk-download-files`, {
            params: {
                account_id
            },
            withCredentials: true
        });
        return res.data;
    },

    updateStudyPersonFatigue: async (study_id, study_person_id, fatigue_status) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/studies/${study_id}/study-people/${study_person_id}/fatigue?account_id=${account_id}`,
            {
                fatigue_status
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },

    getStudyActivity: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/activity`, {
            params: {
                account_id
            },
            withCredentials: true
        });
        return res.data;
    },

    getStudyReportingSummary: async study_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/studies/${study_id}/reporting-summary`, {
            params: {
                account_id
            },
            withCredentials: true
        });
        return res.data;
    },

    getSmsCampaigns: async study_id => {
        const res = await xhr.get(`/studies/${study_id}/sms-campaigns`, {
            withCredentials: true,
            params: {
                account_id: auth.getAccountId()
            }
        });
        return res.data;
    },
    getEmailCampaigns: async study_id => {
        const res = await xhr.get(`/studies/${study_id}/campaigns`, {
            withCredentials: true,
            params: {
                account_id: auth.getAccountId()
            }
        });
        return res.data;
    },
    /**
     * Get email individuals for a campaign
     *
     * @param {number} campaignId email campaign id
     * @param {{ perPage: 20 | 50 | 100, page: number, status: string }} options options
     */
    getEmailIndividualsForCampaign: async (emailCampaignId, { perPage = 20, page = 1, status }) => {
        const response = await xhr.get(`/email-campaigns/${emailCampaignId}/individuals`, {
            withCredentials: true,
            params: {
                account_id: auth.getAccountId(),
                per_page: perPage,
                page,
                status
            }
        });

        return response.data;
    },
    /**
     * Get sms individuals for a campaign
     *
     * @param {number} smsCampaignId sms campaign id
     * @param {{ perPage: 20 | 50 | 100, page: number, status: string }} options options
     */
    getSmsIndividualsForCampaign: async (smsCampaignId, { perPage = 20, page = 1, status }) => {
        const response = await xhr.get(`/sms-campaigns/${smsCampaignId}/individuals`, {
            withCredentials: true,
            params: {
                account_id: auth.getAccountId(),
                per_page: perPage,
                page: page,
                status
            }
        });

        return response.data;
    }
};

export default service_studies;
