import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import NiceWrapper from 'components/NiceWrapper';
import { Flex, Box, Heading, Button } from 'rebass';
import { Label, Switch, Input } from '@rebass/forms';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_people from 'services/people';
import service_accounts from 'services/accounts';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

function PanelistSignup(props) {
    const [loading, setLoading] = useState(true);
    const [personData, setPersonData] = useState({});
    const [account, setAccount] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { addToast } = useToasts();
    const history = useHistory();

    useEffect(() => {
        //getPersonXHR(props.match.params.account_uuid, props.match.params.person_uuid);
        getAccountXHR(props.match.params.account_uuid);
        checkPersonAuth(props.match.params.account_uuid);
    }, [props.match.params.account_uuid, props.match.params.person_uuid]);

    function getAccountXHR(account_uuid) {
        console.log(account_uuid);

        service_accounts
            .getAccountBySlug(account_uuid)
            .then(account => {
                setAccount(account);
                setLoading(false);
            })
            .catch(e => {
                services.parseAndTrackXhrErrors(e);
                setLoading(false);
            });
    }

    function redirectToPanelistProfile() {
        history.push(`/panelist/${props.match.params.account_uuid}/profile`);
    }

    function checkPersonAuth(account_uuid) {
        service_people
            .checkAuthPanelistExperience(account_uuid)
            .then(person => {
                console.log('person', person);
                redirectToPanelistProfile();
            })
            .catch(e => {
                //console.log(e)
            });
    }

    function createPanelistXHR() {
        const person = {
            first_name: document.getElementById('fname').value,
            last_name: document.getElementById('lname').value,
            email: document.getElementById('email').value,
            phone_number: document.getElementById('phone_number').value,
            password: document.getElementById('password').value,
            password_confirmation: document.getElementById('password_confirmation').value
        };

        console.log(person, account);

        setIsSubmitting(true);

        service_people
            .createPersonPanelistExperience(account.uuid, person)
            .then(jwt_token => {
                helpers.setPanelistJWTToken(jwt_token);
                //console.log('jwt_token', jwt_token)
                //setAccount(account);
                redirectToPanelistProfile();
            })
            .catch(e => {
                setIsSubmitting(false);

                const errorText = services.parseAndTrackXhrErrors(e);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <div style={{ margin: 'auto' }}>
            <Helmet titleTemplate="%s">
                <title>{account && account.title ? account.title : 'Panel Profile'}</title>
                {helpers.renderFavicon(account)}
            </Helmet>

            <MetaTags>
                <meta property="og:site_name" content={helpers.getAccountTitleForOgTag(account)} />
                <meta property="og:title" content={account && account.title ? account.title : 'Panel Sign Up'} />
                <meta property="og:description" content={'Join our panel today!'} />
                <meta property="og:url" content={location.href} />
                {helpers.getPageImage(account) != null && (
                    <meta property="og:image" content={helpers.getPageImage(account)} />
                )}
            </MetaTags>

            {loading ? (
                <Box my={4} style={{ width: '100%', height: '80px', position: 'relative' }}>
                    <LoadingWrapper>
                        <LoadingIndicator />
                    </LoadingWrapper>
                </Box>
            ) : account && account.title ? (
                <>
                    <Box style={{ textAlign: 'center' }} mt={4} mb={3}>
                        {account.logo ? (
                            <img
                                src={account.logo}
                                style={{ maxWidth: '100%', maxHeight: helpers.getPanelistLogoHeight(account) }}
                                alt="Account Logo"
                            />
                        ) : (
                            <div style={{ height: '32px' }}></div>
                        )}
                        <Heading fontSize={[3, 4]} mt={2}>
                            <br />
                            Sign Up
                        </Heading>
                    </Box>
                    <NiceWrapper style={{ background: 'white', color: 'black', padding: '0', margin: '0 32px' }}>
                        <Box p={4} sx={{ textAlign: 'center' }}>
                            <Box fontSize={1} style={{ minWidth: '320px' }}>
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        createPanelistXHR();
                                    }}
                                >
                                    <Flex sx={{ gridGap: 3 }}>
                                        <Box width={1 / 2}>
                                            <Label htmlFor="fname">First Name</Label>
                                            <Input
                                                type="text"
                                                placeholder="First Name"
                                                name="fname"
                                                id="fname"
                                                required
                                                autoFocus
                                            />
                                        </Box>
                                        <Box width={1 / 2}>
                                            <Label htmlFor="lname">Last Name</Label>
                                            <Input
                                                type="text"
                                                placeholder="Last Name"
                                                name="lname"
                                                id="lname"
                                                required
                                            />
                                        </Box>
                                    </Flex>
                                    <Label mt={3} htmlFor="email">
                                        E-mail
                                    </Label>
                                    <Input type="email" placeholder="E-mail" name="email" id="email" required />
                                    <Label mt={3} htmlFor="phone_number">
                                        Phone Number
                                    </Label>
                                    <Input type="text" placeholder="Phone Number" name="phone" id="phone_number" />
                                    <Label mt={3} htmlFor="password">
                                        Password
                                    </Label>
                                    <Input type="password" placeholder="Password" id="password" required />
                                    <Label mt={3} htmlFor="password_confirmation">
                                        Confirm Password
                                    </Label>
                                    <Input
                                        type="password"
                                        placeholder="Confirm Password"
                                        id="password_confirmation"
                                        required
                                    />
                                    <Box style={{ textAlign: 'right' }} mt={3}>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            mr={0}
                                            style={helpers.accountThemeButtonStyle(account, { width: '100%' })}
                                            disabled={isSubmitting}
                                        >
                                            Sign Up{isSubmitting}
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Box>

                        <Box fontSize={1} p={3} sx={{ textAlign: 'center', background: '#f7f7f7' }}>
                            Already have an account?{' '}
                            <Link
                                to={`/panelist/${props.match.params.account_uuid}/login`}
                                style={helpers.accountThemeLinkStyle(account)}
                            >
                                Sign In
                            </Link>
                        </Box>
                    </NiceWrapper>
                    {helpers.renderScreenerFooter(account)}
                </>
            ) : (
                <Box mt={4}>Account not found.</Box>
            )}
        </div>
    );
}

export default PanelistSignup;
