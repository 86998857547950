import React from 'react';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import store from './stores/store';
import Toolbar from './toolbar';
import FormElementsEdit from './form-elements-edit';
import SortableFormElements from './sortable-form-elements';
import { Flex, Box, Button } from 'rebass';
import { Label, Input, Switch, Select } from '@rebass/forms';
import { withToastManager } from 'react-toast-notifications';
import {
    FiPlus,
    FiCheck,
    FiMove,
    FiEye,
    FiTrash2,
    FiGitMerge,
    FiPlusCircle,
    FiXCircle,
    FiCornerDownRight,
    FiFilePlus,
    FiMinusCircle,
    FiChevronDown,
    FiMoreVertical,
    FiCopy,
    FiChevronsUp,
    FiCornerUpLeft,
    FiCornerUpRight,
    FiAlertTriangle,
    FiRotateCw,
    FiRotateCcw,
    FiExternalLink,
    FiAlertCircle
} from 'react-icons/fi';
import { BiGridVertical } from 'react-icons/bi';
import DragIcon from 'images/icons/drag';
import ID from './UUID';
import cloneDeep from 'lodash/cloneDeep';
import ReactModal from 'react-modal';
import H1 from 'components/H1';
import helpersScreener from 'utils/helpers-screener';
import helpersStudy from 'utils/helpers-study';
import helpers from 'utils/helpers';
import service_studies from 'services/studies';
import services from 'services/services.js';
import auth from 'services/auth';
const auth_state = auth.getAuthState();
const liu_email = auth_state && auth_state.user && auth_state.user.email ? auth_state.user.email : null;
import {
    SCREENER_PAGE_LOGIC_QUALIFY,
    SCREENER_PAGE_LOGIC_DISQUALIFY,
    SCREENER_PAGE_LOGIC_SHOW,
    SCREENER_PAGE_LOGIC_HIDE,
    FORM_TYPE_CONSENT,
    COL_EMAIL,
    SCREENER_QDQS_ACCEPT
} from 'utils/constants';
import NiceDropdown from 'components/NiceDropdown';
import ErrorLabel from 'components/ErrorLabel';
import { List, arrayMove } from 'react-movable';
import analytics from 'utils/analytics';
import ScreenerVersions from 'containers/StudyScreenersPage/ScreenerVersions';
import EventEmitter from 'utils/events';
import { TIMETRAVEL_REDO, TIMETRAVEL_UNDO } from 'utils/constants';
import { Badge } from 'components/Badge';

const { PlaceHolder } = SortableFormElements;

class Preview extends React.Component {
    constructor(props) {
        super(props);

        const { onLoad, onPost } = props;
        store.setExternalHandler(onLoad, onPost);
        //console.log('preview' , this.props)
        this.editForm = React.createRef();

        const pages_to_reorder = this.generateSortablePageArray(this.props.data);

        this.state = {
            data: [],
            answer_data: {},
            showPageLogicModal: false,
            pageLogicPageIndex: 0,
            pageLogicRules: this.props.page_logic,
            showPageOptionsMoreDropdown_pageIndex: -1,
            pages_to_reorder: pages_to_reorder,
            cards_to_move: null,
            form_updated_at: this.props.form_updated_at,
            availableColumns: [],
            availableColumns_byId: {},
            cursor: 0,
            timeline: [],
            isEmailAvoidingWarning: false
        };
        this.seq = 0;

        const onUpdate = this._onChange.bind(this);
        store.subscribe(state => onUpdate(state.data));

        this.moveCard = this.moveCard.bind(this);
        this.insertCard = this.insertCard.bind(this);
        this.tableOfContentsMoveCard = this.tableOfContentsMoveCard.bind(this);
        this.renderPage = this.renderPage.bind(this);
        this.addPage = this.addPage.bind(this);
        this.deletePage = this.deletePage.bind(this);
        this.reorderPages = this.reorderPages.bind(this);
        this.reorderPagesSave = this.reorderPagesSave.bind(this);
        this.cancelReorderPages = this.cancelReorderPages.bind(this);
        this.mergePage = this.mergePage.bind(this);
        this.updateScreenerPageLogic = this.updateScreenerPageLogic.bind(this);
        this.editPageLogicRulesConditionalValue = this.editPageLogicRulesConditionalValue.bind(this);
        this.editPageLogicRulesSetupAction = this.editPageLogicRulesSetupAction.bind(this);
        this.editQuestionLogicRulesSetupMatch = this.editQuestionLogicRulesSetupMatch.bind(this);
        this.updateElement = this.updateElement.bind(this);
        this.create = this.create.bind(this);
        this.onPanelColumnsChanged = this.onPanelColumnsChanged.bind(this);
    }

    generateSortablePageArray(pages) {
        return pages.map((page, page_index) => {
            return {
                page_title: 'Page ' + (page_index + 1),
                page_index: page_index
            };
        });
    }

    static getDerivedStateFromProps(props, state, page_number) {
        //console.log('before break', props)
        if (props.data && props.data[page_number] && props.data[page_number] !== state.data[page_number]) {
            store.dispatch('updateOrder', { elements: props.data[page_number] });
        }
        return null;
    }

    static _defaultItemOptions(element) {
        /*

            WARNINGS, NOTES, ETC

            Originally added the "option.type" key to support the matrix (so there can be columns & rows).
            When we added the ability to change an element to another (e.g. from a checkbox to a matrix)
                i added the "option.type" to all other types with options.

      */

        switch (element) {
            case 'Dropdown':
                return [
                    { value: ID.uuid(), text: '', key: `option_${ID.uuid()}`, type: 'row' },
                    { value: ID.uuid(), text: '', key: `option_${ID.uuid()}`, type: 'row' }
                ];
            case 'Tags':
                return [
                    {
                        value: 'place_holder_tag_1',
                        text: 'Place holder tag 1',
                        key: `option_${ID.uuid()}`,
                        type: 'row'
                    },
                    { value: 'place_holder_tag_2', text: 'Place holder tag 2', key: `option_${ID.uuid()}`, type: 'row' }
                ];
            case 'Checkboxes':
                return [
                    { value: ID.uuid(), text: '', key: `option_${ID.uuid()}`, type: 'row' },
                    { value: ID.uuid(), text: '', key: `option_${ID.uuid()}`, type: 'row' }
                ];
            case 'Matrix':
                return [
                    { value: '', text: '', key: `option_${ID.uuid()}`, type: 'row' },
                    { value: '', text: '', key: `option_${ID.uuid()}`, type: 'row' },
                    { value: '', text: '', key: `option_${ID.uuid()}`, type: 'column' },
                    { value: '', text: '', key: `option_${ID.uuid()}`, type: 'column' }
                ];
            case 'RadioButtons':
                return [
                    { value: ID.uuid(), text: '', key: `option_${ID.uuid()}`, type: 'row' },
                    { value: ID.uuid(), text: '', key: `option_${ID.uuid()}`, type: 'row' }
                ];
            default:
                return [];
        }
    }

    componentDidMount() {
        console.log('preview.jsx mounted');
        const { data, url, saveUrl } = this.props;
        if (url)
            store.dispatch('load', {
                loadUrl: url,
                saveUrl,
                data: data || []
            });

        store.dispatch('cleanTimeLine');

        EventEmitter.subscribe('screener.save.success', screenerObject => {
            this.setState({ form_updated_at: screenerObject.form_updated_at });

            const SLSB = document.getElementById('screener-last-saved-button');
            if (SLSB && SLSB.classList) {
                SLSB.classList.add('green');

                setTimeout(() => {
                    SLSB.classList.remove('green');
                }, 5000);
            }
        });

        //console.log('mouse down add listener');

        services
            .getCustomDataColumnsXHR()
            .then(columns => {
                let availableColumns_byId = {};
                columns.forEach(c => {
                    availableColumns_byId[c.id] = c;
                });
                this.setState(
                    {
                        availableColumns: columns,
                        availableColumns_byId: availableColumns_byId
                    },
                    () => {
                        this.setState({ isEmailAvoidingWarning: this.shouldShowEmailAvoidingWarning() });
                    }
                );
            })
            .catch(error => {
                helpers.trackError(error);
            });

        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        //console.log('mouse down remove listener');
        document.removeEventListener('keydown', this.keydownHandler);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.cards_to_move !== null) {
            const { pageIndex, dragIndex, newPageIndex, hoverIndex } = this.state.cards_to_move;
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ cards_to_move: null }, () => {
                this.moveCard(pageIndex, dragIndex, newPageIndex, hoverIndex);
            });
        }

        // if form has changed check if email avoidance warning should be displayed
        if (this.state.data !== prevState.data) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isEmailAvoidingWarning: this.shouldShowEmailAvoidingWarning() });
        }

        if (this.props.isEmailRequired !== prevProps.isEmailRequired) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isEmailAvoidingWarning: this.shouldShowEmailAvoidingWarning() });
        }

        if (this.state.pageLogicRules !== prevState.pageLogicRules) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isEmailAvoidingWarning: this.shouldShowEmailAvoidingWarning() });
        }
    }

    keydownHandler = e => {
        const isMac = navigator.userAgent.indexOf('Mac OS X') != -1;
        //console.log('isMac', isMac)
        //console.log('e.keyCode', e.keyCode)
        // "z" -> e.keyCode === 90
        // "y" -> e.keyCode === 89
        // "COMMAND @ mac" -> e.metaKey
        // "ctrl" -> e.ctrlKey
        if ((!isMac ? e.ctrlKey : e.metaKey) && e.keyCode === 90) {
            //console.log('undo via keyboard')
            this.timelineAction(TIMETRAVEL_UNDO.type)();
        }
        if ((!isMac ? e.ctrlKey : e.metaKey) && e.keyCode === 89) {
            //console.log('redo via keyboard')
            this.timelineAction(TIMETRAVEL_REDO.type)();
        }
    };

    editModeSave = e => {
        this.updateElement();
    };
    editModeOff = e => {
        this.props.manualEditModeOff();
    };

    manualEditModeOff = () => {
        const { editElement } = this.props;
        if (editElement && editElement.dirty) {
            editElement.dirty = false;
            this.updateElement(editElement);
        }
        this.props.manualEditModeOff();
    };

    _setValue(text) {
        return text.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
    }

    updateElement(element) {
        let found = false;

        //console.log('inside preview.updateElement', element);

        // just to rename, state.data is all of the pages with their questions
        // const pagesData = this._copyStateData(this.state.data);
        const pagesData = cloneDeep(this.state.data);

        //console.log('updateElement element', element, pages_data)

        // loop through all pages to find it lol
        if (pagesData && pagesData.length) {
            for (let page_i = 0; page_i < pagesData.length; page_i++) {
                for (let item_i = 0; item_i < pagesData[page_i].length; item_i++) {
                    if (pagesData[page_i][item_i] && element.id === pagesData[page_i][item_i].id) {
                        pagesData[page_i][item_i] = element;
                        found = true;
                        break;
                    }
                }
            }
        }

        if (found) {
            this.seq = this.seq > 100000 ? 0 : this.seq + 1;
            store.dispatch('updateOrder', { elements: pagesData });
        }
    }

    _copyStateData() {
        // const pagesData = [];
        // for (const item of this.state.data) {
        //     pagesData.push(Array.isArray(item) ? [...item] : item);
        // }

        const pagesData = cloneDeep(this.state.data);

        // const pagesData = JSON.parse(JSON.stringify(this.state.data));

        return pagesData;
    }

    _onChange(pages) {
        //console.log('ONCHANGE PAGES', pages, this.state.data);

        // const newTimeline = [pages, ...this.state.timeline];
        // if (newTimeline.length > 10) {
        //     newTimeline.splice(10, this.state.timeline.length - 10);
        // }

        // this.setState(prevState => ({
        //     timeline: [pages].concat(prevState.timeline)
        // }));

        const answer_data = {};

        pages.forEach((page_items, page_number) => {
            page_items.forEach(item => {
                if (item && item.readOnly && this.props.variables[item.variableKey]) {
                    //answer_data[item.field_name] = this.props.variables[item.variableKey];
                }
            });
        });

        //console.log('new pages.... _onChange', pages)

        this.setState({
            data: pages,
            answer_data
        });

        if (this.props.onChange) {
            //console.log('onChange found!')
            this.props.onChange(pages);
        } else {
            //console.log('onChange not found')
        }
    }

    _onDestroy(page_number, item) {
        store.dispatch('delete', { element: item, page_number });
    }

    _onDuplicate(page_number, item) {
        //console.log('DISPATCHING screener.element.create', page_number, item)
        EventEmitter.dispatch('screener.element.create', {
            item: item,
            page_number: page_number
        });
    }

    onPanelColumnsChanged(availableColumns, availableColumns_byId) {
        this.setState({
            availableColumns,
            availableColumns_byId
        });
    }

    insertCard(page_index, item, hoverIndex) {
        console.log('insertCard', page_index, hoverIndex);
        const { data } = this.state;
        //console.log('data', data[page_number])
        if (hoverIndex == -1) {
            data[page_index].push(item);
        } else {
            data[page_index].splice(hoverIndex, 0, item);
        }

        this.saveData(page_index, item, hoverIndex, hoverIndex);
    }

    moveCard(page_index, dragIndex, new_page_index, hoverIndex) {
        console.log('move', page_index, dragIndex, new_page_index, hoverIndex);
        const { data } = this.state;

        if (page_index != new_page_index) {
            const dragCard_current = data[page_index][dragIndex];
            const dragCard_new = cloneDeep(dragCard_current);
            this.insertCard(new_page_index, dragCard_new, hoverIndex);
            this._onDestroy(page_index, dragCard_current);
            console.log('insert', new_page_index, dragCard_new, hoverIndex);
        } else {
            const dragCard = data[page_index][dragIndex];
            this.saveData(page_index, dragCard, dragIndex, hoverIndex);
        }

        //console.log('delete', page_index, dragCard_current)

        const { toastManager } = this.props;
        toastManager.add('Moved element', {
            appearance: 'success',
            autoDismiss: true
        });
    }

    cardPlaceHolder(dragIndex, hoverIndex) {
        // Dummy
    }

    saveData(page_number, dragCard, dragIndex, hoverIndex) {
        //console.log(dragCard, dragIndex, hoverIndex)

        console.log('saving data / updating order11111');

        const pagesData = this._copyStateData();
        pagesData[page_number] = update(this.state.data[page_number], {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard]
            ]
        });

        this.setState({ data: pagesData });
        // console.log('new page data..............', pagesData)
        store.dispatch('updateOrder', { elements: pagesData });
    }

    getElement(page_index, item, index) {
        const all_questions = cloneDeep(this.state.data);

        const SortableFormElement = SortableFormElements[item.element];
        const EditElement = (
            <FormElementsEdit
                screener_for={this.props.screener_for}
                index={index}
                all_questions={all_questions}
                page_index={page_index}
                showCorrectColumn={this.props.showCorrectColumn}
                files={this.props.files}
                manualEditModeOff={this.manualEditModeOff}
                preview={this}
                element={item}
                updateElement={this.updateElement}
                _onDestroy={() => this._onDestroy(page_index, item)}
                _onDuplicate={() => this._onDuplicate(page_index, item)}
                //read_only={this.props.read_only}
                _onMoveElement={(dragIndex, new_page_index, hoverIndex) => {
                    console.log('...', page_index, dragIndex, new_page_index, hoverIndex);
                    this.moveCard(page_index, dragIndex, new_page_index, hoverIndex);
                }}
                create={this.create}
                panelColumnsChangedCallback={this.onPanelColumnsChanged}
            />
        );

        return (
            <SortableFormElement
                editElement={window.sb_v2 ? null : EditElement}
                read_only={this.props.read_only}
                id={item.id}
                seq={this.seq}
                index={index}
                moveCard={(dragIndex, hoverIndex) => this.moveCard(page_index, dragIndex, page_index, hoverIndex)}
                insertCard={(page_index, item, hoverIndex) => this.insertCard(page_index, item, hoverIndex)}
                mutable={false}
                parent={this.props.parent}
                editModeOn={this.props.editModeOn}
                isDraggable={true}
                key={item.id}
                sortData={item.id}
                data={item}
            />
        );
        //return <div className='rfb-item'>{EditElement}</div>;
    }

    tableOfContentsMoveCard(dragItem, hoverItem) {
        const pageIndex = dragItem.pageIndex;
        const dragIndex = dragItem.itemIndex;
        const newPageIndex = hoverItem.pageIndex;
        const hoverIndex =
            hoverItem.type === 'page'
                ? 0
                : pageIndex === newPageIndex && dragIndex < hoverItem.itemIndex
                ? hoverItem.itemIndex
                : hoverItem.itemIndex + 1;

        this.setState({
            cards_to_move: {
                pageIndex: pageIndex,
                dragIndex: dragIndex,
                newPageIndex: newPageIndex,
                hoverIndex: hoverIndex
            }
        });
    }

    tableOfContentsRenderItem(value, index, props, isDragged, isSelected) {
        if (value.type === 'page') {
            return (
                <div {...props}>
                    <a
                        className="fs-title-18 link-navigation-primary"
                        href={`#pq-${value.pageIndex + 1}-1`}
                        style={{
                            display: 'block',
                            padding: '8px 0'
                        }}
                    >
                        {value.pageIndex !== 0 && <hr style={{ height: '16px' }} />}
                        <span style={{ padding: '0 24px' }}>Page {value.pageIndex + 1}</span>
                    </a>
                </div>
            );
        } else {
            return (
                <div {...props}>
                    <Box
                        data-movable-handle
                        style={{
                            cursor: isDragged ? 'grabbing' : 'grab',
                            background: isDragged ? 'rgba(234, 222, 252, 0.95)' : 'none',
                            borderRadius: '4px',
                            margin: '0 20px'
                        }}
                    >
                        <Flex>
                            <Flex
                                style={{
                                    color: isDragged ? '#6200FF' : '#929DB0',
                                    padding: '9px 8px 8px 8px',
                                    minWidth: '34px'
                                }}
                            >
                                <DragIcon />
                            </Flex>
                            <a
                                className="ellipsis fs-body-14 link-navigation-secondary"
                                href={`#pq-${value.pageIndex + 1}-${value.itemIndex + 1}`}
                                style={{
                                    color: isDragged ? '#001233' : '',
                                    padding: '8px 0',
                                    width: '100%'
                                }}
                            >
                                {value.itemIndex + 1}. {value.itemName}
                            </a>
                            <a
                                href={`#pq-${value.pageIndex + 1}-${value.itemIndex + 1}`}
                                style={{
                                    minWidth: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                {value.itemHasError && <FiAlertCircle style={{ color: '#D42220', marginTop: '1px' }} />}
                            </a>
                        </Flex>
                    </Box>
                </div>
            );
        }
    }

    cancelReorderPages() {
        // reset the order
        const pages_to_reorder = this.generateSortablePageArray(this.state.data);
        this.setState({ pages_to_reorder: pages_to_reorder, showPageReorderModal: false });
    }
    reorderPages(oldIndex, newIndex) {
        let pages_to_reorder = this.state.pages_to_reorder;

        pages_to_reorder = arrayMove(pages_to_reorder, oldIndex, newIndex);

        this.setState({ pages_to_reorder: pages_to_reorder });
    }

    reorderPagesSave() {
        let pages_new_order = this.state.pages_to_reorder;
        //console.log('pages_new_order', pages_new_order)

        // current "pages" array data
        const pages = cloneDeep(this.state.data);
        const pageLogicRules = this.state.pageLogicRules;

        let data_new = [];
        let pageLogicRules_new = [];
        pages_new_order.forEach(page_order => {
            data_new.push(pages[page_order.page_index]);
            pageLogicRules_new.push(pageLogicRules[page_order.page_index]);
        });

        const pages_to_reorder = this.generateSortablePageArray(data_new);

        this.setState(
            {
                data: data_new,
                pages_to_reorder: pages_to_reorder,
                pageLogicRules: pageLogicRules_new,
                showPageReorderModal: false
            },
            () => {
                this.updateScreenerPageLogic();
            }
        );
        store.dispatch('updateOrder', { elements: data_new });
    }

    mergePage(page_index) {
        if (page_index == 0) {
            alert('You can not merge the 1st page.');
            return;
        }

        if (!confirm('Are you sure you want to merge this page to the above page?')) {
            return;
        }

        const { toastManager } = this.props;

        // get current page index data
        let pages_data = this._copyStateData();
        let page_questions_to_merge = pages_data[page_index];

        // get previous page index data
        const page_index_above = page_index - 1;
        let page_questions_above = pages_data[page_index_above];

        // merge into the "previous" page
        page_questions_above = page_questions_above.concat(page_questions_to_merge);

        pages_data[page_index_above] = page_questions_above;

        let pageLogicRules = this.state.pageLogicRules;
        let pageLogicRules_toMerge = pageLogicRules[page_index];
        if (!pageLogicRules_toMerge) {
            pageLogicRules_toMerge = [];
        }
        let pageLogicRules_above = pageLogicRules[page_index_above];
        if (!pageLogicRules_above) {
            pageLogicRules_above = [];
        }

        const pageLogicRules_combined = pageLogicRules_above.concat(pageLogicRules_toMerge);
        pageLogicRules[page_index_above] = pageLogicRules_combined;
        //console.log('merge page logic', pageLogicRules_above, pageLogicRules_toMerge, pageLogicRules_combined)

        this.setState(
            {
                data: pages_data,
                pageLogicRules: pageLogicRules
            },
            () => {
                // remove current page element
                this.deletePage(page_index, true);
                const pages_to_reorder = this.generateSortablePageArray(pages_data);
                this.updateScreenerPageLogic();
            }
        );

        store.dispatch('updateOrder', { elements: pages_data });
        toastManager.add('Page successfully merged', {
            appearance: 'success',
            autoDismiss: true
        });
    }

    deletePage(page_index, skipConfirm) {
        if (!skipConfirm) {
            if (!confirm('Are you sure you want to delete this page?')) {
                return;
            }
        }

        const { toastManager } = this.props;
        //console.log('page index to delete', page_index)

        let pages_data = this._copyStateData();
        if (pages_data[page_index]) {
            pages_data.splice(page_index, 1);
        }
        const pages_to_reorder = this.generateSortablePageArray(pages_data);

        let pageLogicRules = this.state.pageLogicRules;
        if (pageLogicRules[page_index]) {
            pageLogicRules.splice(page_index, 1);
        }

        this.setState(
            {
                data: pages_data,
                pages_to_reorder: pages_to_reorder,
                pageLogicRules: pageLogicRules
            },
            () => {
                this.updateScreenerPageLogic();
            }
        );
        store.dispatch('updateOrder', { elements: pages_data });

        toastManager.add('Page successfully deleted', {
            appearance: 'success',
            autoDismiss: true
        });
    }

    addPage(from_page_index) {
        //console.log('add page', from_page_index)
        const { toastManager } = this.props;
        let pages_data = this._copyStateData();
        pages_data.splice(from_page_index + 1, 0, []);

        const pages_to_reorder = this.generateSortablePageArray(pages_data);

        let pageLogicRules = this.state.pageLogicRules;
        pageLogicRules.splice(from_page_index + 1, 0, null);

        this.setState(
            {
                data: pages_data,
                pages_to_reorder: pages_to_reorder,
                pageLogicRules: pageLogicRules
            },
            () => {
                this.updateScreenerPageLogic();
            }
        );

        store.dispatch('updateOrder', { elements: pages_data });

        toastManager.add('Page successfully added', {
            appearance: 'success',
            autoDismiss: true
        });
    }

    renderPage(page_number, items) {
        //console.log('rendering pgnum', page_number)

        return (
            <div>
                {items.length > 0 && <div /*className="Sortable"*/>{items}</div>}
                {items.length == 0 && (
                    <Box style={{ textAlign: 'center' }} py={3} fontSize={1}>
                        0 elements on this page
                    </Box>
                )}
                {/*<PlaceHolder id="form-place-holder" show={items.length === 0} index={items.length} moveCard={this.cardPlaceHolder} insertCard={() => this.insertCard()}/>*/}
            </div>
        );
    }

    editPageLogicRulesConditionalValue(conditionalIndex, e, rule_index) {
        let { pageLogicRules } = this.state;
        const p_i = this.state.pageLogicPageIndex;

        if (!pageLogicRules[p_i][rule_index]['conditionals'][conditionalIndex]) {
            pageLogicRules[p_i][rule_index]['conditionals'][
                conditionalIndex
            ] = helpersScreener.getQuestionLogicConditionalDefault();
        }

        pageLogicRules[p_i][rule_index]['conditionals'][conditionalIndex]['value'] = e.target['value'];
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    editQuestionLogicRulesConditionalCompare(rule_index, conditionalIndex, e) {
        let { pageLogicRules } = this.state;
        const p_i = this.state.pageLogicPageIndex;

        if (!pageLogicRules[p_i][rule_index]['conditionals'][conditionalIndex]) {
            pageLogicRules[p_i][rule_index]['conditionals'][
                conditionalIndex
            ] = helpersScreener.getQuestionLogicConditionalDefault();
        }

        pageLogicRules[p_i][rule_index]['conditionals'][conditionalIndex]['compare'] = e.target['value'];
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    editQuestionLogicRulesConditionalQuestionId(rule_index, conditionalIndex, e) {
        let { pageLogicRules } = this.state;
        const p_i = this.state.pageLogicPageIndex;

        if (!pageLogicRules[p_i][rule_index]['conditionals'][conditionalIndex]) {
            pageLogicRules[p_i][rule_index]['conditionals'][
                conditionalIndex
            ] = helpersScreener.getQuestionLogicConditionalDefault();
        }

        pageLogicRules[p_i][rule_index]['conditionals'][conditionalIndex]['question_id'] = e.target['value'];
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    editQuestionLogicRulesConditionalQuestionIdChild(rule_index, conditionalIndex, e) {
        let { pageLogicRules } = this.state;
        const p_i = this.state.pageLogicPageIndex;

        if (!pageLogicRules[p_i][rule_index]['conditionals'][conditionalIndex]) {
            pageLogicRules[p_i][rule_index]['conditionals'][
                conditionalIndex
            ] = helpersScreener.getQuestionLogicConditionalDefault();
        }
        console.log(e);
        pageLogicRules[p_i][rule_index]['conditionals'][conditionalIndex]['question_id_child'] = e.target['value'];
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    editPageLogicRulesSetupAction(e, rule_index) {
        let { pageLogicRules } = this.state;
        const p_i = this.state.pageLogicPageIndex;

        pageLogicRules[p_i][rule_index]['setup']['action'] = e.target['value'];
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    editQuestionLogicRulesSetupMatch(e, rule_index) {
        let { pageLogicRules } = this.state;
        const p_i = this.state.pageLogicPageIndex;

        pageLogicRules[p_i][rule_index]['setup']['match'] = e.target['value'];
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    editElementPropQuestionLogicRules(question_logic_rules) {
        //console.log('!! editElementPropQuestionLogicRules', question_logic_rules);

        let { pageLogicRules } = this.state;
        pageLogicRules = question_logic_rules;

        this.setState(
            {
                pageLogicRules
            },
            () => {
                this.setState({ isEmailAvoidingWarning: this.shouldShowEmailAvoidingWarning() });
                //console.log('updated pageLogicRules', pageLogicRules)
                //this.updateElement();
            }
        );
    }

    removeConditional(rule_index, index) {
        let { pageLogicRules } = this.state;
        const p_i = this.state.pageLogicPageIndex;
        if (pageLogicRules[p_i][rule_index]['conditionals'][index]) {
            pageLogicRules[p_i][rule_index]['conditionals'].splice(index, 1);
        }
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    addConditional(rule_index, index) {
        let { pageLogicRules } = this.state;
        const p_i = this.state.pageLogicPageIndex;
        pageLogicRules[p_i][rule_index]['conditionals'].splice(
            index + 1,
            0,
            helpersScreener.getQuestionLogicConditionalDefault()
        );
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    isPossibleCircularLogic(current_page_index, next_page_number) {
        let hasCL = false;
        if (helpers.isNumber(next_page_number)) {
            const current_page_number = current_page_index + 1;
            //console.log(pi, pi_action)
            try {
                if (current_page_number == next_page_number) {
                    hasCL = true;
                }
            } catch (e) {
                helpers.trackError(e);
            }
        }
        return hasCL;
    }

    renderPageLogicRules() {
        const plr = this.state.pageLogicRules;
        const p_i = this.state.pageLogicPageIndex;
        let all_page_logic_blocks = [];

        //console.log(plr)
        if (plr && plr[p_i]) {
            if (Array.isArray(plr[p_i])) {
                plr[p_i].forEach((page_logic_rule, rule_index) => {
                    const render_action = (
                        <Flex mb={3}>
                            <Box mt={1}>If</Box>
                            <Select
                                className="theme-input"
                                sx={{ width: '120px' }}
                                ml={3}
                                mr={3}
                                value={page_logic_rule.setup.match}
                                onChange={e => this.editQuestionLogicRulesSetupMatch(e, rule_index)}
                            >
                                <option value="any">any (or)</option>
                                <option value="all">all (and)</option>
                            </Select>
                            <Box mt={1}>of the following match, </Box>
                            <Select
                                className="theme-input"
                                sx={{ width: '240px' }}
                                ml={3}
                                mr={3}
                                value={page_logic_rule.setup.action}
                                onChange={e => this.editPageLogicRulesSetupAction(e, rule_index)}
                            >
                                <option value=""></option> {/* empty value on purpose */}
                                {/*<option disabled>Show/Hide Logic:</option>*/}
                                <option value={SCREENER_PAGE_LOGIC_SHOW}>Show This Page</option>
                                {/*<option value={SCREENER_PAGE_LOGIC_HIDE}>Hide Page</option>*/}
                                {/*<option disabled>Skip Logic:</option>*/}
                                <option value={SCREENER_PAGE_LOGIC_DISQUALIFY}>Disqualify Respondent</option>
                                <option value={SCREENER_PAGE_LOGIC_QUALIFY}>Qualify Respondent</option>
                                {this.state.data.map((data, page_index) => {
                                    let opt_label = `Page ${page_index + 1}`;
                                    if (page_index == p_i) {
                                        opt_label = `Page ${page_index + 1} (Current Page)`;
                                    }
                                    return <option value={page_index + 1}>Skip To {opt_label}</option>;
                                })}
                            </Select>
                            {this.isPossibleCircularLogic(p_i, page_logic_rule.setup.action) == true && (
                                <Box mt={1}>
                                    <span className="label-warning">Warning: You have circular logic.</span>
                                </Box>
                            )}
                        </Flex>
                    );

                    let render_conditionals = [];

                    if (page_logic_rule.conditionals.length == 0) {
                        //console.log('DIDNT FIND CONDS');
                        render_conditionals.push(this.getConditionalElement(rule_index, 0, {}));
                    } else {
                        page_logic_rule.conditionals.forEach((conditional, conditional_index) => {
                            //console.log('found cond', conditional, conditional_index)
                            render_conditionals.push(
                                this.getConditionalElement(rule_index, conditional_index, conditional)
                            );
                        });
                    }

                    all_page_logic_blocks.push(
                        <Flex flexDirection="row" mb={3}>
                            <Box
                                sx={{ flexGrow: 1, borderRadius: '12px', border: '1px solid #e7e8f1', padding: '16px' }}
                            >
                                <Box mb={3} className="label-info">
                                    {page_logic_rule.setup.action == SCREENER_PAGE_LOGIC_SHOW ? (
                                        <Box>
                                            Will be executed <b>before</b> this page loads.
                                        </Box>
                                    ) : (
                                        <Box>
                                            Will be executed <b>after</b> the respondent completes this page.
                                        </Box>
                                    )}
                                </Box>
                                {render_action}
                                <Flex flexDirection="column">{render_conditionals}</Flex>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    className="secondary-icon"
                                    ml={3}
                                    mr={0}
                                    onClick={() => this.removeLogicStatement(p_i, rule_index)}
                                >
                                    <FiTrash2 />
                                </Button>
                            </Box>
                        </Flex>
                    );
                });
            }
        }

        return (
            <Flex sx={{ flexDirection: 'column', width: '100%' }}>
                <Flex sx={{ width: '100%' }} flexDirection="column">
                    {all_page_logic_blocks}
                </Flex>
                <Box mt={3}>
                    <Button type="button" variant="secondary-gray" onClick={() => this.addLogicStatement(p_i)}>
                        + Add Logic
                    </Button>
                </Box>
            </Flex>
        );
    }

    addLogicStatement(page_index) {
        let { pageLogicRules } = this.state;
        if (!pageLogicRules[page_index]) {
            pageLogicRules[page_index] = [];
        }
        pageLogicRules[page_index].push(helpersScreener.getPageLogicRulesDefault());
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    removeLogicStatement(page_index, rule_index) {
        let { pageLogicRules } = this.state;

        if (pageLogicRules[page_index][rule_index]) {
            pageLogicRules[page_index].splice(rule_index, 1);
        }
        this.editElementPropQuestionLogicRules(pageLogicRules);
    }

    // THIS IS PAGE LOGIC
    getConditionalElement(rule_index, conditional_index, conditional) {
        let question_labels_raw = {};

        const all_pages = this.state.data;

        if (all_pages.length) {
            //console.log('pageLogicPageIndex', this.state.pageLogicPageIndex, all_pages)
            const current_question_index_in_page = all_pages[this.state.pageLogicPageIndex].indexOf(this.state.element);

            all_pages.forEach((page_questions, page_index) => {
                if (!question_labels_raw[page_index]) {
                    question_labels_raw[page_index] = [];
                }

                page_questions.forEach((pq, pq_index) => {
                    //console.log('pages', this.state.pageLogicPageIndex, page_index+1)
                    // only show question for current or preceding pages
                    if (this.state.pageLogicPageIndex >= page_index) {
                        //console.log('show')
                        // dont show questions after current question
                        // dont show logic for question we're adding logic for
                        // also show previous pages

                        if (!pq.static && !helpersScreener.questionDisabledInLogic(pq)) {
                            // for current page, get index of the question so you can show preceding questions only

                            const pq_index = all_pages[this.state.pageLogicPageIndex].indexOf(pq);

                            question_labels_raw[page_index].push(pq);
                        }
                    }
                });
            });

            // blank
            let question_labels = [];
            question_labels.push(<option value="">Select question</option>);
            Object.keys(question_labels_raw).forEach(page_index => {
                if (question_labels_raw[page_index].length) {
                    let question_labels_raw_page = [];
                    question_labels_raw[page_index].forEach(pq => {
                        const label_plain = helpers.removeHtml(pq.label);
                        question_labels_raw_page.push(<option value={pq.id}>{label_plain}</option>);
                    });

                    question_labels.push(
                        <optgroup label={`Page ${parseInt(page_index) + 1}`}>{question_labels_raw_page}</optgroup>
                    );
                }
            });

            //const question_element = helpersScreener.getQuestionById(all_pages, conditional.question_id);
            const question_element = helpersScreener.getQuestionById(all_pages, conditional.question_id);
            const question_element_type = question_element ? question_element.element : null;

            let additional_question_options = false;
            // super special thing for matrix
            if (question_element_type == 'Matrix' && helpersScreener.getMatrixType(question_element) == 'text') {
                additional_question_options = [];
                additional_question_options.push(<option></option>);
                let matrix_options = helpersStudy.generateMatrixAnswerCombinations(question_element.options);
                if (matrix_options) {
                    //console.log(matrix_options);
                    matrix_options.forEach(option => {
                        additional_question_options.push(
                            <option value={option.row_col_key}>{option.row_col_text}</option>
                        );
                    });
                }
            }

            // THIS IS PAGE LOGIC
            return (
                <Flex mt={2}>
                    {/*conditional.question_id}, {conditional.compare}, {conditional.value*/}
                    <Box mt={1} mr={3} ml={2}>
                        <FiCornerDownRight />
                    </Box>
                    <Box>
                        <Select
                            className="theme-input"
                            sx={{ width: '200px' }}
                            mr={3}
                            value={conditional.question_id}
                            onChange={e =>
                                this.editQuestionLogicRulesConditionalQuestionId(rule_index, conditional_index, e)
                            }
                        >
                            {question_labels}
                        </Select>
                    </Box>
                    {additional_question_options !== false && (
                        <Box>
                            <Select
                                className="theme-input"
                                sx={{ width: '200px' }}
                                mr={3}
                                value={conditional.question_id_child}
                                onChange={e =>
                                    this.editQuestionLogicRulesConditionalQuestionIdChild(
                                        rule_index,
                                        conditional_index,
                                        e
                                    )
                                }
                            >
                                {additional_question_options}
                            </Select>
                        </Box>
                    )}
                    <Box mt={1} sx={{ flexShrink: 0 }}>
                        is
                    </Box>
                    <Box>
                        <Select
                            className="theme-input"
                            sx={{ width: '120px' }}
                            ml={3}
                            mr={3}
                            value={conditional.compare}
                            onChange={e =>
                                this.editQuestionLogicRulesConditionalCompare(rule_index, conditional_index, e)
                            }
                        >
                            {helpersScreener.renderScreenerLogicOptionsByQuestion(question_element)}
                        </Select>
                    </Box>
                    <Box>
                        {helpersScreener.getQuestionValuesForLogic(
                            this.state.data,
                            conditional.question_id,
                            conditional.compare,
                            conditional.value,
                            conditional_index,
                            this.editPageLogicRulesConditionalValue,
                            rule_index,
                            this.state.pageLogicPageIndex
                        )}
                    </Box>
                    <Button
                        style={{ margin: '0 8px 0 8px' }}
                        variant="secondary-gray"
                        className="secondary-icon"
                        onClick={e => {
                            this.addConditional(rule_index, conditional_index);
                        }}
                        tabIndex="-1"
                    >
                        <FiPlus />
                    </Button>
                    {conditional_index > 0 && (
                        <Button
                            variant="secondary-gray"
                            className="secondary-icon"
                            style={{ margin: '0 0 0 0' }}
                            onClick={e => {
                                this.removeConditional(rule_index, conditional_index);
                            }}
                            tabIndex="-1"
                        >
                            <FiTrash2 />
                        </Button>
                    )}
                </Flex>
            );
        } else {
            return null;
        }
    }

    updateScreenerPageLogic() {
        const { toastManager } = this.props;

        const panel_form_id = this.props.match.params.panel_form_id;

        if (panel_form_id) {
            service_studies
                .updatePanelSignupScreenerPageLogic(panel_form_id, this.state.pageLogicRules)
                .then(screener => {
                    this.setState({ showPageLogicModal: false });
                })
                .catch(error => {
                    let errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        } else {
            const study_id = this.props.match.params.study_id;
            const screener_id = this.props.match.params.screener_id;

            if (!study_id || !screener_id) {
                alert('Unfortunately we can not find the study or screener. Please contact support@panelfox.io');
            }

            service_studies
                .updateScreenerPageLogic(study_id, screener_id, this.state.pageLogicRules)
                .then(screener => {
                    this.setState({ showPageLogicModal: false });
                })
                .catch(error => {
                    let errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    /**
     * Check if it's possible to avoid email input
     */
    isPossibleToAvoidEmailInput() {
        const emailColumn = this.state.availableColumns.find(column => column.title === COL_EMAIL);

        if (!emailColumn) return false;

        // Find the page index where the email input is located. Use the last in case of multiple email inputs.
        const emailPageIndex = this.state.data.findLastIndex(page => {
            return page.some(question => emailColumn.id === question.personCustomDataId);
        });

        // search for page logic in the previous pages
        const isAvoidableBecauseOfPageLogic = this.state.pageLogicRules.slice(0, emailPageIndex).some(logicRules => {
            if (!logicRules) return false;

            // if there is any logic rule that skip or disqualify the respondent then it's avoidable
            return logicRules.some(
                logicRule =>
                    ![SCREENER_PAGE_LOGIC_QUALIFY, SCREENER_PAGE_LOGIC_SHOW, '', undefined, null].includes(
                        logicRule.setup.action
                    )
            );
        });

        if (isAvoidableBecauseOfPageLogic) return true;

        // search for qual_disqual in the previous pages
        return this.state.data.slice(0, emailPageIndex).some(page => {
            return page.some(
                question =>
                    question.qual_disqual &&
                    question.options.some(
                        // if there is a question that dosn't have a qualification
                        option => ![SCREENER_QDQS_ACCEPT, '', undefined, null].includes(option.qualification)
                    )
            );
        });
    }

    /**
     * Check if the warning about avoiding email input should be displayed
     */
    shouldShowEmailAvoidingWarning() {
        // Display the warning only if the screener is for anyone
        if (!this.props.isEmailRequired) return false;

        return this.isPossibleToAvoidEmailInput();
    }

    openPageLogic(page_index) {
        this.setState({ showPageLogicModal: true, pageLogicPageIndex: page_index });
    }

    doesPageHavePageLogic(page_index) {
        if (this.state.pageLogicRules) {
            return this.state.pageLogicRules[page_index] &&
                this.state.pageLogicRules[page_index] &&
                this.state.pageLogicRules[page_index].length > 0
                ? true
                : false;
        }
    }

    componentDidCatch(error, errorInfo) {
        helpers.trackError(error);
    }

    create(item, itemDefaults, customColumnLabel) {
        const elementOptions = {
            id: ID.uuid(),
            element: item.element || item.key,
            text: item.name,
            static: item.static,
            required: true,
            save_to_panel: false,
            showDescription: item.showDescription,
            personCustomDataId: '',
            question_logic: false,
            question_logic_rules: helpersScreener.getQuestionLogicRulesDefault()
        };

        if (this.props.show_description === true && !item.static) {
            elementOptions.showDescription = true;
        }

        if (item.static) {
            elementOptions.bold = false;
            elementOptions.italic = false;
        }

        if (item.canHaveAnswer) {
            elementOptions.canHaveAnswer = item.canHaveAnswer;
        }

        if (item.canReadOnly) {
            elementOptions.readOnly = false;
        }

        if (item.canDefaultToday) {
            elementOptions.defaultToday = false;
        }

        if (item.hasOwnProperty('content')) {
            elementOptions.content = item.content;
        }

        if (item.href) {
            elementOptions.href = item.href;
        }

        elementOptions.canHavePageBreakBefore = item.canHavePageBreakBefore !== false;
        elementOptions.canHaveAlternateForm = item.canHaveAlternateForm !== false;
        elementOptions.canHaveDisplayHorizontal = item.canHaveDisplayHorizontal !== false;
        elementOptions.canHaveOptionCorrect = item.canHaveOptionCorrect !== false;
        elementOptions.canHaveOptionValue = item.canHaveOptionValue !== false;
        elementOptions.canPopulateFromApi = item.canPopulateFromApi !== false;

        if (item.key === 'Image') {
            elementOptions.src = item.src;
        }

        if (item.key === 'Checkboxes' || item.key === 'RadioButtons') {
            elementOptions.num_columns = item.num_columns;
        }

        if (item.key === 'DatePicker') {
            elementOptions.dateFormat = item.dateFormat;
            elementOptions.timeFormat = item.timeFormat;
            elementOptions.showTimeSelect = item.showTimeSelect;
            elementOptions.showTimeSelectOnly = item.showTimeSelectOnly;
        }

        console.log(item, 'adddpipe');

        if (item.key === 'AddPipeInput') {
            elementOptions.video_recording_time = item.video_recording_time;
        }

        if (item.key === 'FileUpload') {
            elementOptions.upload_files_cnt = item.upload_files_cnt;
        }

        if (item.key === 'Download') {
            elementOptions._href = item._href;
            elementOptions.file_path = item.file_path;
        }

        // hidden values should not be required
        if (item.key === 'HiddenValue') {
            elementOptions.required = false;
        }

        if (item.key === 'RangeElement') {
            elementOptions.step = item.step;
            elementOptions.min_value = item.min_value;
            elementOptions.max_value = item.max_value;
            elementOptions.min_label = item.min_label;
            elementOptions.max_label = item.max_label;
        }

        if (item.default_value !== undefined) {
            elementOptions.default_value = item.default_value;
        }

        if (item.defaultValue) {
            elementOptions.defaultValue = item.defaultValue;
        }

        if (item.field_name) {
            elementOptions.field_name = item.field_name + ID.uuid();
        }

        if (item.hasOwnProperty('label')) {
            elementOptions.label = item.label;
        }

        if (item.options) {
            if (item.options.length > 0) {
                elementOptions.options = item.options;
            } else {
                elementOptions.options = Preview._defaultItemOptions(elementOptions.element);
            }
        }

        if (item.option_order !== undefined) {
            elementOptions.option_order = item.option_order;
        }

        if (item.qual_disqual) {
            elementOptions.qual_disqual = item.qual_disqual;
        }

        /* opportunity to pass in defaults */

        if (itemDefaults) {
            if (elementOptions.static) {
                elementOptions.content = itemDefaults.content
                    ? itemDefaults.content
                    : itemDefaults.label
                    ? itemDefaults.label
                    : '';
            } else {
                elementOptions.label = itemDefaults.label
                    ? itemDefaults.label
                    : itemDefaults.content
                    ? itemDefaults.content
                    : '';

                if (itemDefaults.hasOwnProperty('options')) {
                    elementOptions.options = itemDefaults.options;
                }

                if (itemDefaults.hasOwnProperty('other_specify_label')) {
                    elementOptions.other_specify_label = itemDefaults.other_specify_label;
                }

                if (itemDefaults.hasOwnProperty('none_above_specify_label')) {
                    elementOptions.none_above_specify_label = itemDefaults.none_above_specify_label;
                }

                if (itemDefaults.hasOwnProperty('add_other')) {
                    elementOptions.add_other = itemDefaults.add_other;
                }

                if (itemDefaults.hasOwnProperty('none_of_the_above')) {
                    elementOptions.none_of_the_above = itemDefaults.none_of_the_above;
                }

                if (itemDefaults.hasOwnProperty('option_order')) {
                    elementOptions.option_order = itemDefaults.option_order;
                }

                if (itemDefaults.hasOwnProperty('qual_disqual')) {
                    elementOptions.qual_disqual = itemDefaults.qual_disqual;
                }

                if (itemDefaults.hasOwnProperty('required')) {
                    elementOptions.required = itemDefaults.required;
                }

                if (itemDefaults.hasOwnProperty('num_columns')) {
                    elementOptions.num_columns = itemDefaults.num_columns;
                }

                if (itemDefaults.hasOwnProperty('validation')) {
                    elementOptions.validation = itemDefaults.validation;
                }

                if (itemDefaults.hasOwnProperty('validation_rules')) {
                    elementOptions.validation_rules = itemDefaults.validation_rules;
                }

                if (itemDefaults.hasOwnProperty('reporting_values')) {
                    elementOptions.reporting_values = itemDefaults.reporting_values;
                }

                if (itemDefaults.hasOwnProperty('question_logic')) {
                    elementOptions.question_logic = itemDefaults.question_logic;
                }

                if (itemDefaults.hasOwnProperty('question_logic_rules')) {
                    elementOptions.question_logic_rules = itemDefaults.question_logic_rules;
                }

                if (itemDefaults.hasOwnProperty('save_to_panel')) {
                    elementOptions.save_to_panel = itemDefaults.save_to_panel;
                }

                if (itemDefaults.hasOwnProperty('personCustomDataId')) {
                    elementOptions.personCustomDataId = itemDefaults.personCustomDataId;
                }
            }
        }

        if (customColumnLabel) {
            elementOptions.save_to_panel = true;

            let panelMapColumn = this.state.availableColumns.find(ac => ac.title == customColumnLabel);
            if (panelMapColumn) {
                elementOptions.personCustomDataId = panelMapColumn.id;
            }
        }

        return elementOptions;
    }

    timelineAction = type => () => {
        store.dispatch('timelineAction', type);
    };

    render() {
        //console.log('preview.jsx rendering...');

        let classes = this.props.className;
        const toolbarProps = {
            showDescription: this.props.show_description
        };
        if (this.props.toolbarItems) {
            toolbarProps.items = this.props.toolbarItems;
        }

        let pages = [];

        //console.log('this is data for render', this.state.data)

        let screener_link = false;
        //console.log('sreener uuid', this.props.uuid)
        // get account
        const account = auth.getAccount();
        if (this.props.screener_for == 'panel') {
            //helpersScreener.getPanelSignupLink()
            screener_link = helpersScreener.getPanelSignupLink(account, null, this.props.match.params.panel_form_id, {
                defaultDomain: true
            });
        } else {
            if (this.props.uuid && account) {
                screener_link = helpersScreener.getScreenerLinkNew(this.props.uuid, account, { defaultDomain: true });
            }
        }

        const email_custom_column = this.state.availableColumns.find(column => column.title == COL_EMAIL);

        let found_email_question = false;
        let errors_count = 0;
        let errorLinker = null;
        const table_of_contents = [];

        this.state.data.forEach((page_data, page_index) => {
            //console.log('page number', page_index)
            const found = page_data.filter(x => !!x);

            try {
                table_of_contents.push({
                    type: 'page',
                    pageIndex: page_index
                });
                if (found.length) {
                    found.forEach((item, item_index) => {
                        if (
                            email_custom_column &&
                            item.personCustomDataId == email_custom_column.id &&
                            item.save_to_panel == true &&
                            item.required == true
                        ) {
                            found_email_question = true;
                        }

                        let error = false;
                        const item_checked_panel = item.hasOwnProperty('save_to_panel') ? item.save_to_panel : false;
                        const item_personCustomDataId = item.hasOwnProperty('personCustomDataId')
                            ? item.personCustomDataId
                            : '';

                        if (item.hasOwnProperty('label') && !['Signature', 'FileUpload'].includes(item.element)) {
                            if (
                                (item_checked_panel && !item_personCustomDataId) ||
                                (item_checked_panel &&
                                    item_personCustomDataId &&
                                    !this.state.availableColumns_byId[item_personCustomDataId]) ||
                                (this.props.screener_for === 'panel' && !item_checked_panel)
                            ) {
                                errors_count++;
                                error = true;

                                if (!errorLinker) {
                                    errorLinker = {
                                        page: page_index + 1,
                                        item: item_index + 1
                                    };
                                }
                            }
                        }

                        if (
                            item_checked_panel &&
                            item.hasOwnProperty('options') &&
                            ['Checkboxes', 'RadioButtons', 'Dropdown'].includes(item.element)
                        ) {
                            const foundPanelProperty = this.state.availableColumns_byId[item_personCustomDataId];
                            if (foundPanelProperty) {
                                item.options.forEach(option => {
                                    if (
                                        (foundPanelProperty.type === 'boolean' &&
                                            !helpers.isStringMappedToBoolean(option.value)) ||
                                        (foundPanelProperty.type !== 'boolean' &&
                                            foundPanelProperty.definition &&
                                            foundPanelProperty.definition.findIndex(
                                                ppItem => ppItem.value === option.value
                                            ) === -1)
                                    ) {
                                        if (!error) errors_count++;
                                        error = true;

                                        if (!errorLinker) {
                                            errorLinker = {
                                                page: page_index + 1,
                                                item: item_index + 1
                                            };
                                        }
                                    }
                                });
                            }
                        }

                        table_of_contents.push({
                            type: 'item',
                            pageIndex: page_index,
                            itemIndex: item_index,
                            itemName: item.static ? helpers.removeHtml(item.content) : helpers.removeHtml(item.label),
                            itemHasError: error
                        });
                    });
                }
            } catch (e) {
                helpers.trackError(e);
            }

            const items = found.map((item, index) => (
                <div className="screener-q-scroll-margin-top" id={`pq-${page_index + 1}-${index + 1}`}>
                    {this.getElement(page_index, item, index)}
                </div>
            ));

            const page_preview_link = `${screener_link}?is_preview=1&is_preview_start_page=${page_index + 1}`;
            const insidePage = [];
            insidePage.push(this.renderPage(page_index, items));
            insidePage.push(
                <Toolbar
                    {...toolbarProps}
                    screener_id={this.props.match.params.screener_id}
                    page_number={page_index}
                    addpipe_token={account.addpipe_token}
                    addpipe_eid={account.addpipe_eid}
                    create={this.create}
                />
            );

            let page_options_more_items = [];
            if (screener_link) {
                page_options_more_items.push({
                    icon: <FiEye />,
                    title: 'Preview page',
                    onClick: () => {
                        helpers.openTab(page_preview_link);
                    }
                });
            }

            page_options_more_items.push({
                icon: <FiGitMerge />,
                title: (this.doesPageHavePageLogic(page_index) ? 'Edit' : 'Add') + ' page logic',
                onClick: () => {
                    this.openPageLogic(page_index);
                }
            });

            if (this.state.data.length > 1) {
                page_options_more_items.push({
                    icon: <FiMove />,
                    title: 'Move page',
                    onClick: () => {
                        this.setState({ showPageReorderModal: true });
                    }
                });
            }

            if (page_index > 0) {
                page_options_more_items.push({
                    icon: <FiChevronsUp />,
                    title: 'Merge w/ page above',
                    onClick: () => {
                        this.mergePage(page_index);
                    }
                });

                page_options_more_items.push({
                    icon: <FiTrash2 />,
                    title: 'Delete page',
                    onClick: () => {
                        this.deletePage(page_index, false);
                    }
                });
            }

            if (page_index == 0) {
                //
            }

            let page_options_more = (
                <div className="inline-block va-top relative">
                    <Button
                        variant="secondary-gray"
                        className="secondary-icon va-top"
                        onClick={() => {
                            this.setState({ showPageOptionsMoreDropdown_pageIndex: page_index });
                            analytics.track('study-screener-page-more_button-click');
                        }}
                    >
                        <FiMoreVertical style={{ marginRight: 0 }} />
                    </Button>

                    {this.state.showPageOptionsMoreDropdown_pageIndex == page_index && (
                        <NiceDropdown
                            positionLeft="auto"
                            positionRight="0px"
                            onClose={() => {
                                this.setState({ showPageOptionsMoreDropdown_pageIndex: -1 });
                            }}
                            items={page_options_more_items}
                        />
                    )}
                </div>
            );

            pages.push(
                <div className={classes} style={{ position: 'relative' }}>
                    <div className="react-form-builder-page-number">
                        <Flex sx={{ justifyContent: 'space-between' }}>
                            <Box className="title strong text-primary">
                                <span style={{ display: 'inline-block', marginRight: '16px' }}>
                                    Page {page_index + 1}
                                </span>
                            </Box>

                            <Flex fontSize={1}>
                                {this.doesPageHavePageLogic(page_index) ? (
                                    <span className="va-top medium text-primary" style={{ margin: '6px 24px 0 0' }}>
                                        <FiGitMerge
                                            style={{ margin: '2px 4px 0 0', verticalAlign: 'top', fontSize: '14px' }}
                                        />{' '}
                                        Page logic enabled
                                    </span>
                                ) : (
                                    ''
                                )}

                                {/*page_index != 0 &&
                                    <a href={page_preview_link} target="_blank"><Button variant='secondary-gray' className='va-top' mr={3}><FiEye /> Preview</Button></a>
                                */}

                                {page_options_more}
                            </Flex>
                        </Flex>
                    </div>
                    {/*JSON.stringify(this.state.pageLogicRules[page_index])*/}
                    {insidePage}
                </div>
            );

            pages.push(
                <Box sx={{ textAlign: 'center', position: 'relative' }} mt={3} fontSize={1}>
                    <Box
                        style={{
                            height: '1px',
                            background: '#ddd',
                            position: 'absolute',
                            top: '16px',
                            left: 0,
                            right: 0
                        }}
                    >
                        {' '}
                    </Box>
                    <Box style={{ background: '#f6f6f6', position: 'relative', display: 'inline-block' }} px={3}>
                        <Button variant="secondary" onClick={this.addPage.bind(this, page_index)}>
                            Add page
                        </Button>
                    </Box>
                </Box>
            );
            pages.push(
                <>
                    <br /> <br />
                </>
            );

            //page_index++;
        });

        const main_preview_link = `${screener_link}?is_preview=1`;

        return (
            <>
                <Flex
                    sx={{
                        height: '80px',
                        width: '100%',
                        justifyContent: 'space-between',
                        padding: '24px 0 0',
                        position: 'sticky',
                        top: 0,
                        background: '#f6f6f6',
                        zIndex: 100
                    }}
                >
                    <H1 className="experience-like-h1" style={{ lineHeight: '24px' }}>
                        Form Builder
                    </H1>
                    <Flex style={{ gap: '16px' }}>
                        {this.props.screener_for != 'panel' && this.state.form_updated_at && (
                            <Button
                                variant="link"
                                className="fs-12"
                                id="screener-last-saved-button"
                                onClick={() => {
                                    this.setState({ showScreenerVersionsModal: true });
                                }}
                            >
                                {/*helpers.newFeatureTag(moment("04-09-2022", "MM-DD-YYYY"), {margin: '0 4px 0 0'})*/}
                                Last saved {moment.utc(this.state.form_updated_at).fromNow()}
                            </Button>
                        )}

                        {!found_email_question && this.props.form_type == FORM_TYPE_CONSENT.id && (
                            <ErrorLabel>Email is missing</ErrorLabel>
                        )}
                        {this.state.isEmailAvoidingWarning && (
                            <Badge style={{ height: '32px', lineHeight: '22px' }} type="warn">
                                <FiAlertTriangle style={{ margin: '3px 8px 3px 0' }} /> Survey logic may cause email
                                collection issue
                                <a
                                    href="#"
                                    data-beacon-article="632dd658c5dd38351401b15e"
                                    className="help-question warn"
                                    style={{ margin: '1px 0 1px 8px' }}
                                >
                                    ?
                                </a>
                            </Badge>
                        )}

                        {errors_count > 0 && errorLinker && (
                            <a href={`#pq-${errorLinker.page}-${errorLinker.item}`}>
                                <ErrorLabel>
                                    {errors_count} {errors_count === 1 ? 'error' : 'errors'} to resolve
                                </ErrorLabel>
                            </a>
                        )}

                        <Button
                            variant="secondary-gray"
                            tabIndex="-1"
                            onClick={this.timelineAction(TIMETRAVEL_UNDO.type)}
                        >
                            <FiRotateCcw /> {TIMETRAVEL_UNDO.label}
                        </Button>

                        <Button
                            variant="secondary-gray"
                            tabIndex="-1"
                            onClick={this.timelineAction(TIMETRAVEL_REDO.type)}
                        >
                            {TIMETRAVEL_REDO.label} <FiRotateCw style={{ marginLeft: '4px' }} />
                        </Button>

                        <a href={main_preview_link} target="_blank" className="va-top">
                            <Button mr={0} variant="primary" className="va-top" tabIndex="-1">
                                <FiEye /> Preview
                            </Button>
                        </a>
                    </Flex>
                </Flex>

                <Flex className="screener-pages-wrapper" sx={{ width: '100%', position: 'relative' }}>
                    <Box sx={{ flexShrink: 0, marginRight: '32px', marginTop: 0, position: 'relative' }}>
                        <Box
                            style={{
                                overflow: 'auto',
                                position: 'sticky',
                                maxHeight: 'calc(100vh - 112px)',
                                top: '80px',
                                width: '320px',
                                background: '#fff',
                                borderRadius: '8px',
                                padding: '8px 0'
                            }}
                        >
                            <Box>
                                <List
                                    values={table_of_contents}
                                    onChange={({ oldIndex, newIndex }) => {
                                        if (
                                            newIndex === oldIndex ||
                                            newIndex === 0 ||
                                            table_of_contents[oldIndex].type !== 'item'
                                        ) {
                                            return;
                                        }

                                        const dragItem = table_of_contents[oldIndex];
                                        const hoverItem =
                                            table_of_contents[newIndex > oldIndex ? newIndex : newIndex - 1];
                                        // console.log(dragItem, hoverItem);

                                        this.tableOfContentsMoveCard(dragItem, hoverItem);
                                    }}
                                    renderList={({ children, props }) => <div {...props}>{children}</div>}
                                    renderItem={({ value, index, props, isDragged, isSelected }) =>
                                        this.tableOfContentsRenderItem(value, index, props, isDragged, isSelected)
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        {pages}
                        <div
                            className={`form-builder-edit-form ${this.props.editMode ? 'is-editing' : ''}`}
                            ref={this.editForm}
                        >
                            {this.props.editElement !== null && (
                                <>
                                    <FormElementsEdit
                                        showSaveCancel={window.sb_v2 ? true : false}
                                        screener_for={this.props.screener_for}
                                        //index={index}
                                        all_questions={this.state.data}
                                        //page_index={page_index}
                                        showCorrectColumn={this.props.showCorrectColumn}
                                        files={this.props.files}
                                        manualEditModeOff={this.manualEditModeOff}
                                        preview={this}
                                        element={this.props.editElement}
                                        //updateElement={this.updateElement}
                                        _onSave={this.updateElement}
                                        _onClose={this.editModeOff}
                                        _onDestroy={() => this._onDestroy(page_index, item)}
                                        _onDuplicate={() => this._onDuplicate(page_index, item)}
                                        //read_only={this.props.read_only}
                                        _onMoveElement={(dragIndex, new_page_index, hoverIndex) => {
                                            console.log('...', page_index, dragIndex, new_page_index, hoverIndex);
                                            this.moveCard(page_index, dragIndex, new_page_index, hoverIndex);
                                        }}
                                        create={this.create}
                                        panelColumnsChangedCallback={this.onPanelColumnsChanged}
                                    />
                                </>
                            )}
                        </div>
                    </Box>
                </Flex>

                <br />
                <br />
                <br />
                <br />
                <ReactModal
                    isOpen={this.state.showPageLogicModal}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => {
                        this.setState({ showPageLogicModal: false });
                    }}
                    style={{ content: { width: '980px' } }}
                >
                    <Box p={4} fontSize={1} sx={{ overflow: 'scroll' }}>
                        <H1>Page {this.state.pageLogicPageIndex + 1} Logic</H1>
                        <Box mb={3}>
                            <a href="https://docs.panelfox.io/article/97-survey-logic" target="_blank">
                                How to use Page Logic <FiExternalLink className="va-top" style={{ marginTop: '3px' }} />
                            </a>
                        </Box>
                        <Box>
                            <Flex>{this.renderPageLogicRules()}</Flex>
                        </Box>
                    </Box>
                    <Box
                        px={4}
                        py={3}
                        style={{
                            position: 'sticky',
                            bottom: 0,
                            background: '#f7f7f7',
                            borderTop: '1px solid #eee',
                            zIndex: 100,
                            textAlign: 'right'
                        }}
                    >
                        <Button
                            type="button"
                            variant="transparent"
                            mr={3}
                            onClick={() => {
                                this.setState({ showPageLogicModal: false });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="button" variant="primary" mr={0} onClick={this.updateScreenerPageLogic}>
                            Save
                        </Button>
                    </Box>
                </ReactModal>

                <ReactModal
                    isOpen={this.state.showPageReorderModal}
                    shouldCloseOnOverlayClick
                    onRequestClose={this.cancelReorderPages}
                    style={{ content: { width: '400px' } }}
                >
                    <Box p={4} fontSize={1} sx={{ overflow: 'scroll' }}>
                        <H1>Reorder Pages</H1>
                        <Box>
                            <Box sx={{ color: 'black' }}>
                                <List
                                    values={this.state.pages_to_reorder}
                                    onChange={({ oldIndex, newIndex }) => {
                                        //console.log(oldIndex, newIndex)
                                        this.reorderPages(oldIndex, newIndex);
                                    }}
                                    renderList={({ children, props }) => <div {...props}>{children}</div>}
                                    renderItem={({ value, index, props, isDragged, isSelected }) => {
                                        return (
                                            <div {...props}>
                                                <Box
                                                    data-movable-handle
                                                    style={{ cursor: isDragged ? 'grabbing' : 'grab' }}
                                                    fontSize={1}
                                                >
                                                    <Box className="border rounded bg-white" px={3} py={3}>
                                                        <div>
                                                            <FiMove />
                                                            &nbsp;&nbsp;
                                                            <span className="medium">{value.page_title}</span>
                                                            {this.doesPageHavePageLogic(value.page_index) && (
                                                                <Box mt={2} className="warning-box-small">
                                                                    <small>Contains page logic</small>
                                                                </Box>
                                                            )}
                                                        </div>
                                                    </Box>
                                                    <br />
                                                </Box>
                                            </div>
                                        );
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        px={4}
                        py={3}
                        style={{
                            position: 'sticky',
                            bottom: 0,
                            background: '#f7f7f7',
                            borderTop: '1px solid #eee',
                            zIndex: 100,
                            textAlign: 'right'
                        }}
                    >
                        <Button type="button" variant="transparent" mr={3} onClick={this.cancelReorderPages}>
                            Cancel
                        </Button>
                        <Button type="button" variant="primary" mr={0} onClick={this.reorderPagesSave}>
                            Save
                        </Button>
                    </Box>
                </ReactModal>

                <ReactModal
                    isOpen={this.state.showScreenerVersionsModal}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => {
                        this.setState({ showScreenerVersionsModal: false });
                    }}
                    style={{ content: { width: '980px' } }}
                >
                    <ScreenerVersions
                        study_id={this.props.match.params.study_id}
                        screener_id={this.props.match.params.screener_id}
                        onClose={() => {
                            this.setState({ showScreenerVersionsModal: false });
                        }}
                    />
                </ReactModal>
            </>
        );
    }
}
Preview.defaultProps = {
    showCorrectColumn: false,
    files: [],
    editMode: false,
    editElement: null,
    className: 'react-form-builder-preview'
};

export default withRouter(withToastManager(Preview));
