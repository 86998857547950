import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NiceWrapper from 'components/NiceWrapper';
import { Flex, Box, Heading, Button } from 'rebass';
import { Label, Switch, Input } from '@rebass/forms';
import helpers from 'utils/helpers';
import helpersStudy from 'utils/helpers-study';
import helpersScreener from 'utils/helpers-screener';
import services from 'services/services';
import service_people from 'services/people';
import service_accounts from 'services/accounts';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { ReactFormGenerator } from 'components/ReactFormBuilder';
import { FiChevronLeft, FiArrowRight, FiGift } from 'react-icons/fi';
import StatusIndicator from 'components/StatusIndicator';

function PanelistProfile(props) {
    const [loading, setLoading] = useState(true);
    const [personData, setPersonData] = useState({});
    const [account, setAccount] = useState({
        panel_forms: []
    });
    const [panelForm, setPanelForm] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formEndText, setFormEndText] = useState();
    const [isRedeeming, setIsRedeeming] = useState(false);

    const { addToast } = useToasts();
    const history = useHistory();

    useEffect(() => {
        checkPersonAuth();
        getAccountXHR();

        if (props.match.params.panel_form_id) {
            getPanelForm();
        }
    }, [props.match.params.account_uuid, props.match.params.panel_form_id]);

    function getPanelForm() {
        service_people
            .getPanelSignupPublic(props.match.params.account_uuid, props.match.params.panel_form_id)
            .then(response_panel_form => {
                //const { account, screener, screener_translation } = res_data;

                setPanelForm(response_panel_form);
                console.log('response_panel_form', response_panel_form);
            })
            .catch(error => {
                services.parseAndTrackXhrErrors(error);
            });
    }

    function getAccountXHR() {
        service_accounts
            .getAccountBySlug(props.match.params.account_uuid)
            .then(account => {
                setAccount(account);
                setLoading(false);
            })
            .catch(e => {
                services.parseAndTrackXhrErrors(e);
            });
    }

    function checkPersonAuth() {
        service_people
            .checkAuthPanelistExperience(props.match.params.account_uuid)
            .then(person => {
                //console.log('person', person);
                setPersonData(person);
            })
            .catch(e => {
                //console.log(e)
                redirectToPanelistLogin();
            });
    }

    function redirectToPanelistLogin() {
        history.push(`/panelist/${props.match.params.account_uuid}/login`);
    }

    function redirectToPanelistProfile() {
        setFormEndText(false);
        history.push(`/panelist/${props.match.params.account_uuid}/profile`);
    }

    function checkShouldShowScreeners(account) {
        if (account && account.public_settings && account.public_settings.panelist_view_studies_allow) {
            return true;
        }
    }

    function checkShouldShowPanelistPoints(account) {
        //console.log('TRUE???', account)
        if (account && account.public_settings && account.public_settings.pv_points) {
            //console.log('TRUE???')
            return true;
        }
    }

    function logoutPersonAuth() {
        service_people
            .logoutPanelistExperience()
            .then(() => {
                // set token as null
                helpers.setPanelistJWTToken(null);
                redirectToPanelistLogin();
            })
            .catch(e => console.log(e));
    }

    function onFormSubmit(formAnswers, isQualified) {
        console.log(formAnswers, isQualified);

        setIsSubmitting(true);

        const screen_type = 'panel';

        services
            .editPanelProfileAnswers(
                props.match.params.account_uuid,
                props.match.params.panel_form_id,
                formAnswers,
                personData.uuid
            )
            .then(person => {
                addToast('Saved', {
                    appearance: 'success',
                    autoDismiss: true
                });

                if (
                    panelForm.screener_translation &&
                    panelForm.screener_translation.confirmation_redirect &&
                    panelForm.screener_translation.confirmation_redirect_url &&
                    helpers.isValidUrl(panelForm.screener_translation.confirmation_redirect_url)
                ) {
                    helpers.redirectAfterPause(panelForm.screener_translation.confirmation_redirect_url);
                } else {
                    setIsSubmitting(false);
                    //console.log('stay on this page :)')

                    const form_end_text = isQualified
                        ? panelForm.screener_translation.confirmation_page_text
                        : panelForm.screener_translation.disqualification_page_text;

                    // check if there is any text  (strip html, beause there can be a <br/> or <p> in there)
                    if (helpers.removeHtml(form_end_text)) {
                        setFormEndText(form_end_text);
                    } else {
                        redirectToPanelistProfile();
                    }
                }
            })
            .catch(error => {
                setIsSubmitting(false);
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function getScreenerTitleFromUuidPanelist(spb, screener_uuid) {
        let title = 'View Survey';
        try {
            if (
                spb &&
                spb.screener_info &&
                spb.screener_info[screener_uuid] &&
                spb.screener_info[screener_uuid]['public_title']
            ) {
                title = spb.screener_info[screener_uuid]['public_title'];
            }
        } catch (e) {
            helpers.trackError(e);
        }
        return title;
    }

    function displayAvailableRedeem(account, personData) {
        let jsx = [];

        let min_currency;
        if (account.public_settings.points_redeem_min && account.public_settings.points_to_money) {
            min_currency = account.public_settings.points_redeem_min / account.public_settings.points_to_money;

            if (min_currency) {
                let available_currency = 0;
                if (personData.available_points > 0) {
                    available_currency = personData.available_points / account.public_settings.points_to_money;
                }
                jsx.push(
                    <Box className="fs-body-16">
                        {personData.available_points} points earned (
                        {helpers.renderCurrencyAmount(account.incentive_currency, available_currency)})
                    </Box>
                );
                jsx.push(
                    <Box className="fs-body-16">
                        Minimum points to redeem: {account.public_settings.points_redeem_min}
                    </Box>
                );
            }
        }

        //{helpers.renderCurrencyAmount(account.incentive_currency, min_currency)}

        if (
            account.public_settings.points_redeem_min &&
            personData.available_points >= account.public_settings.points_redeem_min
        ) {
            jsx.push(
                <>
                    <Box>
                        <Button
                            variant="link"
                            style={helpers.accountThemeLinkStyle(account)}
                            onClick={onClickRedeem}
                            disabled={isRedeeming}
                        >
                            <FiGift /> {isRedeeming ? 'Redeeming...' : 'Redeem Points'}
                        </Button>
                    </Box>
                    <Box className="text-secondary fs-12">
                        After you redeem successfully, you will recieve an email with your reward.
                    </Box>
                </>
            );
        }

        return jsx;
    }

    function onClickRedeem(e) {
        if (confirm('Are you sure you want to redeem?')) {
            setIsRedeeming(true);

            service_people
                .panelistRedeemPoints(props.match.params.account_uuid, personData.uuid)
                .then(response_data => {
                    console.log('onClickRedeem response_data', response_data);

                    addToast('Redeemed! You will get an email with your reward.', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    setIsRedeeming(false);

                    checkPersonAuth();
                })
                .catch(error => {
                    setIsRedeeming(false);
                    const errorText = services.parseAndTrackXhrErrors(error);

                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    return (
        <div style={{ margin: 'auto' }}>
            <Helmet titleTemplate="%s">
                <title>{account && account.title ? account.title : 'Panel Profile'}</title>
                {helpers.renderFavicon(account)}
            </Helmet>

            {loading ? (
                <Box my={4} style={{ width: '100%', height: '80px', position: 'relative' }}>
                    <LoadingWrapper>
                        <LoadingIndicator />
                    </LoadingWrapper>
                </Box>
            ) : account && account.title ? (
                <>
                    <Box style={{ textAlign: 'center' }} mt={4} mb={3}>
                        {account.logo ? (
                            <img
                                src={account.logo}
                                style={{ maxWidth: '100%', maxHeight: helpers.getPanelistLogoHeight(account) }}
                                alt="Account Logo"
                            />
                        ) : (
                            <div style={{ height: '32px' }}></div>
                        )}
                    </Box>

                    <NiceWrapper style={{ background: 'white', color: 'black', padding: '0', margin: '0 32px' }}>
                        <Box p={4}>
                            <Box fontSize={1} style={{ minWidth: '400px', maxWidth: '900px' }}>
                                {personData && personData.uuid ? (
                                    panelForm && panelForm.screener ? (
                                        <>
                                            <Button variant="secondary-gray">
                                                <Link
                                                    to={`/panelist/${props.match.params.account_uuid}/profile`}
                                                    style={helpers.accountThemeLinkStyle(account)}
                                                >
                                                    <FiChevronLeft />
                                                    Back to profile
                                                </Link>
                                            </Button>
                                            <br />
                                            <br />
                                            {formEndText ? (
                                                <Box
                                                    p={4}
                                                    sx={{
                                                        background: '#ffe7c9',
                                                        borderRadius: '4px'
                                                    }}
                                                    className="text-primary quill-render"
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: formEndText }} />
                                                </Box>
                                            ) : (
                                                <ReactFormGenerator
                                                    data={panelForm.screener} // Question data
                                                    page_logic={panelForm.page_logic}
                                                    translation={panelForm.screener_translation}
                                                    branding={account.branding}
                                                    addpipe_token={account.addpipe_token}
                                                    addpipe_eid={account.addpipe_eid}
                                                    person_defaults={personData ? personData.custom_data_values2 : null} // Question data
                                                    action_name={
                                                        panelForm.screener_translation &&
                                                        panelForm.screener_translation.submit_button_text
                                                            ? panelForm.screener_translation.submit_button_text
                                                            : ''
                                                    }
                                                    next_name={
                                                        panelForm.screener_translation &&
                                                        panelForm.screener_translation.next_button_text
                                                            ? panelForm.screener_translation.next_button_text
                                                            : ''
                                                    }
                                                    back_name={
                                                        panelForm.screener_translation &&
                                                        panelForm.screener_translation.cancel_button_text
                                                            ? panelForm.screener_translation.cancel_button_text
                                                            : ''
                                                    }
                                                    is_required_name={
                                                        panelForm.screener_translation &&
                                                        panelForm.screener_translation.is_required_text
                                                            ? panelForm.screener_translation.is_required_text
                                                            : ''
                                                    }
                                                    dismiss_name={
                                                        panelForm.screener_translation &&
                                                        panelForm.screener_translation.dismiss_text
                                                            ? panelForm.screener_translation.dismiss_text
                                                            : ''
                                                    }
                                                    onSubmit={(formAnswers, isQualified) => {
                                                        onFormSubmit(formAnswers, isQualified);
                                                    }}
                                                    is_submitting={isSubmitting}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Box mb="24px">
                                                <Flex justifyContent={'space-between'}>
                                                    <Box
                                                        as="h1"
                                                        mb="10px"
                                                        sx={{ maxWidth: '300px' }}
                                                        className="fs-headline-20 ellipsis"
                                                    >
                                                        {helpers.personGetFullName(personData)}
                                                    </Box>
                                                    <Button
                                                        ml={3}
                                                        mr={0}
                                                        sx={{ verticalAlign: 'top' }}
                                                        variant="secondary"
                                                        className="secondary-red"
                                                        onClick={logoutPersonAuth}
                                                    >
                                                        Logout
                                                    </Button>
                                                </Flex>
                                                <Box className="fs-body-16">{helpers.personGetEmail(personData)}</Box>
                                            </Box>

                                            {/* <Box mb={2} mt={4} className={'panelist-profile-h2'}>Introduction</Box> */}
                                            {account && account.panel_intro_text && (
                                                <Box sx={{ maxWidth: '400px' }} mb="40px" className="quill-render">
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: account.panel_intro_text }}
                                                    />
                                                </Box>
                                            )}

                                            {checkShouldShowPanelistPoints(account) == true && (
                                                <>
                                                    <Box mb="10px" className="fs-headline-20">
                                                        My Points
                                                    </Box>
                                                    <Box mb="40px">{displayAvailableRedeem(account, personData)}</Box>
                                                </>
                                            )}

                                            <Box as="h2" mb="10px" className="fs-headline-20">
                                                My Profile
                                            </Box>
                                            <Flex flexDirection="column" mb="40px">
                                                {account.panel_forms.map((panelForm, index) => {
                                                    const isCompleted =
                                                        !!personData && // if personData exists
                                                        personData.fullfilled_form_ids.includes(panelForm.id); // and the person has completed this form

                                                    return (
                                                        <React.Fragment key={panelForm.id}>
                                                            <Flex
                                                                key={panelForm.id}
                                                                as={Link}
                                                                to={`/panelist/${props.match.params.account_uuid}/profile/${panelForm.id}`}
                                                                alignItems="flex-start"
                                                                className="text-pure"
                                                            >
                                                                <Box flexGrow={1}>
                                                                    <Box
                                                                        as="p"
                                                                        className="fs-accent-16"
                                                                        sx={{ whiteSpace: 'wrap' }}
                                                                        style={helpers.accountThemeLinkStyle(account)}
                                                                    >
                                                                        {panelForm.title}
                                                                    </Box>
                                                                    <StatusIndicator
                                                                        color={isCompleted ? 'green' : 'gray'}
                                                                        isFullyColored
                                                                    >
                                                                        {isCompleted ? 'Completed' : 'Not completed'}
                                                                    </StatusIndicator>
                                                                </Box>
                                                                <FiArrowRight
                                                                    style={helpers.accountThemeLinkStyle(account)}
                                                                    css={{
                                                                        margin: '0px',
                                                                        marginLeft: '16px',
                                                                        padding: '4px',
                                                                        fontSize: '24px'
                                                                    }}
                                                                />
                                                            </Flex>
                                                            {index !== account.panel_forms.length - 1 && (
                                                                <Box as="hr" my={3} />
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                                {account.panel_forms.length === 0 && (
                                                    <Box as="p" className="fs-body-16">
                                                        No panel forms
                                                    </Box>
                                                )}
                                            </Flex>
                                            <Box as="h2" mb="10px" className="fs-headline-20">
                                                My Studies
                                            </Box>
                                            <Box>
                                                {personData.study_participation_basics.length ? (
                                                    personData.study_participation_basics.map(spb => {
                                                        return (
                                                            <Box mb={'8px'} className="fs-body-16">
                                                                <Box sx={{ fontWeight: 500 }}>{spb.study_title}</Box>
                                                                {spb.spot ? (
                                                                    <Box>
                                                                        <Box mt={2}>
                                                                            Scheduled on{' '}
                                                                            {helpersStudy.displayStudyPersonSpotLocal(
                                                                                spb
                                                                            )}
                                                                        </Box>
                                                                        {helpers.allowSPReschedule(account, spb) ? (
                                                                            <Box>
                                                                                <a
                                                                                    href={helpers.getSchedulingLink_reschedule(
                                                                                        spb
                                                                                    )}
                                                                                    target="_blank"
                                                                                    style={helpers.accountThemeLinkStyle(
                                                                                        account
                                                                                    )}
                                                                                >
                                                                                    Reschedule
                                                                                </a>
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <a
                                                                                    href={helpers.getSchedulingLink_cancel(
                                                                                        spb
                                                                                    )}
                                                                                    target="_blank"
                                                                                    style={helpers.accountThemeLinkStyle(
                                                                                        account
                                                                                    )}
                                                                                >
                                                                                    Cancel
                                                                                </a>
                                                                            </Box>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </Box>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {checkShouldShowScreeners(account) &&
                                                                spb &&
                                                                spb.screener_uuids &&
                                                                spb.screener_uuids.length > 0 ? (
                                                                    <Box>
                                                                        {spb.screener_uuids.map(screener_uuid => {
                                                                            const study_person_temp = {
                                                                                uuid: spb.uuid
                                                                            };
                                                                            return (
                                                                                <Box>
                                                                                    <a
                                                                                        href={helpersScreener.getScreenerLinkNewForPerson(
                                                                                            screener_uuid,
                                                                                            account,
                                                                                            study_person_temp
                                                                                        )}
                                                                                        target="_blank"
                                                                                        style={helpers.accountThemeLinkStyle(
                                                                                            account
                                                                                        )}
                                                                                    >
                                                                                        {getScreenerTitleFromUuidPanelist(
                                                                                            spb,
                                                                                            screener_uuid
                                                                                        )}{' '}
                                                                                        <FiArrowRight />
                                                                                    </a>
                                                                                </Box>
                                                                            );
                                                                        })}
                                                                    </Box>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Box>
                                                        );
                                                    })
                                                ) : (
                                                    <Box as="p" className="fs-body-16">
                                                        No studies yet
                                                    </Box>
                                                )}
                                            </Box>
                                        </>
                                    )
                                ) : (
                                    'Not found'
                                )}
                            </Box>
                        </Box>
                    </NiceWrapper>
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            ) : (
                <Box mt={4}>Account not found.</Box>
            )}
        </div>
    );
}

export default PanelistProfile;
