import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button } from 'rebass';
import { Input, Select } from '@rebass/forms';
import services from 'services/services';
import { FiBookOpen, FiSend, FiTrash2 } from 'react-icons/fi';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';

import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';

function SettingsUsersPage(props) {
    const [userInvites, setUserInvite] = useState([]);
    const [accountUsers, setAccountUsers] = useState([]);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [isLoadingUserInvites, setIsLoadingUserInvites] = useState(true);
    const { addToast } = useToasts();

    useEffect(() => {
        getUserInvitesXHR();
        getAccountUsersXHR();
    }, []);

    function getUserInvitesXHR() {
        services.getUserInvites().then(user_invites => {
            setUserInvite(user_invites);
            setIsLoadingUserInvites(false);
        });
    }

    function getAccountUsersXHR() {
        services.getAccountUsers().then(accountUsers_new => {
            setAccountUsers(accountUsers_new);
            setIsLoadingUsers(false);
        });
    }

    function resendUserInviteXHR(user_invite_id) {
        services
            .resendUserInvite(user_invite_id)
            .then(() => {
                getUserInvitesXHR();
                addToast('Invite successfully re-sent', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function deleteUserInviteXHR(user_invite_id) {
        services
            .deleteUserInvite(user_invite_id)
            .then(() => {
                getUserInvitesXHR();
                addToast('Invite successfully revoked', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function deleteUserXHR(user_invite_id) {
        if (!confirm('Are you sure you want to remove this user from the organization?')) {
            return;
        }

        services
            .deleteUser(user_invite_id)
            .then(() => {
                getAccountUsersXHR();
                addToast('User successfully removed', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function renderUserInvites() {
        const render = [];
        userInvites.forEach(ui => {
            render.push(
                <ItemWrapper style={{ color: 'black' }}>
                    <Box width={1 / 3}>{ui.email}</Box>
                    <Box width={1 / 3}>{ui.role}</Box>
                    <Box width={1 / 3}>
                        <Button
                            type="button"
                            variant="secondary-gray"
                            mr={2}
                            onClick={() => resendUserInviteXHR(ui.id)}
                        >
                            <FiSend /> Resend
                        </Button>
                        <Button
                            type="button"
                            variant="secondary-gray"
                            className="secondary-icon"
                            onClick={() => {
                                if (!confirm('Are you sure?')) {
                                    return;
                                }
                                deleteUserInviteXHR(ui.id);
                            }}
                        >
                            <FiTrash2 />
                        </Button>
                    </Box>
                </ItemWrapper>
            );
        });
        return render;
    }

    function shouldDisable(accountUser) {
        let isDisabled = false;
        if (accountUser.user.id === props.auth.user.id) {
            isDisabled = true;
        } else {
            isDisabled = !isEnoughRole(accountUser.type);
        }

        return isDisabled;
    }

    function onRoleChange(user_id, role) {
        const accountUsers_copy = accountUsers;
        const accountUser_found = accountUsers_copy.find(au => au.user_id == user_id);
        if (accountUser_found) {
            const index = accountUsers_copy.indexOf(accountUser_found);
            if (index > -1) {
                accountUsers_copy[index].type = role;
                console.log(accountUser_found, accountUsers_copy);
                setAccountUsers(accountUsers_copy);

                services
                    .updateAccountUserRole(user_id, role)
                    .then(() => {
                        addToast('User role successfully updated', {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    })
                    .catch(error => {
                        const errorText = services.parseAndTrackXhrErrors(error);
                        addToast(errorText, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
            }
        }
    }

    function renderUsers() {
        const render = [];

        //console.log('render again');
        accountUsers.forEach(au => {
            render.push(
                <ItemWrapper style={{ color: 'black' }}>
                    <Box width={1 / 3}>
                        {au.user.id == props.auth.user.id ? <span style={{ color: '#aaa' }}>You&nbsp;&nbsp;</span> : ''}
                        {au.user.name}
                    </Box>
                    <Box width={1 / 3}>{au.user.email}</Box>
                    <Box width={1 / 3}>
                        <Flex>
                            {renderRoleSelection(au.user.id, au.type, shouldDisable(au))}

                            {shouldDisable(au) == false && (
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    className="secondary-icon"
                                    ml={2}
                                    onClick={() => deleteUserXHR(au.user.id)}
                                >
                                    <FiTrash2 />
                                </Button>
                            )}
                        </Flex>
                    </Box>
                </ItemWrapper>
            );
        });
        return render;
    }

    function renderRoleSelection(user_id, value, isDisabled) {
        // Admin: Can change billing, workspace settings, and view / edit projects.
        // User: Can view / edit projects and invite other users or viewers.
        // Viewer: Can view projects and invite other viewers, but cannot edit anything.
        console.log(user_id, value);
        return (
            <Select
                value={value}
                mr={2}
                width={160}
                disabled={isDisabled}
                onChange={e => onRoleChange(user_id, e.target.value)}
            >
                <option value="admin" disabled={!isEnoughRole('admin')}>
                    Admin
                </option>
                <option value="user" disabled={!isEnoughRole('user')}>
                    User
                </option>
                <option value="view" disabled={!isEnoughRole('view')}>
                    Viewer
                </option>
                {/*deprecated<option value="limited" disabled={!isEnoughRole('limited')}>
                    Limited
                </option>*/}
            </Select>
        );
    }

    function isEnoughRole(role_required) {
        let isEnough = false;
        const { type: current_role } = props.auth.account_role;
        if (current_role == 'admin') {
            isEnough = true;
        } else if (current_role == 'user') {
            if (role_required == 'admin') {
                isEnough = false;
            } else {
                isEnough = true;
            }
        } else if (current_role == 'view') {
            if (role_required != 'view') {
                isEnough = false;
            } else {
                isEnough = true;
            }
        } else if (current_role == 'limited') {
            if (role_required != 'limited') {
                isEnough = false;
            } else {
                isEnough = true;
            }
        }
        return isEnough;
    }

    function renderUserInviteRoleSelection() {
        // console.log(props.auth.account_role)
        return (
            <Select name="user_invite_role" mr={3} width={160}>
                <option value="admin" disabled={!isEnoughRole('admin')}>
                    Admin
                </option>
                <option value="user" disabled={!isEnoughRole('user')}>
                    User
                </option>
                <option value="view" disabled={!isEnoughRole('view')}>
                    Viewer
                </option>
                {/*deprecated<option value="limited" disabled={!isEnoughRole('limited')}>
                    Limited
                </option>*/}
            </Select>
        );
    }

    function onUserInviteSubmit(e) {
        e.preventDefault();

        const user_invite_email = document.getElementsByName('user_invite_email');
        const user_invite_role = document.getElementsByName('user_invite_role');
        services
            .createUserInvite(user_invite_email[0].value, user_invite_role[0].value)
            .then(() => {
                getUserInvitesXHR();
                user_invite_email[0].value = '';
                addToast('User successfully invited', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>User Management</title>
                </Helmet>
                <Flex>
                    <SettingsPageSubNav />
                </Flex>
                <AppPageWrapperSectionHeader className="experience" style={{ height: 'auto' }}>
                    <Box>
                        <H1>Users</H1>
                        <Box className="experience-description">
                            Manage organization users, and invite new users.
                            <br />
                            <a href="#" data-beacon-article="63055617549d66420b99a163">
                                <FiBookOpen /> Learn how user roles and permissions work
                            </a>
                        </Box>
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mx={4} my={4} width="100%">
                        <H2>User Invites</H2>
                        <form onSubmit={onUserInviteSubmit}>
                            <Flex>
                                <Box>
                                    <Input
                                        required
                                        type="email"
                                        name="user_invite_email"
                                        placeholder="Email"
                                        mr={2}
                                        width={240}
                                    />
                                </Box>
                                <Box>{renderUserInviteRoleSelection()}</Box>
                                <Box>
                                    <Button type="submit">
                                        <FiSend /> Send user invite
                                    </Button>
                                </Box>
                            </Flex>
                        </form>
                        <br />

                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <ItemWrapper className="header-row">
                                <Box width={1 / 3}>Email</Box>
                                <Box width={1 / 3}>Role</Box>
                                <Box width={1 / 3}>Actions</Box>
                            </ItemWrapper>
                            {isLoadingUserInvites ? (
                                <Box m={3} style={{ minHeight: '24px' }}>
                                    <LoadingWrapper>
                                        <LoadingIndicator />
                                    </LoadingWrapper>
                                </Box>
                            ) : (
                                <>
                                    {renderUserInvites()}
                                    <ItemWrapper style={{ border: 0 }}>
                                        {userInvites.length} result{userInvites.length == 1 ? '' : 's'}
                                    </ItemWrapper>
                                </>
                            )}
                        </ListWrapper>

                        <Box mt={4}>
                            <H2>Current Users</H2>
                        </Box>

                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <ItemWrapper className="header-row">
                                <Box width={1 / 3}>Name</Box>
                                <Box width={1 / 3}>Email</Box>
                                <Box width={1 / 3}>Role</Box>
                            </ItemWrapper>
                            {isLoadingUsers ? (
                                <Box m={3} style={{ minHeight: '24px' }}>
                                    <LoadingWrapper>
                                        <LoadingIndicator />
                                    </LoadingWrapper>
                                </Box>
                            ) : (
                                <>
                                    {renderUsers()}
                                    <ItemWrapper style={{ border: 0 }}>
                                        {accountUsers.length} result{accountUsers.length == 1 ? '' : 's'}
                                    </ItemWrapper>
                                </>
                            )}
                        </ListWrapper>

                        <br />
                        <br />
                    </Box>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default SettingsUsersPage;
