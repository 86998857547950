import React, { useState } from 'react';
import { FaDotCircle } from 'react-icons/fa';
import { BsCircle } from 'react-icons/bs';

import { Flex } from 'rebass';

const SchedulingPageCheckbox = props => {
    const [hovered, setHovered] = useState(false);

    if (props.selected) {
        return (
            <Flex>
                <FaDotCircle
                    style={{
                        color: '#6200FF',
                        height: '18px',
                        width: '18px',
                        margin: '2px 9px 0 0'
                    }}
                />
                <div className="fs-body-14" style={{ color: '#4003BD', fontWeight: '600' }}>
                    {props.children}
                </div>
            </Flex>
        );
    }

    return (
        <Flex
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => {
                setHovered(false);
                props.onSelect();
            }}
            style={{ cursor: 'pointer' }}
        >
            <BsCircle
                style={{
                    color: hovered ? '#6200FF' : '#D4D9DD',
                    background: hovered ? 'none' : '#FAFAFA',
                    borderRadius: '9px',
                    height: '18px',
                    width: '18px',
                    margin: '2px 9px 0 0'
                }}
            />
            <div className="fs-body-14" style={{ color: hovered ? '#6200FF' : '#001233' }}>
                {props.children}
            </div>
        </Flex>
    );
};

export default SchedulingPageCheckbox;
