import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button, Heading } from 'rebass';
import { Input, Label, Textarea, Select } from '@rebass/forms';
import studies_service from 'services/studies';
import people_service from 'services/people';
import services from 'services/services';
import helpers from 'utils/helpers';
import config from 'config';
import cloneDeep from 'lodash/cloneDeep';
import ReactModal from 'react-modal';
import { FiXCircle, FiEdit, FiFile, FiFileText, FiTrash2 } from 'react-icons/fi';
import moment from 'moment-timezone';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import { ReactFormBuilder } from 'components/ReactFormBuilder';
import ScreenerTranslationComponent from 'components/ScreenerTranslationComponent';
import { SCREENER_WHO_CAN_TAKE } from 'utils/constants';
import NiceModal from 'components/NiceModal';
import NiceSelect from 'components/NiceSelect';
import { ActionButton } from 'components/Button/ActionButton';
import CreatePanel from 'components/Participant/CreatePanel';
import PanelSelect from 'components/PanelSelect';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';
import useQuery from 'hooks/useQuery';
import cache from 'utils/cache';

let saveDelay;

function StudyScreenersList(props) {
    const studyId = props.match.params.id;
    const [screeners, setScreeners] = useState([]);
    const [allAccountScreeners, setAllAccountScreeners] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [panels, setPanels] = useState(null);
    const [panelIdSelected, setPanelIdSelected] = useState(null);
    const [showModalCreatePanel, setShowModalCreatePanel] = useState(false);
    const { addToast } = useToasts();
    const { data: study, isLoading: isStudyLoading } = useQuery({
        queryFn: ({ studyId }) => studies_service.getStudy(studyId),
        variables: {
            studyId: studyId
        },
        onSuccess: study => cache.set(`study.${studyId}`, study),
        defaultData: cache.get(`study.${studyId}`),
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    useEffect(() => {
        xhrGetStudyScreeners();
        xhrGetAllAccountScreeners();
        xhrGetPanels();
    }, []);

    function xhrGetStudyScreeners() {
        studies_service
            .getScreeners(props.match.params.id)
            .then(screeners => {
                setScreeners(screeners);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function xhrGetAllAccountScreeners() {
        studies_service
            .getAllAccountScreeners()
            .then(screeners => {
                screeners.sort(function(a, b) {
                    return b.screener_id - a.screener_id;
                });
                //console.log(screeners);
                setAllAccountScreeners(screeners);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function deleteScreenerXHR(screener) {
        console.log(screener);
        if (!confirm('Are you sure you want to delete this screener?')) {
            return;
        }

        studies_service
            .deleteScreener(screener.study_id, screener.id)
            .then(study => {
                xhrGetStudyScreeners();
                addToast('Successfully deleted', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function xhrGetPanels(selectId) {
        people_service
            .getPanels()
            .then(panels_response => {
                if (panels_response.length) {
                    setPanels(panels_response);
                    setPanelIdSelected(selectId ? selectId : panels_response[0].id);
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function onCreatePanel(panel) {
        addToast('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        xhrGetPanels(panel.id);
    }

    function saveFormXHR(e) {
        e.preventDefault();

        setIsSubmitting(true);

        const title = document.getElementById('new-form-screener__title').value;
        const description = document.getElementById('new-form-screener__description').value;
        const copyScreenerId = document.getElementById('new-form-copy-screener-id').value;

        studies_service
            .createScreener(props.match.params.id, title, description, copyScreenerId, panelIdSelected)
            .then(screener_response => {
                addToast('Created', {
                    appearance: 'success',
                    autoDismiss: true
                });

                props.history.push(`/studies/${props.match.params.id}/screeners/${screener_response.id}`);
            })
            .catch(error => {
                setIsSubmitting(false);
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function getCopyScreenerDropdownItems() {
        let items = [];
        items.push({
            id: 0,
            title: 'Do not copy'
        });
        allAccountScreeners.forEach(s => {
            let study_title = s.study_title;
            if (study_title.length > 20) {
                study_title = study_title.substr(0, 20) + '...';
            }
            items.push({
                id: s.screener_id,
                title: `[${study_title}] ${s.screener_title}`
            });
        });
        return items;
    }

    function getCopyScreenerDropdownValueMap() {
        let items = {};
        items[0] = 'Do not copy';

        allAccountScreeners.forEach(s => {
            items[s.screener_id] = `[${s.study_title}] ${s.screener_title}`;
        });
        return items;
    }

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Screeners</title>
                </Helmet>

                <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                    <Box>
                        <BreadcrumbBack to="/studies">Back to all studies</BreadcrumbBack>
                        <BreadcrumbTitle title={study && study.title} isLoading={isStudyLoading} />
                    </Box>
                </AppPageWrapperSectionHeader>
                <Flex>
                    <StudyPageSubNav study_id={props.match.params.id} study={study} />
                </Flex>
                <AppPageWrapperSectionHeader className="experience">
                    <Box mt={3}>
                        <H1>Study Screeners</H1>

                        <Box className="experience-description">
                            Create pre-screeners, phone-screeners, confirmations, and more.
                        </Box>
                    </Box>
                    <Box mt={3}>
                        <ActionButton title="New Screener" action={() => setShowModal(true)} keyLabel="N" mr={0} />
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box m={4} width="100%">
                        <Box className="text-primary medium fs-18">
                            {!isLoading && (
                                <>
                                    {screeners.length} {screeners.length == 1 ? 'screener' : 'screeners'} matched
                                </>
                            )}
                        </Box>

                        <ItemWrapper className="header-row">
                            <Box width={1 / 2}>Title & Description</Box>
                            <Box width={1 / 4}>Who can take the screener?</Box>
                            <Box width={1 / 8}>Created</Box>
                            <Box width={1 / 8} sx={{ textAlign: 'right' }}>
                                Actions
                            </Box>
                        </ItemWrapper>
                        {!isLoading && screeners && screeners.length == 0 && (
                            <Box mt={4} mx={3}>
                                No results, create your first screener!
                            </Box>
                        )}
                        {screeners.map(screener => {
                            return (
                                <Link to={`/studies/${props.match.params.id}/screeners/${screener.id}`}>
                                    <ItemWrapper key={screener.id}>
                                        <Box width={1 / 2}>
                                            <Link
                                                to={`/studies/${props.match.params.id}/screeners/${screener.id}`}
                                                className="muted"
                                            >
                                                {screener.title ? screener.title : '(no title)'}
                                            </Link>
                                            <Box
                                                style={{ width: '100%' }}
                                                mt={1}
                                                className="ellipsis text-secondary fs-12"
                                            >
                                                {screener.description}
                                            </Box>
                                        </Box>
                                        <Box width={1 / 4} className="text-primary">
                                            {SCREENER_WHO_CAN_TAKE[screener.participant_external]}
                                        </Box>

                                        <Box width={1 / 8} className="text-primary">
                                            {moment.utc(screener.created_at).format('MMMM D, YYYY')}
                                        </Box>
                                        <Box width={1 / 8} style={{ textAlign: 'right' }}>
                                            {/*<Link to={`/studies/${props.match.params.id}/screeners/${screener.id}`}><Button type='button' variant='secondary-gray' mr={2} ><FiEdit /> Edit</Button></Link>*/}
                                            <Button
                                                type="button"
                                                variant="secondary-gray"
                                                className="secondary-icon delete-screener"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    deleteScreenerXHR(screener);
                                                }}
                                            >
                                                <FiTrash2 />
                                            </Button>
                                        </Box>
                                    </ItemWrapper>
                                </Link>
                            );
                        })}
                    </Box>

                    <NiceModal
                        isOpen={showModal}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => setShowModal(false)}
                        style={{ content: { width: '510px' } }}
                        title="Create a new screener"
                        overflow="visible"
                    >
                        <form onSubmit={saveFormXHR}>
                            {panels !== null && panelIdSelected !== null && (
                                <Box mb={3}>
                                    <Label>Associated Panel</Label>
                                    <PanelSelect
                                        width="100%"
                                        items={panels}
                                        value={panelIdSelected}
                                        onChange={panel_id => setPanelIdSelected(panel_id)}
                                        onCreatePanel={() => setShowModalCreatePanel(true)}
                                    />
                                </Box>
                            )}

                            <Label htmlFor="title">Internal Title</Label>

                            <Input
                                id="new-form-screener__title"
                                type="text"
                                name="title"
                                placeholder="Online pre-screen"
                                autoComplete="off"
                                required
                                autoFocus
                            />

                            <Label mt={3} htmlFor="description">
                                Internal Description
                            </Label>

                            <Textarea
                                id="new-form-screener__description"
                                type="text"
                                name="description"
                                placeholder="Pre-screen for online shoppers"
                            />

                            <Label mt={3} htmlFor="description">
                                Copy Screener
                            </Label>
                            {/*<Select id="new-form-copy-screener-id">
                                <option value="0">No</option>
                                {allAccountScreeners.map(s => {
                                    return <option value={s.screener_id}>({s.study_title}) {s.screener_title}</option>
                                })}
                            </Select>*/}

                            <input type="hidden" id="new-form-copy-screener-id" />
                            <NiceSelect
                                showSearch
                                revealEllipsis={true}
                                mimicSelectStyle={true}
                                defaultValue={0}
                                //positionRight="0px"
                                items={getCopyScreenerDropdownItems()}
                                mapValueToLabel={getCopyScreenerDropdownValueMap()}
                                onChange={screener_id => {
                                    var s = document.getElementById('new-form-copy-screener-id');
                                    s.value = screener_id;
                                }}
                            />

                            <Box className="modal-actions">
                                <Button
                                    variant="secondary-gray"
                                    type="button"
                                    mr={3}
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    mr={0}
                                    type="submit"
                                    variant="primary"
                                    className="modal-primary"
                                    disabled={isSubmitting}
                                >
                                    Create screener
                                </Button>
                            </Box>
                        </form>
                        <NiceModal
                            isOpen={showModalCreatePanel}
                            shouldCloseOnOverlayClick
                            onRequestClose={() => setShowModalCreatePanel(false)}
                            title={`Create new panel`}
                        >
                            <CreatePanel onCreate={onCreatePanel} onClose={() => setShowModalCreatePanel(false)} />
                        </NiceModal>
                    </NiceModal>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default StudyScreenersList;
