/*
 * StudiesPage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import xhr from 'xhr.js';
import styled from 'styled-components';
import { Fade } from 'react-slideshow-image';
import { isMobile } from 'react-device-detect';
import helpers from 'utils/helpers';
import {
    MarketingHeader,
    MarketingFooter,
    MarketingPageSection,
    MarketingPageWrapper,
    MarketingHeadline
} from 'components/Marketing';

import H1 from 'components/H1';
import H2 from 'components/H2';

import { Flex, Box, Button, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';
import config from 'config';
import { FcGoogle } from 'react-icons/fc';
import NiceWrapper from 'components/NiceWrapper';
import auth from 'services/auth';

import LogoTremendous from 'images/external/logo-tremendous.png';
import LogoIntercom from 'images/external/logo-intercom.png';
import LogoGcal from 'images/external/logo-gcal.png';
import LogoAmazon from 'images/external/logo-amazon.png';
import SSAppHome from 'images/screenshots/app-home.png';
import SSAppSchedule from 'images/screenshots/app-schedule.png';
import SSAppPay from 'images/screenshots/app-pay.png';
import SSAppSettings from 'images/screenshots/app-settings.png';
import SSCompensate from 'images/features/compensate.png';
import SSRecruit from 'images/features/recruit.png';
import SSSchedule from 'images/features/schedule.png';
import SSScreen from 'images/screenshots/app-screener-builder.png';
import SSEmail from 'images/screenshots/app-email-bulk.png';
import SSKanban from 'images/features/kanban.png';
import SSNotes from 'images/features/notes.png';

import SSAppPanel from 'images/screenshots/app-panel.png';
import SSAppEmail1on1 from 'images/screenshots/app-email-one-on-one.png';
import SSAppScreener from 'images/screenshots/app-screener.png';

import {
    FiArrowUpRight,
    FiArrowRight,
    FiCheckCircle,
    FiCalendar,
    FiUsers,
    FiSend,
    FiFileText,
    FiDollarSign,
    FiCheck
} from 'react-icons/fi';

const Headline = styled.div`
    margin: 120px 0 0 0;
    font-size: 60px;
    font-weight: 600;
    color: #000;
`;

const Sepratator = styled.div`
    width: 1px;
    height: 16px;
    background: #d9d2e8;
    margin: 8px 12px 0;
    display: inline-block;
    vertical-align: top;
`;
const JTBDBubble = styled(Box)`
    border: 1px solid #ddd;
    padding: 12px 16px;
    border-radius: 4px;
    vertical-align: top;
    cursor: pointer;
    color: #333;
    font-weight: 500;
    background: #fbfafc;
    transition: background-color 250ms;
    position: relative;

    div {
        font-size: 13px;
        color: #777;
        margin-top: 8px;
        font-weight: 400;
    }
    &:hover,
    &.selected {
        background: #efe7fe;
        border-color: rgba(98, 0, 255, 0.5);
        svg {
            //color: rgb(98, 0, 255);
        }
    }

    svg {
        position: absolute;
        top: 16px;
        right: 16px;
        //color: rgb(98, 0, 255);
    }
`;

const slideImages = ['images/slide_2.jpg', 'images/slide_3.jpg', 'images/slide_4.jpg'];

const slideshowProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
    pauseOnHover: false
};

const JTBD_cards = {
    panel: {
        image: SSAppPanel,
        icon: <FiUsers />
    },
    screen: {
        image: SSScreen,
        icon: <FiFileText />
    },
    contact: {
        image: SSEmail,
        icon: <FiSend />
    },
    schedule: {
        image: SSAppSchedule,
        icon: <FiCalendar />
    },
    compensate: {
        image: SSAppPay,
        icon: <FiDollarSign />
    }
};

const CheckBullet = (
    <Box style={{ width: '32px', height: '32px', border: '1px solid rgb(98,0,255)', borderRadius: '4px' }}>
        <FiCheck />
    </Box>
);

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        const jtbd_default = 'panel';

        this.state = {
            jtbd_selected: jtbd_default,
            jtbd_image: JTBD_cards[jtbd_default].image
        };
        this.handleClickJTBD = this.handleClickJTBD.bind(this);
    }

    handleClickJTBD(card_title) {
        this.setState({
            jtbd_selected: card_title,
            jtbd_image: JTBD_cards[card_title].image
        });
    }

    render() {
        return (
            <Flex flexDirection="column" style={{ margin: '0px auto', width: '100%' }}>
                <MarketingHeader auth={this.props.auth} />
                <MarketingPageWrapper isMobile={isMobile}>
                    <Helmet>
                        <title>Panel management software for market research and user research</title>
                    </Helmet>

                    <MarketingPageSection style={{ textAlign: 'center' }}>
                        <Heading
                            as="h1"
                            fontSize={[5, 6, 6]}
                            color="black"
                            style={{ margin: isMobile ? '64px 0 0 0' : '160px 0 0 0' }}
                        >
                            Built to make user research
                            <br /> recruiting blissful
                        </Heading>
                        <Box style={{ margin: isMobile ? '32px 0 32px 0' : '32px 0 64px 0' }}>
                            Organize your panel, screen candidates, send bulk or 1:1 emails,{isMobile ? ' ' : <br />}
                            auto-schedule, and pay incentives.
                        </Box>
                        {helpers.renderDemoSignup()}
                        <br />
                        <br />
                        <br />
                        <br />
                    </MarketingPageSection>
                </MarketingPageWrapper>
                <MarketingPageWrapper isMobile={isMobile} fullwidth background="#f9f5f1">
                    <MarketingPageSection style={{ textAlign: 'center' }}>
                        <Heading
                            fontSize={[4, 4, 4]}
                            color="black"
                            style={{ margin: isMobile ? '64px 0 0 0' : '64px 0 16px 0' }}
                        >
                            How does it work?
                        </Heading>
                        <Box style={{ margin: isMobile ? '0 0 0 0' : '0 0 16px 0' }}>
                            Build your panel and fill studies for years to come.
                        </Box>
                    </MarketingPageSection>
                    <MarketingPageSection style={{ overflow: 'scroll', maxWidth: '100vw', marginTop: '24px' }}>
                        <div style={{ width: 'calc(960px)', margin: 'auto' }}>
                            <Flex sx={{ gridGap: 3 }}>
                                <JTBDBubble
                                    width={1 / 4}
                                    onClick={() => this.handleClickJTBD('panel')}
                                    className={this.state.jtbd_selected == 'panel' ? 'selected' : ''}
                                >
                                    Manage
                                    <div>Advanced filtering to find the right segment.</div>
                                    {JTBD_cards.panel.icon}
                                </JTBDBubble>
                                <JTBDBubble
                                    width={1 / 4}
                                    onClick={() => this.handleClickJTBD('contact')}
                                    className={this.state.jtbd_selected == 'contact' ? 'selected' : ''}
                                >
                                    Contact
                                    <div>Maintain a personal feel, while contacting many.</div>
                                    {JTBD_cards.contact.icon}
                                </JTBDBubble>
                                <JTBDBubble
                                    width={1 / 4}
                                    onClick={() => this.handleClickJTBD('screen')}
                                    className={this.state.jtbd_selected == 'screen' ? 'selected' : ''}
                                >
                                    Screen
                                    <div>Verify your study has the perfect participants.</div>
                                    {JTBD_cards.screen.icon}
                                </JTBDBubble>
                                <JTBDBubble
                                    width={1 / 4}
                                    onClick={() => this.handleClickJTBD('schedule')}
                                    className={this.state.jtbd_selected == 'schedule' ? 'selected' : ''}
                                >
                                    Schedule
                                    <div>Allow your participants to schedule, and reschedule themselves.</div>
                                    {JTBD_cards.schedule.icon}
                                </JTBDBubble>
                                <JTBDBubble
                                    width={1 / 4}
                                    onClick={() => this.handleClickJTBD('compensate')}
                                    className={this.state.jtbd_selected == 'compensate' ? 'selected' : ''}
                                >
                                    Thank
                                    <div>Incentives which allow the users options like gift cards or charity.</div>
                                    {JTBD_cards.compensate.icon}
                                </JTBDBubble>
                            </Flex>

                            <NiceWrapper
                                style={{ padding: 0, margin: '36px 0 36px 0', width: '100%', textAlign: 'left' }}
                            >
                                <div className="each-slide">
                                    <img src={this.state.jtbd_image} style={{ width: '100%' }} />
                                </div>
                            </NiceWrapper>
                        </div>
                    </MarketingPageSection>
                </MarketingPageWrapper>

                <MarketingPageWrapper isMobile={isMobile}>
                    <MarketingPageSection style={{ textAlign: 'left', margin: '32px 0' }}>
                        <Flex flexWrap="wrap" style={{ alignItems: 'center' }}>
                            <Box width={[1, 1 / 2]} px={[0, 4]} order={[2, 1]}>
                                {isMobile ? <br /> : ''}
                                <Box fontSize={1} mb={2}>
                                    PANEL MANAGEMENT
                                </Box>
                                <Heading fontSize={[5]} color="black">
                                    Build the research panel of your dreams
                                </Heading>
                                <br />
                                Create the world class in-house research panel you and your team will love to recruit
                                from. Easily set up the right panelist experience and connect with customers like never
                                before!
                            </Box>
                            <Box width={[1, 1 / 2]} px={[0, 4]} order={[1, 2]}>
                                <Box mt={4} fontSize={1} px={4}>
                                    <Flex mb={3}>
                                        <Box>
                                            <strong>Branded Panelist Experience</strong>
                                            <br />
                                            Create a unique panelist experience with your companies brand or you
                                            specialty panel branding. Create a program customers are dying to
                                            participate in.
                                        </Box>
                                    </Flex>
                                    <Flex mb={3}>
                                        <Box>
                                            <strong>Email Super Powers</strong>
                                            <br />
                                            Connect with customers 1:1 or en masse with the same personal feel. Treat
                                            your panel with a white glove experience that scales.
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <strong>Research Operations Center of Excellence</strong>
                                            <br />
                                            Keep track of all ongoing and past projects. Easily plan studies and create
                                            a consistent practice for your internal team to scale your research team's
                                            efficiency.
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </MarketingPageSection>
                </MarketingPageWrapper>
                <MarketingPageWrapper isMobile={isMobile} fullwidth background="#f9f5f1">
                    <MarketingPageSection style={{ textAlign: 'left', margin: '32px 0' }}>
                        <Flex flexWrap="wrap" style={{ alignItems: 'center' }}>
                            <Box width={[1, 1 / 2]} px={[0, 4]}>
                                <Box mt={4} fontSize={1} px={4}>
                                    <Flex mb={3}>
                                        <Box>
                                            <strong>Flexible Work Flows</strong>
                                            <br />
                                            Customize any study to fit your current process and enable every methodology
                                            in your research repertoire.
                                        </Box>
                                    </Flex>
                                    <Flex mb={3}>
                                        <Box>
                                            <strong>Automated Screening and Scheduling</strong>
                                            <br />
                                            Build automations in Panelfox to eliminate repetitive time consuming steps.
                                            Connect with your panelists at the right time to fill your study quota.
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <strong>Stay Connected with Your Panel</strong>
                                            <br />
                                            Easily know who is in your panel and find the right candidates for your next
                                            study.
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                            <Box width={[1, 1 / 2]} px={[0, 4]}>
                                {isMobile ? <br /> : ''}
                                <Box fontSize={1} mb={2}>
                                    AUTOMATION
                                </Box>
                                <Heading fontSize={[5]} color="black">
                                    Design your team's process and eliminate wasted time
                                </Heading>
                                <br />
                                With Panelfox customize your system to fit your team's process and build the future of
                                your research practice. Confidently democratize research operations to support the
                                growth of your research team.
                            </Box>
                        </Flex>
                    </MarketingPageSection>
                </MarketingPageWrapper>

                <MarketingPageWrapper isMobile={isMobile}>
                    <MarketingPageSection style={{ textAlign: 'left', margin: '32px 0' }}>
                        <Flex flexWrap="wrap" style={{ alignItems: 'center' }}>
                            <Box width={[1, 1 / 2]} px={[0, 4]} order={[2, 1]}>
                                {isMobile ? <br /> : ''}
                                <Box fontSize={1} mb={2}>
                                    SCALABLE PLATFORM
                                </Box>
                                <Heading fontSize={[5]} color="black">
                                    Grow your organization and build strong relationships
                                </Heading>
                                <br />
                                With the ability to connect with users and enable more research activities in your
                                organization you can grow your team and scale your world class research operation.
                            </Box>
                            <Box width={[1, 1 / 2]} px={[0, 4]} order={[1, 2]}>
                                <Box mt={4} fontSize={1} px={4}>
                                    <Flex mb={3}>
                                        <Box>
                                            <strong>Easily Track Everything</strong>
                                            <br />
                                            Gone are the days of candidates falling through the cracks, and having to
                                            worry about no-shows in an important study. Keep your research team and
                                            stakeholders on the same page.
                                        </Box>
                                    </Flex>
                                    <Flex mb={3}>
                                        <Box>
                                            <strong>Integrations</strong>
                                            <br />
                                            Connect with customers 1:1 or en masse with the same personal feel. Treat
                                            your panel with a white glove experience that scales.
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <strong>Make Research a Habit</strong>
                                            <br />
                                            With a system built to make research faster and easier to conduct, Panelfox
                                            takes away the pains involved in research and replaces it with a fun
                                            experience.
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                        <Box sx={{ textAlign: 'center' }} my={4}>
                            <br />
                            <br />
                            {helpers.renderDemoSignup()}
                        </Box>
                    </MarketingPageSection>
                </MarketingPageWrapper>
                <MarketingPageWrapper isMobile={isMobile} fullwidth background="#f9f5f1">
                    <MarketingPageSection style={{ textAlign: 'center' }}>
                        <MarketingFooter props={this.props} />
                    </MarketingPageSection>
                </MarketingPageWrapper>
            </Flex>
        );
    }
}

export default HomePage;
