import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

/**
 * Title component
 *
 * @param {Object} props props

 * @param {boolean} props.zeroPanelsAvailable zeroPanelsAvailable
 * @param {boolean} props.hasAdminPrivileges hasAdminPrivileges
 * @param {array} props.panels list of panels
 * @param {number} props.selectedId id of selected panel
 * @param {boolean} props.isDashboard isDashboard
 */
function PanelDropdownTitle({ zeroPanelsAvailable, hasAdminPrivileges, panels, selectedId, isDashboard }) {
    if (zeroPanelsAvailable) {
        return <>0 panels available</>;
    }

    const allPanelists = 'All panelists';
    const notFound = 'Panel not found';
    const dropdownIcon = (
        <FiChevronDown
            style={{
                margin: '4px 0 0 8px',
                strokeWidth: 1.5
            }}
        />
    );

    if (selectedId) {
        const panel = panels.find(panel => panel.id === selectedId);
        if (panel) {
            return (
                <>
                    {panel.title} {isDashboard ? 'Dashboard' : ''}
                    {dropdownIcon}
                </>
            );
        } else {
            return (
                <>
                    {notFound}
                    {dropdownIcon}
                </>
            );
        }
    } else if (hasAdminPrivileges) {
        return (
            <>
                {allPanelists}
                {dropdownIcon}
            </>
        );
    } else {
        return <span style={{ visibility: 'hidden' }}>.</span>;
    }
}

export default PanelDropdownTitle;
