import React, { useState } from 'react';
import { FiLayers } from 'react-icons/fi';
import { Button } from 'rebass';
import { useToasts } from 'react-toast-notifications';

import SelectModal from 'components/SelectModal';
import useQuery from 'hooks/useQuery';
import studyService from 'services/studies';
import services from 'services/services';
import useMutation from 'hooks/useMutation';
import ToastSuccess from 'containers/ParticipantPage/components/ToastSuccess';

/**
 * The button for adding people to a study
 *
 * @param {Obejct} props
 * @param {Function?} props.onOpen A callback for when the modal opens
 * @param {Function?} props.onClose A callback for when the modal closes
 * @param {any[]} props.participantIds The ids of the participants to add to the study
 */
export function AddToStudyButton({ onOpen, onClose, participantIds }) {
    const { addToast } = useToasts();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { data: studies, isLoading: isStudiesLoading } = useQuery({
        queryFn: () => studyService.getStudiesSimple(),
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { mutate: addToStudy, isLoading: isAddToStudyLoading } = useMutation({
        mutationFn: async ({ studyId }) => {
            await services.addPeopleToStudy(studyId, {
                people_ids: participantIds.map(participantId => ({
                    id: participantId
                })),
                all: 0
            });

            // add people to study doesn't return anything important so we just return the studyId
            return studyId;
        },
        onSuccess: studyId => {
            addToast(<ToastSuccess studyId={studyId} />, {
                appearance: 'success',
                big: true
            });

            onModalClose();
        },
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        }
    });
    const { mutate: createStudy, isLoading: isCreateStudyLoading } = useMutation({
        mutationFn: async ({ title }) =>
            services.createStudyAddPeople({
                title,
                people_ids: participantIds.map(participantId => ({
                    id: participantId
                })),
                all: 0
            }),
        onSuccess: study => {
            addToast(<ToastSuccess studyId={study.id} studyName={study.title} />, {
                appearance: 'success',
                big: true
            });

            onModalClose();
        },
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        }
    });

    /**
     * Opens the modal
     */
    const onModalOpen = () => {
        setIsModalOpen(true);
        onOpen && onOpen();
    };

    /**
     * Closes the modal
     */
    const onModalClose = () => {
        setIsModalOpen(false);
        onClose && onClose();
    };

    return (
        <>
            <Button variant="secondary-gray" onClick={onModalOpen}>
                <FiLayers /> Add to study
            </Button>
            {studies && (
                <SelectModal
                    type="study"
                    icon={<FiLayers />}
                    items={studies}
                    isOpen={isModalOpen}
                    shouldCloseOnOverlayClick={false}
                    isLoading={isCreateStudyLoading || isAddToStudyLoading}
                    onRequestClose={onModalClose}
                    onSelect={studyId => addToStudy({ studyId })}
                    onCreate={title => createStudy({ title })}
                />
            )}
        </>
    );
}

export default AddToStudyButton;
