import React, { useState, useEffect, useCallback } from 'react';
import H1 from 'components/H1';
import { Flex, Box, Button, Heading } from 'rebass';
import { Label } from '@rebass/forms';
import services from 'services/services';
import service_people from 'services/people';
import xhr from 'xhr.js';
import auth from 'services/auth';
import helpers from 'utils/helpers';
import { useToasts } from 'react-toast-notifications';
import { FiCheckCircle, FiPlus, FiXCircle } from 'react-icons/fi';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import NiceDropdown from 'components/NiceDropdown';
import ReactModal from 'react-modal';
import { CreatePersonDataKey } from 'components/PersonDataKey';
import ReactS3Uploader from 'react-s3-uploader';
import { PANELIST_TYPE_OPTED_IN, PANELIST_TYPE_PURCHASED_LIST, PANELIST_TYPE_CLIENT_LIST } from 'utils/constants';
import NiceModal from 'components/NiceModal';
import CreatePanel from 'components/Participant/CreatePanel';
import PanelSelect from 'components/PanelSelect';

export default function UploadParticipants({
    study_id,
    onClose,
    onDone,
    onUpdatePanels,
    panels,
    defaultPanelIdSelected
}) {
    const [availableColumns, setAvailableColumns] = useState([]);
    const [fileColumns, setFileColumns] = useState([]);
    const [filePreviewRows, setFilePreviewRows] = useState([]);
    const [uploadProgressNum, setUploadProgressNum] = useState([]);
    const [showDisplayedCustomColumn, setShowDisplayedCustomColumn] = useState(false);
    const [doNotImportUnmatched, setDoNotImportUnmatched] = useState(false);
    const [doNotOverwriteData, setDoNotOverwriteData] = useState(false);
    const [matchHeaderTitlesToColumnIds, setMatchHeaderTitlesToColumnIds] = useState({});
    const [bulkImportStep, setBulkImportStep] = useState(1);
    const [bulkImportProcessing, setBulkImportProcessing] = useState(false);
    const [showModalAddCustomColumn, setShowModalAddCustomColumn] = useState(false);
    const [addCustomColumnForFileHeader, setAddCustomColumnForFileHeader] = useState(false);
    const [uploadPanelistType, setUploadPanelistType] = useState(null);
    const [panelIdSelected, setPanelIdSelected] = useState(null);
    const [showModalCreatePanel, setShowModalCreatePanel] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const account_id = auth.getAccountId();
    const { addToast } = useToasts();

    useEffect(() => {
        getAvailableColumnsXHR();
    }, []);

    useEffect(() => {
        if (defaultPanelIdSelected) {
            setPanelIdSelected(defaultPanelIdSelected);
        } else if (panels && panels.length) {
            setPanelIdSelected(panels[0].id);
        }
    }, [defaultPanelIdSelected, panels]);

    function getAvailableColumnsXHR() {
        services
            .getCustomDataColumnsXHR()
            .then(columns => {
                setAvailableColumns(columns);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
            });
    }

    function getPanelsXHR(selectId) {
        service_people
            .getPanels()
            .then(panels_response => {
                onUpdatePanels(panels_response);
                if (selectId) {
                    setPanelIdSelected(selectId);
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function onCreatePanel(panel) {
        addToast('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        getPanelsXHR(panel.id);
    }

    function guessSimpleColumnMapping(column_titles) {
        const match = {};
        column_titles.forEach(fileColumn => {
            // check for email, firstname, lastname, phone, etc
            console.log(fileColumn);
            const matched_column = availableColumns.find(ac => helpers.looseCompareStrings(ac.title, fileColumn));
            if (matched_column) {
                match[fileColumn] = matched_column;
            }
        });

        console.log('guessSimpleColumnMapping', column_titles, match);

        setMatchHeaderTitlesToColumnIds(match);
    }

    function updateHeaderTitlesToColumnIds(fileHeaderTitle, columnId) {
        const match_new = matchHeaderTitlesToColumnIds;

        if (columnId) {
            const col_found = availableColumns.find(ac => ac.id == columnId);
            if (col_found) {
                match_new[fileHeaderTitle] = col_found;
            }
        } else {
            if (match_new.hasOwnProperty(fileHeaderTitle)) {
                console.log('found prop, deleting');
                delete match_new[fileHeaderTitle];
            }
        }

        console.log('updateHeaderTitlesToColumnIds', match_new);

        setMatchHeaderTitlesToColumnIds(match_new);

        forceUpdate();
    }

    function getSignedUrl(file, callback) {
        if (file) {
            //console.log(file);
            if (['text/csv', 'application/vnd.ms-excel'].includes(file.type)) {
                //console.log('file okl')
                services.getAwsSignedUrl(file.name, file.type).then(response => {
                    //console.log(response);
                    callback(response);
                });
            } else {
                addToast('The file must be a CSV. We detected: ' + file.type, {
                    appearance: 'warning',
                    autoDismiss: false
                });
                setBulkImportProcessing(false);
            }
        } else {
            addToast('File not found', {
                appearance: 'error',
                autoDismiss: false
            });
            setBulkImportProcessing(false);
        }
    }

    function renderBulkImport(step) {
        const fileColumnsRender = [];
        const existingColumns = [];

        switch (step) {
            case 1:
                return (
                    <form /* onSubmit={importSubmit} */>
                        <Box mb={4}>
                            <Box className="form-label">Choose panel to upload</Box>
                            <PanelSelect
                                width="290px"
                                height="164px"
                                variant="gray"
                                items={panels}
                                value={panelIdSelected}
                                onChange={panel_id => setPanelIdSelected(panel_id)}
                                onCreatePanel={() => setShowModalCreatePanel(true)}
                            />
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <div>
                                {/*<Heading fontSize={1} sx={{marginBottom: '32px', fontWeight: 400}}>Import panelists with a CSV file</Heading>*/}
                                <ReactS3Uploader
                                    style={{
                                        height: 'auto',
                                        border: '2px solid rgb(98,0,255)',
                                        borderRadius: '4px',
                                        padding: '16px',
                                        background: 'rgba(98,0,255, .2)',
                                        cursor: 'pointer'
                                    }}
                                    getSignedUrl={getSignedUrl}
                                    accept=".csv"
                                    // autoUpload={true}

                                    onProgress={onUploadProgress}
                                    onError={onUploadError}
                                    onFinish={onUploadFinish}
                                    uploadRequestHeaders={{}}
                                    contentDisposition="auto"
                                    disabled={bulkImportProcessing}
                                />

                                {bulkImportProcessing ? (
                                    <Box mt={4} mb={3} fontWeight={600}>
                                        Uploading... {uploadProgressNum}%
                                    </Box>
                                ) : (
                                    ''
                                )}

                                <Box htmlFor="title" mt={4} mb={2} fontSize={1} fontWeight={500}>
                                    Only .csv files are allowed.
                                    <br />
                                    Please ensure the 1st row of the .csv file are column titles.
                                </Box>
                            </div>

                            {/* <Input
                                type="file"
                                id="file-bulk-import"
                                accept=".csv"
                                onChange={importSubmit}
                                
                            /> */}
                        </Box>
                        <Box className="modal-actions modal-actions-sticky">
                            <Button type="button" mr={0} variant="secondary-gray" onClick={onClose}>
                                Cancel
                            </Button>
                        </Box>
                    </form>
                );
            case 2:
                fileColumnsRender.push(
                    <ItemWrapper className="header-row-dark" fontSize={1} key="import-map-headers">
                        <Box width={3 / 10}>Column in Uploaded CSV</Box>
                        <Box width={3 / 10}>Preview</Box>
                        <Box width={4 / 10}>Column in Panelfox</Box>
                    </ItemWrapper>
                );

                existingColumns.push(<option value="">Select Panelfox Panelist Property</option>);
                availableColumns.forEach(col => {
                    if (helpers.isEditableAttribute(col.title)) {
                        existingColumns.push(<option value={col.id}>{col.title}</option>);
                    }
                });

                fileColumns.forEach((fileColumnTitle, key) => {
                    const previews = [];
                    if (filePreviewRows[key] && filePreviewRows[key].length) {
                        filePreviewRows[key].forEach(p => {
                            previews.push(<div key={p}>{p}</div>);
                        });
                    } else {
                        previews.push(filePreviewRows[key]);
                    }
                    fileColumnsRender.push(
                        <ItemWrapper fontSize={1} key={fileColumnTitle} style={{ alignItems: 'center' }}>
                            <Box width={3 / 10} style={{ fontWeight: 500 }}>
                                {fileColumnTitle || (
                                    <em style={{ color: 'red', fontWeight: 400 }}>empty file header</em>
                                )}
                            </Box>
                            <Box width={3 / 10} style={{ color: '#777', fontSize: '13px' }}>
                                {previews}
                            </Box>
                            <Box width={4 / 10} style={{ position: 'relative' }}>
                                {matchHeaderTitlesToColumnIds[fileColumnTitle] &&
                                matchHeaderTitlesToColumnIds[fileColumnTitle].id ? (
                                    <FiCheckCircle style={{ color: 'green', marginRight: '8px' }} />
                                ) : (
                                    <FiXCircle
                                        style={{ color: doNotImportUnmatched ? 'black' : 'red', marginRight: '8px' }}
                                    />
                                )}

                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    onClick={() => setShowDisplayedCustomColumn(fileColumnTitle)}
                                    className={
                                        (!matchHeaderTitlesToColumnIds[fileColumnTitle] ||
                                            !matchHeaderTitlesToColumnIds[fileColumnTitle].id) &&
                                        !doNotImportUnmatched &&
                                        'error-button'
                                    }
                                >
                                    {matchHeaderTitlesToColumnIds[fileColumnTitle] &&
                                    matchHeaderTitlesToColumnIds[fileColumnTitle].id ? (
                                        matchHeaderTitlesToColumnIds[fileColumnTitle].title
                                    ) : (
                                        <span>Select Column</span>
                                    )}
                                    <span className="arrow-down-dark" style={{ marginLeft: '4px' }} />
                                </Button>
                                {showDisplayedCustomColumn && showDisplayedCustomColumn == fileColumnTitle && (
                                    <NiceDropdown
                                        showSearch
                                        // positionRight="0px"
                                        onClose={() => {
                                            //console.log('CLOSING dropdown');
                                            setShowDisplayedCustomColumn(false);
                                        }}
                                        items={availableColumns}
                                        renderBottomStickyButton={
                                            <Button
                                                type="button"
                                                variant="primary-transparent"
                                                onClick={() => {
                                                    console.log('clicked create in dropdown');
                                                    setAddCustomColumnForFileHeader(fileColumnTitle);
                                                    setShowModalAddCustomColumn(true);
                                                }}
                                            >
                                                <FiPlus /> Create Panelist Property
                                            </Button>
                                        }
                                        onChange={columnId => {
                                            updateHeaderTitlesToColumnIds(showDisplayedCustomColumn, columnId);
                                        }}
                                    />
                                )}
                                {matchHeaderTitlesToColumnIds[fileColumnTitle] &&
                                    matchHeaderTitlesToColumnIds[fileColumnTitle].id && (
                                        <Button
                                            type="button"
                                            variant="transparent"
                                            className="light"
                                            ml={2}
                                            onClick={() => {
                                                updateHeaderTitlesToColumnIds(fileColumnTitle, null);
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    )}
                            </Box>
                        </ItemWrapper>
                    );
                });

                return (
                    <form onSubmit={bulkColumnsSelected}>
                        <Box className="modal-sticky-top">
                            <Flex>
                                <Box className="form-label" mr={2}>
                                    Target panel:
                                </Box>
                                <Box style={{ lineHeight: 0 }}>
                                    <select
                                        value={panelIdSelected}
                                        onChange={e => setPanelIdSelected(parseInt(e.target.value))}
                                    >
                                        {panels.map(p => {
                                            return <option value={p.id}>{p.title}</option>;
                                        })}
                                    </select>
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
                            {/*<Heading fontSize={2} sx={{marginBottom: '24px', fontWeight: 600}}>Assign column headers</Heading>*/}
                            <ListWrapper
                                style={{
                                    position: 'relative',
                                    border: '1px solid #eee',
                                    borderRadius: '4px'
                                }}
                            >
                                {fileColumnsRender}
                            </ListWrapper>
                        </Box>
                        <Box className="modal-actions modal-actions-sticky" sx={{ textAlign: 'right' }}>
                            <Box mb={1} style={{ fontWeight: 500 }}>
                                <Label style={{ display: 'block' }}>
                                    {fileColumns.length > Object.keys(matchHeaderTitlesToColumnIds).length ? (
                                        <span style={{ color: doNotImportUnmatched ? 'black' : 'red' }}>
                                            (You have{' '}
                                            {fileColumns.length - Object.keys(matchHeaderTitlesToColumnIds).length}{' '}
                                            unmatched columns)&nbsp;&nbsp;
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                    Skip unmatched columns?&nbsp;&nbsp;
                                    <input
                                        type="checkbox"
                                        name="do_not_import_unmatched"
                                        style={{ marginTop: '4px' }}
                                        onChange={() =>
                                            setDoNotImportUnmatched(
                                                document.getElementsByName(`do_not_import_unmatched`)[0].checked
                                            )
                                        }
                                    />{' '}
                                    Yes
                                </Label>
                            </Box>
                            <Box mb={1} style={{ fontWeight: 500 }}>
                                <Label style={{ display: 'block' }}>
                                    Do not overwrite panel property if CSV data is blank&nbsp;&nbsp;
                                    <input
                                        type="checkbox"
                                        name="do_not_overwrite_data"
                                        style={{ marginTop: '4px' }}
                                        onChange={() =>
                                            setDoNotOverwriteData(
                                                document.getElementsByName(`do_not_overwrite_data`)[0].checked
                                            )
                                        }
                                    />{' '}
                                    Yes
                                </Label>
                            </Box>
                            <Box mb={3} fontSize={1}>
                                <Flex sx={{ justifyContent: 'end' }}>
                                    <Box>
                                        <Label>
                                            {uploadPanelistType ? (
                                                <></>
                                            ) : (
                                                <span style={{ color: 'red' }}>(Required)&nbsp;&nbsp;</span>
                                            )}
                                            Source of upload&nbsp;&nbsp;
                                        </Label>
                                    </Box>
                                    <Box>
                                        <select
                                            required
                                            id="panelist_type"
                                            value={uploadPanelistType}
                                            onChange={e => {
                                                setUploadPanelistType(e.target.value);
                                            }}
                                        >
                                            <option value="">Please select...</option>
                                            <option value={PANELIST_TYPE_OPTED_IN}>Opted-in panelists</option>
                                            <option value={PANELIST_TYPE_CLIENT_LIST}>Client list</option>
                                            <option value={PANELIST_TYPE_PURCHASED_LIST}>Purchased list</option>
                                        </select>
                                    </Box>
                                </Flex>
                                {uploadPanelistType == PANELIST_TYPE_CLIENT_LIST && (
                                    <Box mt={1}>
                                        <div className="inline-block rounded bg-warning">
                                            Please scrub emails through a bounce-checker first, such as{' '}
                                            <a href="https://emailable.com" target="_blank">
                                                https://emailable.com
                                            </a>
                                            .
                                        </div>
                                    </Box>
                                )}
                                {uploadPanelistType == PANELIST_TYPE_PURCHASED_LIST && (
                                    <Box mt={1}>
                                        <div className="inline-block rounded bg-warning">
                                            If you are not set up to send emails to a Purchased List through Panelfox,
                                            please reach out to{' '}
                                            <a href="mailto:support@panelfox.io">support@panelfox.io</a>.
                                        </div>
                                    </Box>
                                )}
                            </Box>
                            <Box>
                                <Button type="button" variant="secondary-gray" mr={3} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    className="modal-primary"
                                    mr={0}
                                    type="button" /* this is on purpose so that clicking "enter" doesnt do anything */
                                    disabled={
                                        bulkImportProcessing ||
                                        (fileColumns.length > Object.keys(matchHeaderTitlesToColumnIds).length &&
                                            !doNotImportUnmatched) ||
                                        !uploadPanelistType
                                    }
                                    onClick={bulkColumnsSelected}
                                >
                                    {bulkImportProcessing ? 'Processing...' : 'Import Panelists'}
                                </Button>
                            </Box>
                        </Box>
                    </form>
                );
            default:
                return null;
        }
    }

    function onUploadProgress(progress) {
        setBulkImportProcessing(true);
        setUploadProgressNum(progress);
    }

    function onUploadFinish(signedUrlAndFilename) {
        const { filename } = signedUrlAndFilename;

        xhr.post(`/people-bulk-columns?account_id=${account_id}`, { filename }, { withCredentials: true })
            .then(response => {
                setBulkImportProcessing(false);

                addToast('CSV uploaded', {
                    appearance: 'success',
                    autoDismiss: true
                });

                const { column_titles, row_previews } = response.data;

                setBulkImportStep(2);
                setFileColumns(column_titles);
                setFilePreviewRows(row_previews);
                setBulkImportProcessing(false);
                guessSimpleColumnMapping(column_titles);

                // window.location.reload();
            })
            .catch(error => {
                setBulkImportProcessing(false);
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    function onUploadError(e) {
        helpers.trackError(e);
        addToast('There was an error uploading. Please try again.', {
            appearance: 'error',
            autoDismiss: false
        });
    }

    /* function importSubmit(e) {
        e.preventDefault();

        let postPromise;

        const formData = new FormData();
        const file = document.querySelector('#file-bulk-import');
        formData.append('csv_file', file.files[0]);

        setBulkImportProcessing(true);

        // if file uploaded
        if (file.files.length && file.files[0]) {
            postPromise = xhr.post(`/people-bulk-columns?account_id=${account_id}`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } else {
            addToast('Either upload a file or paste the content.', {
                appearance: 'error',
                autoDismiss: true
            });
        }
    } */

    function bulkColumnsSelected(e) {
        e.preventDefault();

        const data = {
            columnMapping: {},
            do_not_import_unmatched: doNotImportUnmatched,
            do_not_overwrite_data: doNotOverwriteData,
            panelist_type: uploadPanelistType,
            panel_id: panelIdSelected
        };

        Object.keys(matchHeaderTitlesToColumnIds).forEach(fileHeaderTitle => {
            const column = matchHeaderTitlesToColumnIds[fileHeaderTitle];
            data.columnMapping[column.id] = fileHeaderTitle;
        });

        setBulkImportProcessing(true);

        xhr.post(`/people-bulk?account_id=${account_id}&study_id=${study_id || ''}`, data, {
            withCredentials: true
        })
            .then(() => {
                setBulkImportProcessing(false);
                setUploadPanelistType('');
                setDoNotImportUnmatched(false);
                setDoNotOverwriteData(false);
                onDone();

                addToast(
                    'The import has started processing. You will receive a confirmation email when its complete. Refresh this page to see updates.',
                    {
                        appearance: 'success',
                        autoDismiss: true
                    }
                );
            })
            .catch(error => {
                setBulkImportProcessing(false);
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    function handleAddColumnSuccess(newCustomDataKey) {
        console.log('handling handleAddColumnSuccess');
        setShowModalAddCustomColumn(false);

        // update existing "available columns" array w/o going to XHR
        const availableColumns_new = availableColumns;
        availableColumns_new.push(newCustomDataKey);
        setAvailableColumns(availableColumns_new);

        //console.log('doing.. updateHeaderTitlesToColumnIds(addCustomColumnForFileHeader, newCustomDataKey.id)');
        updateHeaderTitlesToColumnIds(addCustomColumnForFileHeader, newCustomDataKey.id);

        // for some reason the dropdown was not updating so.. lets try this
        forceUpdate();
    }

    return (
        <Box>
            {renderBulkImport(bulkImportStep)}
            <NiceModal
                isOpen={showModalAddCustomColumn}
                onRequestClose={() => setShowModalAddCustomColumn(false)}
                title={`Add a panelist property`}
            >
                <CreatePersonDataKey
                    placeholder={addCustomColumnForFileHeader}
                    onSuccess={handleAddColumnSuccess}
                    onClose={() => setShowModalAddCustomColumn(false)}
                />
            </NiceModal>
            <NiceModal
                isOpen={showModalCreatePanel}
                shouldCloseOnOverlayClick
                onRequestClose={() => setShowModalCreatePanel(false)}
                title={`Create new panel`}
            >
                <CreatePanel onCreate={onCreatePanel} onClose={() => setShowModalCreatePanel(false)} />
            </NiceModal>
        </Box>
    );
}
