import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NiceWrapper from 'components/NiceWrapper';
import { Flex, Box, Heading, Button } from 'rebass';
import { Select, Input } from '@rebass/forms';
import styled from 'styled-components';
import { FiCheckCircle } from 'react-icons/fi';

export default function ConsentManagementPage(props) {
    const [numUsers, setNumUsers] = useState(props.numUsers ? props.numUsers : 1);
    const [selectedPlanId, setSelectedPlanId] = useState(props.selectedPlanId ? props.selectedPlanId : false);

    function changePlan(newPlanId) {
        setSelectedPlanId(newPlanId);
        if (props.onPlanSelectedChange) {
            props.onPlanSelectedChange(newPlanId);
        }
    }

    function onChangeUsers(e) {
        const val = e.target.value;
        setNumUsers(val);
        if (props.onNumUsersChange) {
            props.onNumUsersChange(val);
        }
    }

    function renderPricing(plan) {
        const renderFeature = (hasFeature, text) => {
            if (hasFeature) {
                return (
                    <>
                        <FiCheckCircle /> {text}
                    </>
                );
            }
            return (
                <>
                    <FiCheckCircle style={{ color: 'transparent' }} /> <span style={{ color: ' #999' }}>{text}</span>
                </>
            );
        };

        /*
            PLANS
            1 = FREE
            2 = BUSINESS
            3 = ENTERPRISE
        */

        const numPanelists = plan == 1 ? '1,000' : plan == 2 ? '50,000' : '>50,000';

        return (
            <FeaturesBox>
                {renderFeature([1, 2, 3].includes(plan), `${numPanelists} Panelists`)}
                <br />
                {renderFeature([1, 2, 3].includes(plan), 'Screeners & Automation')}
                <br />
                {renderFeature([1, 2, 3].includes(plan), 'Scheduling & Automation')}
                <br />
                {renderFeature([1, 2, 3].includes(plan), 'Bulk & 1:1 Emailing')}
                <br />
                {renderFeature([2, 3].includes(plan), 'Zoom, Calendly Integrations')}
                <br />
                {renderFeature([2, 3].includes(plan), 'Incentive Payments')}
                <br />
                {renderFeature([2, 3].includes(plan), 'Custom Branding')}
                <br />
                {renderFeature([3].includes(plan), 'Concierge Onboarding')}
                <br />
                {renderFeature([3].includes(plan), 'SSO & RBAC')}
                <br />
                {renderFeature([3].includes(plan), 'Security & Compliance')}
            </FeaturesBox>
        );
    }

    const FeaturesBox = styled.div`
        text-align: left;
        font-size: 15px;
        line-height: 28px;
        padding: 0 16px;
        svg {
            color: blue;
            vertical-align: top;
            margin: 7px 4px 0 0;
        }
    `;

    const currentPlanDiv = (
        <div
            style={{
                display: 'inline-block',
                verticalAlign: 'top',
                margin: '0 0 0 8px',
                background: 'rgba(98,0,255,1)',
                borderRadius: '4px',
                padding: '4px 8px',
                color: '#fff',
                fontSize: '14px',
                fontWeight: '500'
            }}
        >
            Current Plan
        </div>
    );

    return (
        <Flex flexWrap="wrap" mx={-3} mt={4} style={{ textAlign: 'center' }}>
            <Box px={3} width={1 / 3}>
                <NiceWrapper
                    style={{ background: '#fff', padding: '32px', position: 'relative', cursor: 'pointer' }}
                    className={selectedPlanId == 1 && 'purple'}
                    onClick={() => changePlan(1)}
                >
                    <Heading style={{ color: '#000', fontWeight: '600', margin: 0, fontSize: '20px' }}>
                        Starter {props.currentPlanId == 1 && currentPlanDiv}
                    </Heading>
                    <Box mt={1} style={{ fontSize: '14px' }}>
                        Get started with research
                        <br /> ops, for free.
                    </Box>

                    <div
                        style={{
                            fontSize: '36px',
                            fontWeight: '500',
                            color: '#000',
                            marginTop: '24px',
                            lineHeight: '22px'
                        }}
                    >
                        $0
                    </div>
                    <div style={{ margin: '8px 0 4px' }}>
                        <small>with 2 users included</small>
                    </div>

                    {!props.billing && (
                        <Link to="/signup">
                            <Button variant="tertiary" mr={0} mt={2}>
                                Sign Up
                            </Button>
                        </Link>
                    )}
                </NiceWrapper>
                <br />
                {renderPricing(1)}
            </Box>
            <Box px={3} width={1 / 3}>
                <NiceWrapper
                    style={{ background: '#fff', padding: '32px', position: 'relative', cursor: 'pointer' }}
                    className={selectedPlanId == 2 && 'purple'}
                    onClick={() => changePlan(2)}
                >
                    <Heading style={{ color: '#000', fontWeight: '600', margin: 0, fontSize: '20px' }}>
                        Premier {props.currentPlanId == 2 && currentPlanDiv}
                    </Heading>
                    <Box mt={1} style={{ fontSize: '14px' }}>
                        Advanced features for world-class research ops.
                    </Box>

                    <div
                        style={{
                            fontSize: '36px',
                            fontWeight: '500',
                            color: '#000',
                            marginTop: '24px',
                            lineHeight: '22px'
                        }}
                    >
                        ${99 * numUsers}
                    </div>
                    <div style={{ margin: '8px 0 0px' }}>
                        <small>
                            per month for{' '}
                            <Input
                                type="number"
                                value={numUsers}
                                onChange={onChangeUsers}
                                mx={1}
                                sx={{
                                    width: '32px',
                                    height: '28px',
                                    padding: '2px 4px',
                                    display: 'inline-block !important',
                                    textAlign: 'center'
                                }}
                            />{' '}
                            user{numUsers == 1 ? '' : 's'}{' '}
                        </small>
                    </div>

                    {!props.billing && (
                        <Link to="/signup">
                            <Button variant="primary" mr={0} mt={2}>
                                Sign Up
                            </Button>
                        </Link>
                    )}
                </NiceWrapper>
                <br />
                {renderPricing(2)}
            </Box>
            <Box px={3} width={1 / 3}>
                <NiceWrapper
                    style={{ background: '#fff', padding: '32px', position: 'relative', cursor: 'pointer' }}
                    className={selectedPlanId == 3 && 'purple'}
                    onClick={() => changePlan(3)}
                >
                    <Heading style={{ color: '#000', fontWeight: '600', margin: 0, fontSize: '20px' }}>
                        Enterprise {props.currentPlanId == 3 && currentPlanDiv}
                    </Heading>
                    <Box mt={1} style={{ fontSize: '14px' }}>
                        Personalized service and enterprise security.
                    </Box>

                    <div
                        style={{
                            fontSize: '36px',
                            fontWeight: '400',
                            color: '#000',
                            marginTop: '24px',
                            lineHeight: '22px'
                        }}
                    >
                        Custom
                    </div>
                    <div style={{ margin: '8px 0 4px' }}>
                        <small>
                            {props.billing ? (
                                <strong>
                                    <a href="mailto:support@panelfox.io">Contact Us</a>
                                </strong>
                            ) : (
                                <>&nbsp;</>
                            )}
                        </small>
                    </div>

                    {!props.billing && (
                        <a href="mailto:support@panelfox.io">
                            <Button variant="tertiary" mt={2} mr={0}>
                                Contact Us
                            </Button>
                        </a>
                    )}
                </NiceWrapper>
                <br />
                {renderPricing(3)}
            </Box>
        </Flex>
    );
}
