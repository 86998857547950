import React, { useRef, useState } from 'react';
import { Box, Button } from 'rebass';
import { FiChevronDown } from 'react-icons/fi';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';

import Portal from 'components/Portal';

const Option = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    padding: 0 16px;
    margin: 2px 0;
    min-height: 32px;
    line-height: 32px;
    font-weight: 400;
    vertical-align: top;
    color: ${props => (props.disabled ? '#929DB0' : props.checked ? '#fff' : '#001233')};
    background: ${props => (props.checked ? 'rgb(98, 0, 255)' : 'transparent')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    &:hover {
        color: ${props => (props.disabled ? '#666' : 'rgb(98, 0, 255)')};
        background: #fafafa;
    }
`;

const Card = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 0px 17px 100px rgba(0, 0, 0, 0.06), 0px 5.125px 30.1471px rgba(0, 0, 0, 0.0390953),
        0px 2.12866px 12.5216px rgba(0, 0, 0, 0.03), 0px 0.769896px 4.5288px rgba(0, 0, 0, 0.0209047);
    font-size: 14px;
`;

const TOP_GAP = 4; // 4px

/**
 * Select component. It renders a button with a dropdown.
 *
 * @param {Object} props
 * @param {Array} props.options Array of options to be displayed in the dropdown
 * @param {Function} props.onChange Function to be called when the value changes
 * @param {string} props.value Current value
 * @param {string} props.placeholder Placeholder text
 * @param {string} props.variant Button variant
 * @param {(value: any | undefined) => React.ReactNode | undefined} props.label Button label
 *
 * The rest of the props are passed to the button
 */
export function Select({ options, onChange, value, placeholder, label, variant, ...props }) {
    const buttonRef = useRef(null);
    const selectRef = useRef(null);
    const [position, setPosition] = useState(null);

    /**
     * Open the dropdown and calculate the position
     *
     * TODO: use useWindowPositioner hook
     */
    const onOpen = () => {
        if (buttonRef.current && selectRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const selectRect = selectRef.current.getBoundingClientRect();

            // Initial default position
            const newPosition = {
                left: buttonRect.left + window.scrollX,
                top: buttonRect.bottom + window.scrollY + TOP_GAP
            };

            // If the dropdown is going to be out of the screen (right), then move it to the left
            if (newPosition.left + selectRect.width > window.innerWidth) {
                newPosition.left = newPosition.left + buttonRect.width - selectRect.width;
            }

            // If the dropdown is going to be out of the screen (bottom), then move it to the top
            if (newPosition.top + selectRect.height > window.innerHeight + window.scrollY) {
                newPosition.top = buttonRect.top + window.scrollY - selectRect.height - TOP_GAP;
            }

            // Set the new position
            setPosition(newPosition);
        }
    };

    /**
     * Close the dropdown
     */
    const onClose = () => setPosition(null);

    const labelElement = label ? label(value) : value;
    return (
        <>
            <Button variant={variant ? variant : 'secondary-gray'} ref={buttonRef} {...props} onClick={onOpen}>
                {value ? labelElement : placeholder ? placeholder : 'Select'}{' '}
                <FiChevronDown style={{ margin: '4px 0 0 8px' }} />
            </Button>
            <Portal>
                <Card
                    ref={selectRef}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        ...position,
                        visibility: position ? 'visible' : 'hidden'
                    }}
                >
                    <OutsideClickHandler onOutsideClick={onClose}>
                        <Box sx={{ overflow: 'auto', maxHeight: '400px', minWidth: '200px', padding: '4px 0' }}>
                            {options.map(option => (
                                <Option
                                    key={option.value}
                                    checked={option.value === value}
                                    onClick={() => {
                                        onChange(option.value);
                                        onClose();
                                    }}
                                    disabled={option.disabled}
                                >
                                    {option.label}
                                </Option>
                            ))}
                        </Box>
                    </OutsideClickHandler>
                </Card>
            </Portal>
        </>
    );
}

export default Select;
