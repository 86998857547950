import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'rebass';
import { Select, Label } from '@rebass/forms';
import services from 'services/services';
import { useToasts } from 'react-toast-notifications';
import helpers from 'utils/helpers';
import { EMAIL_TEMPLATE_TYPE } from 'utils/constants';

const MERGE_TAG_STUDY_INCENTIVE = 'Study Incentive';
const MERGE_TAG_STUDY_SESSION = 'Scheduled Session';
const MERGE_TAG_STUDY_LOCATION = 'Scheduled Location';
const MERGE_TAG_SESSION_DURATION = 'Session Duration';
const MERGE_TAG_PANELIST_UUID = 'PanelistUuid';
const MERGE_TAG_STUDYPANELIST_ID = 'StudyPanelistUuid';

const MERGE_TAG_UNSUBSCRIBE_LINK = 'unsubscribe_link';
const MERGE_TAG_UNSUBSCRIBE_LINK_LABEL = 'Unsubscribe Link';

export default function VariablesHelper({ study, templateType, ...props }) {
    const [renderCustomColumns, setRenderCustomColumns] = useState([]);
    const { addToast } = useToasts();

    useEffect(() => {
        getCustomDataColumnsXHR();
    }, [templateType]);

    function addCustomColumnMergeTag(e) {
        let mergeTag = e.target.value;
        mergeTag = `{${mergeTag}}`;

        helpers.copyToClipboard(mergeTag);

        addToast(`${mergeTag} copied to clipboard`, {
            appearance: 'success',
            autoDismiss: true
        });
    }

    function getCustomDataColumnsXHR() {
        const study_meta_tags = [
            { title: MERGE_TAG_STUDYPANELIST_ID },
            { title: MERGE_TAG_STUDY_SESSION },
            { title: MERGE_TAG_SESSION_DURATION },
            { title: MERGE_TAG_STUDY_LOCATION },
            { title: MERGE_TAG_STUDY_INCENTIVE }
        ];
        const additional_panelist_columns = [
            {
                title: MERGE_TAG_PANELIST_UUID
            }
        ];
        const htmlMergeTags = [
            {
                id: MERGE_TAG_UNSUBSCRIBE_LINK,
                label: MERGE_TAG_UNSUBSCRIBE_LINK_LABEL
            }
        ];
        services
            .getCustomDataColumnsXHR()
            .then(columns_xhr => {
                //console.log('GOT NEW COLUMNS', columns_xhr);
                const new_cols = [];
                new_cols.push(<option></option>);
                new_cols.push(<option disabled>- - - Panelist Properties - - -</option>);
                additional_panelist_columns.forEach(col => {
                    new_cols.push(
                        <option key={col.title} value={col.title}>
                            {col.title}
                        </option>
                    );
                });
                columns_xhr.forEach(col => {
                    new_cols.push(
                        <option key={col.title} value={col.title}>
                            {col.title}
                        </option>
                    );
                });
                new_cols.push(<option disabled>- - - Study Properties - - -</option>);
                study_meta_tags.forEach(col => {
                    new_cols.push(
                        <option key={col.title} value={col.title}>
                            {col.title}
                        </option>
                    );
                });

                if (study && study.custom_sp_attributes.length) {
                    new_cols.push(<option disabled>- - - Custom Study Attributes - - -</option>);
                    study.custom_sp_attributes.forEach(attr => {
                        new_cols.push(
                            <option key={attr.title} value={'StudyAttribute:' + attr.id}>
                                {attr.title}
                            </option>
                        );
                    });
                }

                // show HTML tags for HTML templates only
                if (templateType == EMAIL_TEMPLATE_TYPE.HTML) {
                    new_cols.push(<option disabled>- - - HTML Tags - - -</option>);
                    htmlMergeTags.forEach(tag => {
                        new_cols.push(
                            <option key={tag.id} value={tag.id}>
                                {tag.label}
                            </option>
                        );
                    });
                }

                setRenderCustomColumns(new_cols);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
            });
    }

    return (
        <Box flex="1">
            <Box className="form-label">Merge tags</Box>
            <Select
                onChange={e => {
                    addCustomColumnMergeTag(e);
                }}
            >
                <option value="" disabled selected hidden>
                    Select
                </option>
                {renderCustomColumns}
            </Select>
            {/* <Button variant="tertiary" mr={2} onClick={addScreenerLink}>
                <FiFileText /> Insert Screener Link
            </Button>
            <Button variant="tertiary" onClick={addSchedulingLink}>
                <FiCalendar /> Insert Scheduling Link
            </Button> */}
        </Box>
    );
}
