import React from 'react';
import { Redirect } from 'react-router';
import { Box, Button, Flex } from 'rebass';
import { Label, Input, Textarea, Select } from '@rebass/forms';
import xhr from 'xhr.js';
import { withToastManager } from 'react-toast-notifications';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import helpers from 'utils/helpers';
import helpersStudy from 'utils/helpers-study';
import helpersScreener from 'utils/helpers-screener';
import services from 'services/services';
import service_studies from 'services/studies';
import moment from 'moment-timezone';
import { ReactFormGenerator } from 'components/ReactFormBuilder';
import NiceDropdown from 'components/NiceDropdown';
import TimezoneDropdown from 'components/TimezoneDropdown';
import cloneDeep from 'lodash/cloneDeep';
import {
    SP_STATUS_KEY_TITLE,
    CURRENCY_SYMBOL,
    MOMENT_HUMAN_DATE,
    MAX_EMAIL_ATTACHMENTS_SIZE,
    SP_STATUS_KEY_CANCELLED,
    PAID_FEATURE,
    COL_FIRST_NAME,
    COL_EMAIL,
    COL_PHONE_NUMBER,
    COL_LAST_NAME,
    EMAIL_TEMPLATE_TYPE,
    INCENTIVE_PAYMENT_METHOD,
    INCENTIVE_PAYMENT_METHOD_LABEL,
    CURRENCY_POINTS,
    NOTIFICATION_TITLE_DISABLED_EMAILING,
    NOTIFICATION_CONTENT_DISABLED_EMAILING
} from 'utils/constants';
import analytics from 'utils/analytics';
import LoadingIndicator from 'components/LoadingIndicator';
import { AttachedFilesSection } from 'components/AttachedFilesSection';
import myxss from 'components/ReactFormBuilder/form-elements/myxss';
import {
    FiList,
    FiRotateCcw,
    FiActivity,
    FiSmartphone,
    FiPlus,
    FiWatch,
    FiExternalLink,
    FiInfo,
    FiAlertCircle,
    FiXCircle,
    FiPaperclip,
    FiSend,
    FiUser,
    FiCopy,
    FiEdit,
    FiTrash2,
    FiEye,
    FiCalendar,
    FiClock,
    FiChevronUp,
    FiSettings,
    FiUserX,
    FiUserCheck,
    FiFileText
} from 'react-icons/fi';
import {
    Editor,
    EditorState,
    RichUtils,
    convertToRaw,
    convertFromRaw,
    getVisibleSelectionRect,
    Modifier,
    CompositeDecorator,
    SelectionState
} from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';
import 'styles/draftjs-rich.css';
// import IconBold from 'images/icons/bold.svg';
import styled from 'styled-components';
import NiceWrapper from 'components/NiceWrapper';
import ParticipantPay from 'components/Participant/ParticipantPay';
import ParticipantSettings from 'components/Participant/ParticipantSettings';
import PastParticipation from 'components/Participant/PastParticipation';
import { CollapsibleContentContainer } from 'components/CollapsibleContent';
import { isEqual } from 'lodash';
import NiceModal from 'components/NiceModal';
import UpgradeButton from 'components/UpgradeButton';
import DatePicker from 'react-datepicker';
import NotificationCard from 'components/NotificationCard';
import { IncentiveProgress } from 'components/IncentiveProgress';
import { ManualPaymentModal } from 'components/ManualPaymentModal';

import ParticipantMaskedValue from './ParticipantMaskedValue';
import OptOutBadge from 'components/OptOutBadge';
import PaymentModal from 'components/PaymentModal';

const MSG_TYPE_EMAIL = 'Email';
const MSG_TYPE_SMS = 'SMS';

const tagColors = {};
function getRandomColor() {
    const colors = [];
    for (let i = 0; i < 3; i++) {
        colors.push(Math.floor(Math.random() * 255));
    }
    return `rgba(${colors.join(',')}, 0.5)`;
}

function getTagColor(id) {
    if (!tagColors[id]) {
        tagColors[id] = getRandomColor();
    }

    console.log(tagColors);

    return tagColors[id];
}

const NotesTitle = styled(Flex)`
    font-size: 28px;
    font-weight: 600;
    color: #000;
`;

const EmailFromParticipant = styled.div`
    text-align: left;
    margin: 0 0 16px 0;

    .email-avatar-participant {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #e2963d;
        color: #fff;
        vertical-align: top;
        margin: 0 16px 0 0;
        line-height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        overflow: hidden;
    }

    .sms-content {
        max-width: 80%;
        border-radius: 8px;
        display: inline-block;
        background: #fff;
        //padding: 8px 16px;
    }
    .sms-content-inside {
        padding: 8px 16px;
    }

    .sms-label {
        border-color: #e7e8f1;
    }

    .email-content {
        max-width: 80%;
        border-radius: 8px;
        display: inline-block;
        background: #fff;
        padding: 8px 16px;
        white-space: pre-wrap;
    }
    .email-attachments {
        border-top: 1px solid #ddd;
        margin: 16px 0 0 0;
        padding: 8px 0 0 0;
        display: block;
        font-size: 12px;
    }
    .email-date {
        margin: 6px 0 0 48px;
        font-size: 12px;
        color: #929db0;
        cursor: pointer;
    }
    .email-tooltip {
        max-width: 320px;
        text-align: left;

        .label {
            color: #6b7a94;
            font-size: 12px;
        }
    }
`;

const EmailToParticipant = styled.div`
    margin: 0 0 16px 0;
    color: black;
    text-align: right;

    .email-avatar-researcher {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #ebe3fb; //rgba(0, 0, 255, 0.1);
        vertical-align: top;
        margin: 0 0 0 16px;
        line-height: 32px;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }

    .sms-content {
        max-width: 80%;
        border-radius: 8px;
        display: inline-block;
        background: #ebe3fb; //rgba(0, 0, 255, 0.1);
        //padding: 8px 16px;
    }
    .sms-content-inside {
        padding: 8px 16px;
    }

    .email-content {
        max-width: 80%;
        text-align: left;
        padding: 8px 16px;
        border-radius: 8px;
        display: inline-block;
        background: #ebe3fb; //rgba(0, 0, 255, 0.1);
        white-space: pre-wrap;
    }
    .email-date {
        margin: 6px 48px 0 0;
        font-size: 12px;
        color: #929db0;
        cursor: pointer;
    }
    .email-attachments {
        border-top: 1px solid #ddd;
        margin: 16px 0 0 0;
        padding: 8px 0 0 0;
        display: block;
        font-size: 12px;
    }
    .email-tooltip {
        max-width: 320px;
        text-align: left;
        .label {
            color: #6b7a94;
            font-size: 12px;
        }
    }
`;

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function getEntityStrategy(type) {
    return function(contentBlock, callback, contentState) {
        contentBlock.findEntityRanges(character => {
            const entityKey = character.getEntity();
            if (entityKey === null) {
                return false;
            }
            return contentState.getEntity(entityKey).getType() === type;
        }, callback);
    };
}
const TokenSpan = props => {
    const displaythis = props.contentState.getEntity(props.entityKey);
    const entityData = displaythis.getData();
    const tagsArray = entityData.tags;

    // const block = props.contentState.getBlockForKey(props.blockKey);
    console.log('BLOCK BY KEY', props.entityKey);

    function showTagCloud(e, entityKey) {
        // e.currentTarget.style.border = '1px solid red'
        console.log(entityKey);
        console.log(props);
        // console.log(getEntityRange(props.editor.state.editorState, entityKey))

        // alert(tagContent)
        // <div className='tagHover'>{props.entityKey} {tagContent}</div>
        // Modifier.removeRange(props.contentState, )
        // getSelectionEntity(props.)

        // removeHighlight()

        const selectionState = new SelectionState({
            anchorKey: props.blockKey,
            anchorOffset: props.start,
            focusKey: props.blockKey,
            focusOffset: props.end
        });

        const selectionRect = getVisibleSelectionRect(window);
        if (!selectionRect) return;
        // console.log('selection coords', selectionRect)

        const editorWrapperElement = document.getElementById('editor-wrapper');
        const editorWrapperPosition = editorWrapperElement.getBoundingClientRect();

        props.editor.setState({
            tagManager: {
                show: true,
                selectedTextPosition: selectionRect,
                editorWrapperPosition,
                existingTags: tagsArray,
                selectionState,
                entityKey: props.entityKey
            }
        });
    }

    /* function removeHighlight() {
        const content = props.editor.state.editorState.getCurrentContent();
        const block = content.getBlockForKey(props.blockKey);

        const targetRange = new SelectionState({
            anchorKey: props.blockKey,
            anchorOffset: props.start,
            focusKey: props.blockKey,
            focusOffset: props.end
        });

        const withoutEntity = Modifier.applyEntity(content, targetRange, null);

        const newEditorState = EditorState.push(props.editor.state.editorState, withoutEntity, 'apply-entity');

        // console.log(JSON.stringify(selectionState.toJS(), null, 4));
        // console.log(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())))

        props.editor.onNotesChange(newEditorState);
    } */

    let tagStyle = { background: 'transparent' };
    let firstTagId;
    if (tagsArray.length) {
        // eslint-disable-next-line prefer-destructuring
        firstTagId = tagsArray[0];
        tagStyle = { background: getTagColor(firstTagId) };
    }

    return (
        <span data-offset-key={props.offsetkey} style={tagStyle} onClick={e => showTagCloud(e, props.entityKey)}>
            {props.children}
        </span>
    );
};

const SpDataLabelClassName = 'text-secondary-dark medium fs-12 mt-16'; //sp-data-label-top-border

class ParticipantNotes extends React.Component {
    constructor(props) {
        super(props);

        const decorator = new CompositeDecorator([
            {
                strategy: getEntityStrategy('TAG_THING'),
                component: TokenSpan,
                props: { editor: this }
            }
        ]);

        let editorState = null;
        let rawContentFromStore = null;
        if (this.props.study_person.notes) {
            if (IsJsonString(this.props.study_person.notes)) {
                rawContentFromStore = convertFromRaw(JSON.parse(this.props.study_person.notes));
            } else {
                rawContentFromStore = convertFromHTML(this.props.study_person.notes);
            }

            editorState = EditorState.createWithContent(rawContentFromStore, decorator);
        } else {
            editorState = EditorState.createEmpty(decorator);
        }

        this.lockedUpdate = null;

        this.state = {
            loading: false,
            timezones: {},
            study_person: this.props.study_person,
            editorState,
            tagManager: {
                show: false
            },
            study: this.props.study,
            all_tags: [],
            tagManagerFilterKeyword: '',
            modalPaymentMethod: null,
            showMsgOptions: false,
            sendEmail_content: '',
            sendEmail_subject: '',
            schedulingLinks: {
                create: '',
                reschedule: '',
                cancel: ''
            },
            isEditPaymentModalOpen: false,
            isPaymentModalOpen: false,
            study_availability_array: [],
            editAnswersScreenerId: null,
            screener_edit_is_submitting: false,
            sendEmail_attachments: [],
            email_attachment_isUploading: false,
            sp_tab_selected: 'recruit',
            unmaskedFirstName: false,
            unmaskedLastName: false,
            unmaskedEmail: false,
            unmaskedPhone: false
        };

        // this.contentEditable = React.createRef();

        this.emailAttachmentUploadButton = this.emailAttachmentUploadButton.bind(this);
        this.updateParticipantStatus = this.updateParticipantStatus.bind(this);
        this.getDataForScreener = this.getDataForScreener.bind(this);
        this.updateTimezoneXHR = this.updateTimezoneXHR.bind(this);
        this.renderLocation = this.renderLocation.bind(this);
        this.onNotesChange = this.onNotesChange.bind(this);
        this.textSelected = this.textSelected.bind(this);
        this.updateTags = this.updateTags.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutsideTagManager = this.handleClickOutsideTagManager.bind(this);
        this.submitTagManagerNewTag = this.submitTagManagerNewTag.bind(this);
        this.tagManagerFilterList = this.tagManagerFilterList.bind(this);
        this.getParticipantXHR = this.getParticipantXHR.bind(this);
        this.getStudyXHR = this.getStudyXHR.bind(this);
        this.onChange_sendEmail_content = this.onChange_sendEmail_content.bind(this);
        this.onChange_sendEmail_subject = this.onChange_sendEmail_subject.bind(this);
        this.onClickSendMsg = this.onClickSendMsg.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.generateSchedulingLinks = this.generateSchedulingLinks.bind(this);
        this.addTemporaryEmailSent = this.addTemporaryEmailSent.bind(this);
        this.messagesEndRef = React.createRef();
        // eslint-disable-next-line react/no-string-refs
        this.focus = () => this.refs.editor.focus();
        // this.handleKeyCommand = command => this.handleKeyCommand(command);
        // eslint-disable-next-line no-underscore-dangle
        this.onTab = e => this._onTab(e);
        this.toggleBlockType = type => this.toggleBlockType(type);
        this.toggleInlineStyle = style => this.toggleInlineStyle(style);

        this.customAttributeChanged = this.customAttributeChanged.bind(this);

        // this.getParticipantXHR();
        // this.getStudyXHR();

        this.generateSchedulingLinks();
    }

    emailAttachmentUploadButton() {
        const { toastManager } = this.props;
        document.getElementById('email_attachment_upload').click();
        document.getElementById('email_attachment_upload').onchange = e => {
            const formData = new FormData();
            const file = e.target.files[0];
            const current_file_size = file.size;

            console.log('filesize', current_file_size);

            // sum up attachment file sizes, including this new one
            let sum_attachments_file_sizes = 0;
            sum_attachments_file_sizes += current_file_size;

            this.state.sendEmail_attachments.forEach(a => {
                if (a && a.filesize) {
                    sum_attachments_file_sizes += a.filesize;
                }
            });

            if (sum_attachments_file_sizes > MAX_EMAIL_ATTACHMENTS_SIZE) {
                analytics.track('study-sp-attachment-TOO_BIG');
                alert('Sum of all attachments can not exceed ' + helpers.humanFileSize(MAX_EMAIL_ATTACHMENTS_SIZE));
                return;
            }
            formData.append('attachment', file);

            this.setState({ email_attachment_isUploading: true });

            services
                .uploadEmailAttachment(formData)
                .then(file_object => {
                    //console.log(file_object);
                    let { sendEmail_attachments } = this.state;

                    // add file size to display
                    file_object.filesize = current_file_size;

                    sendEmail_attachments.push(file_object);
                    //console.log(sendEmail_attachments);
                    /*{
                    'filename': 'awhattt'+sendEmail_attachments.length+'.jpeg',
                    'filepath': 'https://google.com'
                }*/
                    this.setState({
                        sendEmail_attachments,
                        email_attachment_isUploading: false
                    });

                    analytics.track('study-sp-attachment-add');
                })
                .catch(error => {
                    this.setState({
                        email_attachment_isUploading: false
                    });

                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        };
    }

    onChange_sendEmail_content(e) {
        this.setState({ sendEmail_content: e.target.value });
    }

    onChange_sendEmail_subject(e) {
        this.setState({ sendEmail_subject: e.target.value });
    }

    addTemporaryEmailSent(person, emailCampaign) {
        const newEmail = {
            created_at: moment()
                .utc()
                .format('YYYY-MM-DD HH:mm:ss'),
            id: Math.floor(Math.random() * 100000) + 10000,
            message_parsed: emailCampaign.content,
            recipients_to: helpers.personGetCustomValue(person, COL_EMAIL),
            study_id: this.state.study.id,
            subject: emailCampaign.subject,
            user: this.props.auth.user
        };
        const sp = this.state.study_person;
        sp.emails_sent.push(newEmail);
        this.setState({ study_person: sp });
    }

    onClickSendMsg = type => () => {
        const { toastManager } = this.props;
        if (!this.state.sendEmail_content || !this.state.sendEmail_content.trim()) {
            return;
        }

        const { sendEmail_content, sendEmail_subject, sendEmail_attachments } = this.state;
        this.setState({ sendEmail_sending: true });

        // get latest campaign ID
        const emailCampaign = {
            subject: sendEmail_subject,
            content: sendEmail_content,
            is_reply_campaign_id: '',
            attachments: sendEmail_attachments
        };
        if (this.state.study_person.emails_sent && this.state.study_person.emails_sent.length) {
            const lastES = this.state.study_person.emails_sent[this.state.study_person.emails_sent.length - 1];
            emailCampaign.is_reply_campaign_id = lastES.email_campaign_id;
            emailCampaign.subject = lastES.subject;
        } else {
            console.log('study_person emails not found...');
        }

        // this.addTemporaryEmailSent(this.state.study_person.person, emailCampaign);

        if (type === MSG_TYPE_EMAIL) {
            if (!this.state.study_person.emails_sent.length) {
                let subject = prompt('Write the subject of your email:');
                if (subject) {
                    emailCampaign.subject = subject;
                } else {
                    this.setState({ sendEmail_sending: false });
                    toastManager.add('Please write a subject, and try again.', {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    return;
                }
            }

            services
                .sendStudyPersonEmail({
                    personId: this.state.study_person.person.id,
                    studyId: this.state.study_person.study_id,
                    studyPersonId: this.state.study_person.id,
                    emailCampaign
                })
                .then(study_person_xhr => {
                    // the Email is an async job, so it will not yet show up here
                    // MM: 2/14/2023, will it not show up here?

                    this.getUpdatedStudyPerson();

                    /*this.setState({
                        study_person: study_person_xhr
                    });*/
                    this.setState({
                        sendEmail_content: '',
                        sendEmail_subject: '',
                        sendEmail_attachments: []
                    });
                    toastManager.add('Successfully sent email', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    this.scrollToBottom();
                })
                .catch(error => {
                    this.setState({
                        sendEmail_content: emailCampaign.content,
                        sendEmail_subject: emailCampaign.subject,
                        sendEmail_attachments: emailCampaign.attachments
                    });
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                })
                .finally(() => {
                    this.setState({ sendEmail_sending: false });
                });
        } else if (type === MSG_TYPE_SMS) {
            let msgContent = this.state.sendEmail_content;
            const action = 'send';

            if (emailCampaign.attachments) {
                emailCampaign.attachments.forEach(attachment => {
                    msgContent += ` ${attachment.filepath} `;
                });
            }

            //console.log(emailCampaign.attachments)
            //console.log(msgContent)
            //return;

            if (!this.state.study_person.person_id) {
                toastManager.add('Person not found, please refresh and try again.', {
                    appearance: 'error',
                    autoDismiss: false
                });
                return;
            }
            const sms_people = [{ id: this.state.study_person.person_id }];

            service_studies
                .sendSmsCampaign(this.state.study.id, sms_people, [], null, msgContent, action, null)
                .then(smsCampaignResponse => {
                    console.log('%c emailCampaign', 'red', smsCampaignResponse);
                    const success_msg = 'Successfully sent SMS';
                    toastManager.add(success_msg, {
                        appearance: 'success',
                        autoDismiss: true
                    });

                    this.getUpdatedStudyPerson();

                    this.setState({
                        sendEmail_content: '',
                        sendEmail_subject: '',
                        sendEmail_attachments: []
                    });

                    this.scrollToBottom();
                })
                .catch(error => {
                    this.setState({
                        sendEmail_content: emailCampaign.content
                    });

                    helpers.trackError(error);
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                })
                .finally(() => {
                    this.setState({ sendEmail_sending: false });
                });
        }
    };

    handleResendEmail(email_individual_id) {
        const { toastManager } = this.props;

        this.setState({ reSendingEmail: true });

        service_studies
            .resendEmail(this.state.study.id, email_individual_id)
            .then(data => {
                const success_msg = 'Successfully re-sent email';
                toastManager.add(success_msg, {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.getUpdatedStudyPerson();
                this.setState({ reSendingEmail: false });
            })
            .catch(error => {
                helpers.trackError(error);
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });

                this.setState({ reSendingEmail: false });
            });
    }

    /**
     * Mark the study person as paid. (Mark as paid)
     *
     * @param {Object} data
     * @param {string} data.paymentDate The payment date
     * @param {number} data.amount The payment amount
     */
    async processManualPayment(data) {
        const { toastManager } = this.props;
        const { study_person } = this.state;

        try {
            await service_studies.processManualPayment(study_person.study_id, study_person.id, {
                incentiveStatus: 'paid',
                incentiveTotalPaid: data.amount,
                paymentDate: data.paymentDate
            });

            // on success, update the study person, and close the modal
            this.getParticipantXHR();
            this.setState({ isEditPaymentModalOpen: false });
            toastManager.add('Updated successfully', {
                appearance: 'success',
                autoDismiss: true
            });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutsideTagManager(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ tagManager: { show: false }, tagManagerFilterKeyword: '' });
        }
    }

    updateTimezoneXHR(e) {
        const timezone = e.target.value;

        const { toastManager } = this.props;
        service_studies
            .updateStudyPersonTimezone(this.state.study_person.study_id, this.state.study_person.id, timezone)
            .then(sp => {
                let { study_person } = this.state;
                study_person.timezone = sp.timezone;

                this.setState({
                    study_person
                });

                this.getAvailabilityXHR();

                toastManager.add('Session saved successfully', {
                    appearance: 'success',
                    autoDismiss: true
                });

                //this.parentCallback('save', study_person);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    updateParticipantStatus(e) {
        const status_value = e.target.value;
        const { toastManager } = this.props;

        let { study_person } = this.state;
        study_person.status = status_value;

        if (status_value == SP_STATUS_KEY_CANCELLED) {
            study_person.spot = null;
        }

        this.setState({
            study_person
        });

        service_studies
            .updatePersonStatus(this.state.study_person.study_id, this.state.study_person.id, status_value)
            .then(sp => {
                toastManager.add('Status saved successfully', {
                    appearance: 'success',
                    autoDismiss: true
                });

                //this.parentCallback('save', study_person);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    updateSpotXHR(spot) {
        const { toastManager } = this.props;
        service_studies
            .updateStudyPersonSpot(this.state.study_person.study_id, this.state.study_person.id, spot)
            .then(sp_response => {
                this.getUpdatedStudyPerson();

                this.getAvailabilityXHR();

                toastManager.add('Session saved successfully', {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.scrollToBottom();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    cancelSpotXHR() {
        const { toastManager } = this.props;
        service_studies
            .cancelStudyPersonSpot(this.state.study_person.study_id, this.state.study_person.id)
            .then(sp_response => {
                this.getUpdatedStudyPerson();

                this.getAvailabilityXHR();

                toastManager.add('Session cancelled successfully', {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.scrollToBottom();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getAvailabilityXHR() {
        const { toastManager } = this.props;
        service_studies
            .getStudyAvailability(this.state.study.id)
            .then(study_availability => {
                const sp_timezone = this.state.study_person.timezone
                    ? this.state.study_person.timezone
                    : this.state.study.timezone;
                let study_availability_array = helpersStudy.generateStudyAvailabilityArray(
                    study_availability,
                    this.state.study.timezone,
                    sp_timezone
                );

                this.setState({
                    study_availability_array
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });

                helpers.trackError(error);
            });
    }

    componentDidMount() {
        const { toastManager } = this.props;

        // document.addEventListener('mousedown', this.handleClickOutsideTagManager);
        this.scrollToBottom();
        this.getAvailabilityXHR();

        services.getTimezones().then(timezones => {
            this.setState({ timezones });
        });

        // set timer every N seconds to send a "locked" update
        this.lockedUpdate = setInterval(() => {
            const lock_action = 'lock';
            service_studies
                .getStudyPerson(this.state.study.id, this.state.study_person.id, lock_action)
                .then(study_person => {
                    // console.log("%c get study person xhr", "color:blueberry", study_person)
                })
                .catch(error => {
                    //this.handleCloseModalStudyParticipant();

                    helpers.trackError(error);
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }, 30 * 1000);
    }

    componentWillUnmount() {
        //console.log('UNMOUNTING')
        this.scrollToBottom();
        if (this.lockedUpdate) {
            clearInterval(this.lockedUpdate);
        }
    }

    scrollToBottom() {
        //console.log('scrolling?', this.messagesEndRef);
        // this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
        // this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })

        try {
            if (this.messagesEndRef && this.messagesEndRef.current) {
                this.messagesEndRef.current.scrollTop = this.messagesEndRef.current.scrollHeight;
            }
        } catch (e) {
            helpers.trackError(e);
        }
    }

    textSelected() {
        // do not update this if clickign within the tag manager
        // console.log('TM', document.getElementById('#tag-manager'))
        // eslint-disable-next-line no-restricted-globals
        if (document.getElementById('#tag-manager') && document.getElementById('#tag-manager').contains(event.target)) {
            console.log('clicked inside tag manager');
            return;
        }
        setTimeout(() => {
            const windowSelection = window.getSelection();
            // console.log(windowSelection)
            const selectionText = windowSelection.toString();

            if (selectionText && selectionText.length > 0) {
                const selectionRect = getVisibleSelectionRect(window);
                if (!selectionRect) return;
                // console.log('selection text', selectionText)

                const editorWrapperElement = document.getElementById('editor-wrapper');
                const editorWrapperPosition = editorWrapperElement.getBoundingClientRect();

                // console.log('posss...', editorWrapperPosition)

                const selectionState = this.state.editorState.getSelection();

                console.log('WTF.....', selectionState);

                let existingTags = [];
                const entityRanges = this.getEntityRanges(this.state.editorState, 'TAG_THING');
                entityRanges.forEach(er => {
                    // if backwards
                    if (
                        (selectionState.getIsBackward() == false &&
                            er.blockKey == selectionState.getAnchorKey() &&
                            er.start == selectionState.getAnchorOffset() &&
                            er.end == selectionState.getFocusOffset()) ||
                        (selectionState.getIsBackward() == true &&
                            er.blockKey == selectionState.getAnchorKey() &&
                            er.start == selectionState.getFocusOffset() &&
                            er.end == selectionState.getAnchorOffset())
                    ) {
                        const entityData = er.entity.getData();
                        if (entityData && entityData.tags) {
                            existingTags = entityData.tags;
                        }
                    }
                });

                this.setState({
                    tagManager: {
                        show: true,
                        selectedTextPosition: selectionRect,
                        editorWrapperPosition,
                        selectionState,
                        existingTags
                    }
                });
            }
        });
    }

    /**
     * Update the study person payment status
     *
     * @param {Object} data
     * @param {string} data.paymentDate The payment date
     * @param {number} data.amount The payment amount
     */
    async updateManualPayment({ paymentDate, amount }) {
        const { toastManager } = this.props;
        const { study_person } = this.state;

        try {
            await service_studies.updateManualPayment(study_person.study_id, study_person.id, {
                paymentDate,
                amount
            });

            // on success, update the study person, and close the modal
            toastManager.add('Payment updated successfully', {
                appearance: 'success',
                autoDismiss: true
            });
            this.getParticipantXHR();
            this.setState({ isEditPaymentModalOpen: false });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    getEntityRanges(editorState, entityType = null) {
        const content = editorState.getCurrentContent();
        const entities = [];
        content.getBlocksAsArray().forEach(block => {
            let selectedEntity = null;
            block.findEntityRanges(
                character => {
                    if (character.getEntity() !== null) {
                        const entity = content.getEntity(character.getEntity());
                        if (!entityType || (entityType && entity.getType() === entityType)) {
                            selectedEntity = {
                                entityKey: character.getEntity(),
                                blockKey: block.getKey(),
                                entity: content.getEntity(character.getEntity())
                            };
                            return true;
                        }
                    }
                    return false;
                },
                (start, end) => {
                    entities.push({ ...selectedEntity, start, end });
                }
            );
        });
        return entities;
    }

    getUpdatedStudyPerson = () => {
        const { toastManager } = this.props;
        service_studies
            .getStudyPerson(this.state.study.id, this.state.study_person.id)
            .then(study_person => {
                if (isEqual(study_person, this.state.study_person) === false) this.setState({ study_person });

                this.scrollToBottom();
            })
            .catch(error => {
                helpers.trackError(error);
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    updateTags() {
        const checkedTagsArray = [];
        const inputElements = document.getElementsByClassName('highlight-tag');
        for (let i = 0; inputElements[i]; ++i) {
            if (inputElements[i].checked) {
                // eslint-disable-next-line radix
                checkedTagsArray.push(parseInt(inputElements[i].value));
            }
        }

        console.log('updated tags', checkedTagsArray);

        const { tagManager } = this.state;
        tagManager.existingTags = checkedTagsArray;
        this.setState({ tagManager });

        const currentContent = this.state.editorState.getCurrentContent();
        let newContentState;
        // if this is a new entity
        if (!this.state.tagManager.entityKey) {
            newContentState = currentContent.createEntity('TAG_THING', 'MUTABLE', { tags: checkedTagsArray });
            const entityKey = currentContent.getLastCreatedEntityKey();
            newContentState = Modifier.applyEntity(
                newContentState,
                this.state.tagManager.selectionState,
                checkedTagsArray.length > 0 ? entityKey : null
            );
        } else {
            // if this is an existing entity
            newContentState = currentContent.replaceEntityData(this.state.tagManager.entityKey, {
                tags: checkedTagsArray
            });
        }

        // console.log(entityKey, newContentState);

        let newEditorState = EditorState.push(this.state.editorState, newContentState, 'apply-entity');

        // console.log(JSON.stringify(selectionState.toJS(), null, 4));
        // console.log(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())))

        newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());

        this.onNotesChange(newEditorState);

        this.forceUpdate();
    }

    onNotesChange(editorState) {
        const study_person = { ...this.state.study_person };
        study_person.notes = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        // console.log(e.target.name);
        this.setState({ study_person, editorState });

        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        // set timer for 200ms
        this.saveDelay = setTimeout(
            function() {
                this.saveStudyPersonXHR(study_person);
            }.bind(this),
            500
        );
    }

    showSavedNotification() {
        this.setState({ saved: 'show' });

        // detect change, and clear timeout if exists
        if (this.saveNotificationDelay) {
            clearTimeout(this.saveNotificationDelay);
        }

        // set timer for 200ms
        this.saveNotificationDelay = setTimeout(
            function() {
                this.setState({ saved: 'hide' });
            }.bind(this),
            3000
        );
    }

    getStudyXHR() {
        xhr.get(`/studies/${this.state.study.id}?account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        }).then(res => {
            const study = res.data;
            const all_tags = study.tags;

            this.setState(
                {
                    study,
                    all_tags
                },
                () => {
                    this.generateSchedulingLinks();
                }
            );
        });
    }

    getParticipantXHR() {
        const { toastManager } = this.props;

        xhr.get(
            `/studies/${this.state.study.id}/study-people/${this.state.study_person.id}?account_id=${this.props.auth.account.id}`,
            { withCredentials: true }
        )
            .then(response => {
                const study_person = response.data;

                let editorState = null;
                let rawContentFromStore = null;
                const decorator = new CompositeDecorator([
                    {
                        strategy: getEntityStrategy('TAG_THING'),
                        component: TokenSpan,
                        props: { editor: this }
                    }
                ]);

                if (study_person.notes) {
                    if (IsJsonString(study_person.notes)) {
                        rawContentFromStore = convertFromRaw(JSON.parse(study_person.notes));
                    } else {
                        rawContentFromStore = convertFromHTML(study_person.notes);
                    }

                    editorState = EditorState.createWithContent(rawContentFromStore, decorator);
                } else {
                    editorState = EditorState.createEmpty(decorator);
                }

                this.setState({ study_person, editorState });
            })
            .catch(error => {
                services.parseAndTrackXhrErrors(error);
                let errorText = 'Error';

                if (error.response) {
                    Object.keys(error.response.data).forEach(property => {
                        errorText += `${error.response.data[property]} `;
                    });
                }

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    saveStudyPersonCustomAttributeXHR(cspa_id, cspa_val) {
        const { toastManager } = this.props;

        xhr.put(
            `/studies/${this.state.study_person.study_id}/study-people/${this.state.study_person.id}/custom-attributes?account_id=${this.props.auth.account.id}`,
            {
                custom_attribute_id: cspa_id,
                custom_attribute_value: cspa_val
            },
            { withCredentials: true }
        )
            .then(response => {
                //this.showSavedNotification();
                //this.parentCallback('save', response.data);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    saveStudyPersonXHR(study_person) {
        //console.log('SAVED person!!!', study_person);

        const { toastManager } = this.props;

        const studyPerson = study_person;

        xhr.put(
            `/studies/${study_person.study_id}/study-people/${study_person.id}?account_id=${this.props.auth.account.id}`,
            studyPerson,
            {
                withCredentials: true
            }
        )
            .then(response => {
                console.log('saved.........');
                //this.showSavedNotification();
                //this.parentCallback('save', response.data);
            })
            .catch(error => {
                services.parseAndTrackXhrErrors(error);
                let errorText = 'Error';

                if (error.response) {
                    Object.keys(error.response.data).forEach(property => {
                        errorText += `${error.response.data[property]} `;
                    });
                }

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    createTagXHR(title, cb) {
        const { toastManager } = this.props;

        xhr.post(
            `/study-tags?account_id=${this.props.auth.account.id}`,
            { study_id: this.props.study.id, title },
            { withCredentials: true }
        )
            .then(response => {
                toastManager.add('Tag created', {
                    appearance: 'info',
                    autoDismiss: true
                });

                cb(false, response.data);
            })
            .catch(error => {
                services.parseAndTrackXhrErrors(error);
                cb(true, error);

                toastManager.add('There was an error creating this tag.', {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    parentCallback(action, study_person) {
        if (this.props.parentCallback) {
            //console.log('parentCallback2', action, study_person);
            this.props.parentCallback(action, study_person);
        }
    }

    handleKeyCommand(command) {
        const { editorState } = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onNotesChange(newState);
            return true;
        }
        return false;
    }

    _onTab(e) {
        console.log('tab');
        const maxDepth = 4;
        this.onNotesChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    }

    toggleBlockType(blockType) {
        this.onNotesChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }

    toggleInlineStyle(inlineStyle) {
        this.onNotesChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
    }

    submitTagManagerNewTag(e) {
        e.preventDefault();
        let val = this.state.tagManagerFilterKeyword;
        val = val.trim();
        if (val.length > 0) {
            const that = this;
            this.createTagXHR(val, (err, tag) => {
                if (!err) {
                    const { all_tags } = that.state;
                    all_tags.unshift(tag);

                    that.setState(
                        {
                            all_tags,
                            tagManagerFilterKeyword: ''
                        },
                        () => {
                            // automatically "check" this tag
                            const inputElements = document.getElementsByClassName('highlight-tag');
                            for (let i = 0; inputElements[i]; ++i) {
                                if (inputElements[i].value == tag.id) {
                                    inputElements[i].click();
                                }
                            }
                        }
                    );
                }
            });
        }
    }

    tagManagerFilterList() {
        const el = document.getElementById('new_tag');
        this.setState({
            tagManagerFilterKeyword: el.value
        });
    }

    updateLocationXHR() {
        const location = document.getElementById(`${this.state.study_person.id}-location`).value;
        const { toastManager } = this.props;

        service_studies
            .updateStudyPersonLocation(this.state.study_person.study_id, this.state.study_person.id, location)
            .then(spot => {
                let { study_person } = this.state;
                study_person.custom_location = location;
                this.setState({
                    study_person,
                    editLocation: false
                });
                toastManager.add('Successfully saved session location', {
                    appearance: 'success',
                    autoDismiss: true
                });

                //this.parentCallback('save', study_person);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    customAttributeChanged(e, study_sp_attribute) {
        let { study_person } = this.state;

        let val;
        if (study_sp_attribute.type && study_sp_attribute.type == 'checkbox') {
            const checked = [];
            const checkboxes = document.getElementsByName(`study_cspa_${study_sp_attribute.id}`);
            for (let i = 0, n = checkboxes.length; i < n; i++) {
                if (checkboxes[i].checked) {
                    checked.push(checkboxes[i].value);
                }
            }
            console.log('checkboxes', checkboxes, checked);
            val = JSON.stringify(checked);
        } else {
            val = e.target.value;
        }

        let found = study_person.custom_attributes.find(sp_ca => sp_ca.sp_attribute_id == study_sp_attribute.id);
        if (found) {
            console.log('found..');
            found.value = val;
        } else {
            console.log('NOT found..');
            const new_sp_ca = {
                study_id: this.state.study.id,
                study_person_id: this.state.study_person.id,
                sp_attribute_id: study_sp_attribute.id,
                value: val
            };
            study_person.custom_attributes.push(new_sp_ca);
        }
        this.setState({ study_person });

        // detect change, and clear timeout if exists
        if (this.saveDelay_sp_attribute) {
            clearTimeout(this.saveDelay_sp_attribute);
        }

        // set timer for 200ms
        this.saveDelay_sp_attribute = setTimeout(
            function() {
                this.saveStudyPersonCustomAttributeXHR(study_sp_attribute.id, val);
            }.bind(this),
            500
        );
    }

    notAllowedToCommunicate(person) {
        return helpers.personIsOptedOut(person) || helpers.personOnBlocklist(person);
    }

    notAllowedToPay(study_person) {
        return (
            !study_person.study_fatigue_exclude &&
            study_person.person.panelist_eligibility2.find(pe => pe.suppress == true && pe.type == 'incentive')
        );
    }

    renderCommunicationStatus(person) {
        let elements = [];
        if (helpers.personIsOptedOut(person)) {
            elements.push(
                helpers.renderErrorLabel({
                    title: 'Opt-Out of Email',
                    content: `${helpers.personGetCustomValue(person, COL_FIRST_NAME)} opted out of email communication.`
                })
            );
        }
        if (helpers.personIsOptedOutSms(person)) {
            elements.push(
                helpers.renderErrorLabel({
                    title: 'Opted out of SMS',
                    content: `${helpers.personGetCustomValue(person, COL_FIRST_NAME)} opted out of SMS communication.`
                })
            );
        }
        if (helpers.personOnBlocklist(person)) {
            elements.push(
                helpers.renderErrorLabel({
                    title: 'Opt-Out Due to Blocklist',
                    content: `${helpers.personGetCustomValue(
                        person,
                        'First Name'
                    )} has opted out of email communication because of being on a blocklist.`
                })
            );
        }

        if (!elements.length) return null;

        return (
            <Flex flexDirection="column" sx={{ gap: '16px' }} mb="16px">
                {elements}
            </Flex>
        );
    }

    renderCustomAttributes() {
        let ret = [];
        if (this.state.study && this.state.study.custom_sp_attributes) {
            this.state.study.custom_sp_attributes.forEach(study_cspa => {
                if (this.state.study_person.custom_attributes) {
                    let sp_cs_found = this.state.study_person.custom_attributes.find(
                        sp_cspa => sp_cspa.sp_attribute_id == study_cspa.id
                    );
                    const sp_cs_found_value = sp_cs_found && sp_cs_found.value ? sp_cs_found.value : '';
                    let render_edit = helpersStudy.renderCspaEdit(
                        study_cspa,
                        sp_cs_found_value,
                        this.customAttributeChanged
                    );

                    ret.push(
                        <Box>
                            <Box className="text-primary medium" mb={1}>
                                {study_cspa.title}
                            </Box>
                            <Box className="text-primary medium" mb={3}>
                                {render_edit}
                            </Box>
                        </Box>
                    );
                }
            });
        }
        return ret;
    }

    renderLocation() {
        const location = helpers.getStudyPersonLocation(this.state.study_person, this.state.study);

        return (
            <Flex mt={2} sx={{ width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <Box className={SpDataLabelClassName}>Session Location</Box>
                    <Box>
                        {this.state.editLocation == true ? (
                            <Flex>
                                <Box>
                                    <Input
                                        autoFocus
                                        id={`${this.state.study_person.id}-location`}
                                        defaultValue={location}
                                        placeholder="Location"
                                        onKeyDown={e => {
                                            if (helpers.isKeyDownEnter(e)) {
                                                this.updateLocationXHR();
                                            }
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        variant="primary"
                                        ml={2}
                                        onClick={e => {
                                            this.updateLocationXHR();
                                        }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="secondary-gray"
                                        onClick={e => {
                                            this.setState({ editLocation: false });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Flex>
                        ) : (
                            <>
                                <Flex>
                                    <Box>
                                        <Flex>
                                            <Box>
                                                {location && (
                                                    <Box
                                                        className="ellipsis bold text-primary"
                                                        style={{ lineHeight: '32px' }}
                                                        mr={2}
                                                    >
                                                        {helpers.isValidUrl(location) ? (
                                                            <a target="_blank" href={location} class="muted">
                                                                {location}
                                                            </a>
                                                        ) : (
                                                            <span>{location}</span>
                                                        )}
                                                    </Box>
                                                )}
                                            </Box>
                                            {!this.state.study.location_type && (
                                                <Box flexShrink={0}>
                                                    <Button
                                                        fontSize={1}
                                                        variant={'link'}
                                                        onClick={() => {
                                                            this.setState({ editLocation: true });
                                                        }}
                                                        ml={1}
                                                    >
                                                        {location ? (
                                                            <>
                                                                <FiEdit /> <span className={'vertical-line'}>|</span>
                                                            </>
                                                        ) : (
                                                            '+ Add Location'
                                                        )}
                                                    </Button>
                                                </Box>
                                            )}
                                        </Flex>
                                    </Box>
                                    <Box sx={{ flexShrink: 0 }} ml={2}>
                                        {location && (
                                            <>
                                                <Button
                                                    variant="link"
                                                    onClick={() => {
                                                        helpers.copyToClipboard(location);
                                                        this.makeToast('info', 'Copied');
                                                    }}
                                                >
                                                    <FiCopy />
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Flex>

                                {this.state.study.location_type && (
                                    <Box>
                                        <small className="text-secondary">
                                            Edit or delete this "global" location in:{' '}
                                            <Link to={`/studies/${this.state.study.id}/scheduling`} className="muted">
                                                Scheduling > Calendar Invitation > Location
                                            </Link>
                                        </small>
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </Flex>
        );
    }

    renderTagManager() {
        const existingTags = this.state.tagManager.existingTags ? this.state.tagManager.existingTags : [];

        // console.log('existingTags', existingTags);

        const ALL_TAGS_jsx_checked = [];
        const ALL_TAGS_jsx_unchecked = [];
        this.state.all_tags.forEach(t => {
            if (
                !this.state.tagManagerFilterKeyword ||
                (this.state.tagManagerFilterKeyword && t.title.includes(this.state.tagManagerFilterKeyword) !== false)
            ) {
                // unchecked
                if (existingTags.indexOf(t.id) == -1) {
                    ALL_TAGS_jsx_unchecked.push(
                        <div key={t.id}>
                            <label>
                                <input
                                    key={t.id}
                                    type="Checkbox"
                                    className="highlight-tag"
                                    onChange={this.updateTags}
                                    value={t.id}
                                    checked={false}
                                />{' '}
                                <div style={{ display: 'inline-block', verticalAlign: 'top', margin: '-2px 0 0 4px' }}>
                                    {t.title}
                                </div>
                            </label>
                        </div>
                    );
                } else {
                    ALL_TAGS_jsx_checked.push(
                        <div key={t.id}>
                            <label key={t.id}>
                                <input
                                    key={t.id}
                                    type="Checkbox"
                                    className="highlight-tag"
                                    onChange={this.updateTags}
                                    value={t.id}
                                    checked
                                />{' '}
                                <div style={{ display: 'inline-block', verticalAlign: 'top', margin: '-2px 0 0 2px' }}>
                                    {t.title}
                                </div>
                            </label>
                        </div>
                    );
                }
            }
        });

        let ALL_TAGS_jsx = ALL_TAGS_jsx_checked.concat(ALL_TAGS_jsx_unchecked);
        if (ALL_TAGS_jsx.length == 0) {
            if (this.state.tagManagerFilterKeyword) {
                ALL_TAGS_jsx = "Hit 'Enter' to create.";
            } else {
                ALL_TAGS_jsx = 'Type your first tag!';
            }
        }

        return (
            <div
                id="tag-manager"
                ref={this.setWrapperRef}
                className="tagHover"
                style={{
                    top:
                        this.state.tagManager.selectedTextPosition.top -
                        this.state.tagManager.editorWrapperPosition.top +
                        72,
                    left:
                        this.state.tagManager.selectedTextPosition.left -
                        this.state.tagManager.editorWrapperPosition.left +
                        48
                }}
            >
                <NiceWrapper padding="2px 8px" style={{ backgroundColor: 'white' }}>
                    <form onSubmit={this.submitTagManagerNewTag}>
                        <Input
                            type="text"
                            id="new_tag"
                            placeholder="Search"
                            my={2}
                            value={this.state.tagManagerFilterKeyword}
                            onChange={this.tagManagerFilterList}
                            /* autoFocus */ autoComplete="off"
                        />
                    </form>
                    <div style={{ maxHeight: '200px', overflow: 'scroll' }}>{ALL_TAGS_jsx}</div>
                </NiceWrapper>
            </div>
        );
    }

    generateSchedulingLinks() {
        Object.keys(this.state.schedulingLinks).forEach(type => {
            helpers.getSchedulingLink(this.state.study, this.state.study_person, type).then(link => {
                const { schedulingLinks } = this.state;
                schedulingLinks[type] = link;
                this.setState({ schedulingLinks });
            });
        });
    }

    makeToast(type, content) {
        const { toastManager } = this.props;

        toastManager.add(content, {
            appearance: type,
            autoDismiss: true
        });
    }

    getScreener(study_screener_id) {
        if (study_screener_id) {
            //console.log('getScreenerTitle', this.state.study.screeners, study_screener_id)
            const found_screener = this.state.study.screeners.find(s => s.id == study_screener_id);
            if (found_screener) {
                return found_screener;
            }
        }
    }

    getScreenerTitle(study_screener_id) {
        if (study_screener_id) {
            //console.log('getScreenerTitle', this.state.study.screeners, study_screener_id)
            const found_screener = this.state.study.screeners.find(s => s.id == study_screener_id);
            if (found_screener) {
                return found_screener.title;
            }
        } else {
            return 'Default';
        }
    }

    getDataForScreener(study_screener_id) {
        if (study_screener_id) {
            const found_screener = this.state.study.screeners.find(s => s.id == study_screener_id);
            if (found_screener) {
                return found_screener.form;
            }
        } else {
            return this.state.study.screener;
        }
    }

    onEditScreenerSubmit(screener_id, screener_data_id, formAnswers, isQualified) {
        const { toastManager } = this.props;

        this.setState({ screener_edit_is_submitting: true });

        services
            .editScreenerAnswers(
                this.state.study_person.study_id,
                screener_id,
                screener_data_id,
                formAnswers,
                isQualified
            )
            .then(screenerData => {
                console.log(screenerData);
                //this.state.study_person.screener_answers
                this.setState({
                    screener_edit_is_submitting: false,
                    editAnswersScreenerId: null
                });

                const { study_person } = this.state;
                const sd_found = study_person.screener_answers.find(sa => (sa.id = screenerData.id));
                const index = study_person.screener_answers.indexOf(sd_found);
                study_person.screener_answers[index] = screenerData;

                this.parentCallback('save', study_person);

                toastManager.add('Screener answers updated', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                this.setState({ screener_edit_is_submitting: false });

                helpers.trackError(error);
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onSearchIp(ip) {
        const { toastManager } = this.props;

        //console.log('seraching ip!', ip)
        if (this.props.onSearchKeywordChange) {
            //console.log('calling parent')
            this.props.onSearchKeywordChange({
                target: {
                    value: 'ip:' + ip
                }
            });

            toastManager.add('Quick Search updated with IP address', {
                appearance: 'success',
                autoDismiss: true
            });

            analytics.track('study-click_duplicate_ip');
        }
    }

    sessionSpotDropdownOnChange(spot, event) {
        console.log('sessionSpotDropdownOnChange', spot);
        if (event) {
            event.stopPropagation();
        }

        this.updateSpotXHR(spot);
        this.setState({ showSchedulingDropdown: false });
    }

    renderBackupPanelistParticipationWarning() {
        try {
            if (
                !this.state.study.account.settings.panelist_fatigue_scheduling &&
                this.state.study_person.recent_session == true
            ) {
                return (
                    <NotificationCard
                        type="warn"
                        icon={<FiCalendar />}
                        title={`${helpers.personGetCustomValue(
                            this.state.study_person.person,
                            COL_FIRST_NAME
                        )} has another session within 30 days.`}
                        style={{ marginBottom: '16px' }}
                        extra={
                            <Button
                                onClick={() => this.setState({ sp_tab_selected: 'pastpart' })}
                                variant="transparent"
                            >
                                <FiEye /> View Participation
                            </Button>
                        }
                    />
                );
            }
        } catch (e) {
            helpers.trackError(e);
        }
    }

    xhrSpFatigueToggle() {
        const new_fatigue_setting = !this.state.study_person.study_fatigue_exclude;
        const { toastManager } = this.props;
        service_studies
            .updateStudyPersonFatigue(this.state.study_person.study_id, this.state.study_person.id, new_fatigue_setting)
            .then(sp => {
                toastManager.add('Fatigue turned off', {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.getUpdatedStudyPerson();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    alreadyScheduledViaCalendly() {
        if (this.state.study_person.calendly_invitee_uuid) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Get payment method label. Used in the payment phrase.
     */
    getPaymentMethodLabel() {
        if (this.state.study_person.incentive_receipt === INCENTIVE_PAYMENT_METHOD.MARK_AS_PAID) {
            return INCENTIVE_PAYMENT_METHOD_LABEL.MARK_AS_PAID;
        }

        return null;
    }

    /**
     * Get the payment date label. Used in the payment phrase.
     */
    getPaymentDateLabel() {
        if (this.state.study_person.incentive_sent_at)
            return moment(this.state.study_person.incentive_sent_at).format('MMM D, YYYY');

        return null;
    }

    /**
     * Get the payment phrase for the study person
     */
    getPaymentPhrase() {
        const methodLabel = this.getPaymentMethodLabel();
        const dateLabel = this.getPaymentDateLabel();

        switch (true) {
            case !!dateLabel && !!methodLabel:
                return `Paid on ${dateLabel}, using ${methodLabel}.`;
            case !!dateLabel:
                return `Paid on ${dateLabel}.`;
            case !!methodLabel:
                return `Paid using ${methodLabel}.`;
            default:
                return 'Paid.';
        }
    }

    /**
     * Pay the participant
     *
     * @param {Object} props
     * @param {number} props.amount The payment amount
     * @param {string} props.paymentType The payment type
     * @param {string} props.paymentDate The payment date
     */
    async payParticipant({ amount, paymentType, paymentDate }) {
        const { toastManager } = this.props;

        const peopleIds = [this.state.study_person.person_id];

        try {
            await service_studies.bulkPayAccept(this.state.study.id, peopleIds, paymentType, amount, paymentDate);
            toastManager.add('Payment process initiated', {
                appearance: 'success',
                autoDismiss: true
            });
            this.setState({ isPaymentModalOpen: false });
            this.getParticipantXHR();
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        }
    }

    render() {
        const paymentPhrase = this.getPaymentPhrase();
        const studyPerson = this.state.study_person;
        const isPaymentEditable =
            studyPerson.incentive_status === 'paid' && // the status of the payment is paid
            studyPerson.incentive_currency !== CURRENCY_POINTS && // the payment is not in points
            studyPerson.incentive_receipt === INCENTIVE_PAYMENT_METHOD.MARK_AS_PAID; // the payment is marked as paid (manual)

        const hasEmailingFeatureEnabled = helpers.hasFeatureEnabled(this.props.auth.account, PAID_FEATURE.EMAILING);
        const hasSmsFeatureEnabled = helpers.hasFeatureEnabled(this.props.auth.account, PAID_FEATURE.SMS_MASS);
        const msgOptions = [
            {
                title: `Send Email`,
                onClick: this.onClickSendMsg(MSG_TYPE_EMAIL),
                disabled: !hasEmailingFeatureEnabled
            },
            {
                title: 'Send SMS',
                onClick: this.onClickSendMsg(MSG_TYPE_SMS),
                disabled: !hasSmsFeatureEnabled,
                suffix: !hasSmsFeatureEnabled ? <UpgradeButton type="sms-mass" /> : undefined
            }
        ];

        const sessionCancelSvgStyle = {
            fontSize: '13px',
            strokeWidth: '3px',
            margin: '3px 2px 0 0',
            verticalAlign: 'top'
        };

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        const contentState = this.state.editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (
                contentState
                    .getBlockMap()
                    .first()
                    .getType() !== 'unstyled'
            ) {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        if (!this.state.study_person) return <br />;

        if (this.state.redirect) {
            return (
                <Redirect to={`/studies/${this.state.study.id}/participants/${this.state.study_person.person_id}`} />
            );
        }

        const email_key = helpers.personGetCustomDataKey(this.state.study_person.person, COL_EMAIL);
        const email = helpers.personGetCustomValue(this.state.study_person.person, COL_EMAIL);

        const first_name_key = helpers.personGetCustomDataKey(this.state.study_person.person, COL_FIRST_NAME);
        const first_name = helpers.personGetCustomValue(this.state.study_person.person, COL_FIRST_NAME);

        const last_name_key = helpers.personGetCustomDataKey(this.state.study_person.person, COL_LAST_NAME);
        const last_name = helpers.personGetCustomValue(this.state.study_person.person, COL_LAST_NAME);

        const phone_number_key = helpers.personGetCustomDataKey(this.state.study_person.person, COL_PHONE_NUMBER);
        const phone_number = helpers.personGetCustomValue(this.state.study_person.person, COL_PHONE_NUMBER);
        let bad_phone_number_error = null;
        if (
            hasSmsFeatureEnabled &&
            phone_number &&
            !helpersStudy.isPhoneNumberValid(this.state.study, phone_number) &&
            this.state.study_person.spot
        ) {
            bad_phone_number_error = (
                <Box className="error fs-13">
                    <FiAlertCircle style={{ margin: '4px 4px 0 0' }} className="va-top error" />
                    Bad phone number format for SMS and Voice reminders
                </Box>
            );
        }

        let dupe_answers_error = null;
        if (this.state.study_person.screener_answers) {
            this.state.study_person.screener_answers.forEach(sa => {
                let sa_screener = this.getScreener(sa.study_screener_id);
                if (helpersStudy.shouldShowDupeIpWarning(sa_screener, sa)) {
                    dupe_answers_error = (
                        <Box className="" mt={3}>
                            <Box className="error medium">
                                <FiAlertCircle
                                    style={{ margin: '6px 6px 0 0', strokeWidth: '2.5' }}
                                    className="va-top error fs-12"
                                />
                                Duplicate IP (
                                <span className="pointer red underlined" onClick={() => this.onSearchIp(sa.ip)}>
                                    {sa.ip}
                                </span>
                                )
                            </Box>
                            <Box className="text-primary fs-12">
                                This IP was used multiple times for "{this.getScreenerTitle(sa.study_screener_id)}"{' '}
                                <a href="#" data-beacon-article="6321057ac713d51da3ede31e" className="help-question">
                                    ?
                                </a>
                            </Box>
                        </Box>
                    );
                }
            });
        }

        const communication_from_to = [];

        const personActivity = [];
        if (this.state.study_person.emails_sent && this.state.study_person.emails_sent.length) {
            this.state.study_person.emails_sent.forEach(es => {
                personActivity.push({ type: 'Email Sent', date: es.created_at });
                communication_from_to.push({ type: 'sent', object: es });
                //console.log(es);
            });
        }

        if (this.state.study_person.emails_received && this.state.study_person.emails_received.length) {
            this.state.study_person.emails_received.forEach(er => {
                personActivity.push({ type: 'Email Received', date: er.created_at });
                communication_from_to.push({ type: 'received', object: er });
            });
        }

        if (this.state.study_person.sms_sent && this.state.study_person.sms_sent.length) {
            this.state.study_person.sms_sent.forEach(ss => {
                personActivity.push({ type: 'SMS Sent', date: ss.created_at });
                communication_from_to.push({ type: 'sms_sent', object: ss });
            });
        }

        if (this.state.study_person.sms_received && this.state.study_person.sms_received.length) {
            this.state.study_person.sms_received.forEach(ss => {
                personActivity.push({ type: 'SMS Received', date: ss.created_at });
                communication_from_to.push({ type: 'sms_received', object: ss });
            });
        }

        if (this.state.study_person.reminders && this.state.study_person.reminders.length) {
            this.state.study_person.reminders.forEach(r => {
                // change "created at" to "send at" because we sort by this
                r.created_at = r.send_at;
                communication_from_to.push({ type: 'reminder', object: r });
            });
        }

        communication_from_to.sort((a, b) => (a.object.created_at > b.object.created_at ? 1 : -1));

        let render_emails_from_to = [];
        communication_from_to.forEach(comm => {
            let mch_class;
            if (comm.object && comm.object.message_parsed) {
                mch_class = helpers.containsHtml(comm.object.message_parsed) ? 'message-contains-html' : '';
            }

            if (comm.type == 'sent') {
                let status_info;
                switch (comm.object.status) {
                    case 'failed':
                        status_info = (
                            <>
                                Failed to send <FiAlertCircle className="email-info-style red" />
                            </>
                        );
                        break;
                    case 'ready':
                        status_info = (
                            <>
                                In queue to send <FiWatch className="email-info-style yellow" />
                            </>
                        );
                        break;
                    case 'scheduled':
                        status_info = (
                            <>
                                Scheduled <FiWatch className="email-info-style yellow" />
                            </>
                        );
                        break;
                    case 'sent':
                        status_info = (
                            <>
                                {moment.utc(comm.object.created_at).fromNow()} <FiInfo className="email-info-style" />
                            </>
                        );
                        break;
                    default:
                        status_info = comm.object.status;
                }
                render_emails_from_to.push(
                    <EmailToParticipant>
                        <div
                            className={`email-content ${mch_class} ${comm.object.status == 'failed' ? 'bg-error' : ''}`}
                        >
                            {comm.object.template_type === EMAIL_TEMPLATE_TYPE.HTML ? (
                                <Box
                                    as="iframe"
                                    srcDoc={comm.object.message_parsed}
                                    width="100%"
                                    frameBorder={0}
                                    sandbox="allow-scripts allow-same-origin"
                                />
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{ __html: myxss.process(comm.object.message_parsed) }}
                                ></div>
                            )}

                            {comm.object.attachments.length > 0 && (
                                <div className="email-attachments">
                                    <Label className="quiet fs-12">Attachments</Label>
                                    {comm.object.attachments.map(attachment => {
                                        if (attachment.filepath && attachment.filename) {
                                            return (
                                                <div className="ellipsis">
                                                    &bull;{' '}
                                                    <a href={attachment.filepath} target="_blank">
                                                        {attachment.filename}
                                                    </a>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="email-avatar-researcher">
                            {comm.object.user.avatar ? (
                                <img src={comm.object.user.avatar} alt="Email Avatar" />
                            ) : (
                                helpers.getUserInitials(comm.object.user)
                            )}
                        </div>
                        <div className={`email-date ${comm.object.status == 'failed' ? 'red' : ''}`}>
                            <span data-tip data-for={`tooltip-sp-email-to-${comm.object.id}`}>
                                {status_info}
                            </span>
                        </div>
                        <ReactTooltip
                            id={`tooltip-sp-email-to-${comm.object.id}`}
                            effect="solid"
                            place="left"
                            className="react-tooltip-hover-stay"
                            delayHide={500}
                        >
                            <div className="email-tooltip">
                                <div className="label">From</div>
                                {comm.object.user.email}
                                <br />
                                <br />
                                <div className="label">To</div>
                                {comm.object.recipients_to}
                                <br />
                                <br />
                                <div className="label">Subject</div>
                                {comm.object.subject}
                                {comm.object.error || comm.object.status == 'failed' ? (
                                    <div className="red">
                                        <br />
                                        <div className="label red">Failure</div>
                                        {comm.object.error}
                                        <br />
                                        <br />
                                        <Button
                                            variant="primary"
                                            onClick={() => this.handleResendEmail(comm.object.id)}
                                            disabled={this.state.reSendingEmail}
                                        >
                                            <FiSend /> Re-send email
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </ReactTooltip>
                    </EmailToParticipant>
                );
            } else if (comm.type == 'received') {
                render_emails_from_to.push(
                    <EmailFromParticipant>
                        <div className="email-avatar-participant">
                            {helpers.getPersonInitials(this.state.study_person.person)}
                        </div>
                        <div className={`email-content ${mch_class}`}>
                            <div dangerouslySetInnerHTML={{ __html: myxss.process(comm.object.text_body) }}></div>

                            {comm.object.attachments.length > 0 && (
                                <div className="email-attachments">
                                    <Label className="quiet fs-12">Attachments</Label>
                                    {comm.object.attachments.map(attachment => {
                                        if (attachment.filepath && attachment.filename) {
                                            return (
                                                <div className="ellipsis">
                                                    &bull;{' '}
                                                    <a href={attachment.filepath} target="_blank">
                                                        {attachment.filename}
                                                    </a>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="email-date">
                            <span data-tip data-for={`tooltip-sp-email-from-${comm.object.id}`}>
                                {moment.utc(comm.object.created_at).fromNow()} <FiInfo className="email-info-style" />
                            </span>
                        </div>
                        <ReactTooltip id={`tooltip-sp-email-from-${comm.object.id}`} effect="solid" place="right">
                            <div className="email-tooltip">
                                <div className="label">From</div>
                                {comm.object.from}
                                <br />
                                <br />
                                {/* <div className='label'>To</div>
                                {comm.object.from}
                                <br/><br/> */}
                                <div className="label">Subject</div>
                                {comm.object.subject}
                            </div>
                        </ReactTooltip>
                    </EmailFromParticipant>
                );
            } else if (comm.type == 'reminder') {
                let reminder_msg;
                if (moment.utc(comm.object.send_at).isAfter()) {
                    reminder_msg = `Session reminder will be sent on ${moment
                        .utc(comm.object.send_at)
                        .tz(this.state.study.timezone)
                        .format(MOMENT_HUMAN_DATE)}`;
                } else {
                    reminder_msg = `Session reminder was sent on ${moment
                        .utc(comm.object.send_at)
                        .tz(this.state.study.timezone)
                        .format(MOMENT_HUMAN_DATE)}`;
                }

                render_emails_from_to.push(
                    <div style={{ margin: '0 auto', textAlign: 'center', position: 'relative' }}>
                        <div className="email-reminder-line"></div>
                        <div className="email-reminder">
                            <FiClock /> {reminder_msg}
                        </div>
                    </div>
                );
            } else if (comm.type == 'sms_sent') {
                let status_info;
                switch (comm.object.status) {
                    case 'failed':
                        status_info = (
                            <>
                                Failed to send <FiAlertCircle className="email-info-style red" />
                            </>
                        );
                        break;
                    case 'ready':
                        status_info = (
                            <>
                                In queue to send <FiWatch className="email-info-style yellow" />
                            </>
                        );
                        break;
                    case 'sent':
                        status_info = (
                            <>
                                {moment.utc(comm.object.created_at).fromNow()} <FiInfo className="email-info-style" />
                            </>
                        );
                        break;
                    default:
                        status_info = comm.object.status;
                }

                render_emails_from_to.push(
                    <EmailToParticipant>
                        <div
                            className={`sms-content sms-background ${comm.object.status == 'failed' ? 'bg-error' : ''}`}
                        >
                            <div className="sms-content-inside">{comm.object.message_parsed}</div>
                            <span className="sms-label fs-12">
                                <FiSmartphone className="sms-icon" /> Sent as SMS
                            </span>
                        </div>
                        <div className="email-avatar-researcher">
                            {comm.object.user ? (
                                comm.object.user.avatar ? (
                                    <img src={comm.object.user.avatar} alt="Email Avatar" />
                                ) : (
                                    helpers.getUserInitials(comm.object.user)
                                )
                            ) : (
                                <FiClock style={{ fontSize: '19px' }} />
                            )}
                        </div>
                        <div className={`email-date ${comm.object.status == 'failed' ? 'red' : ''}`}>
                            <span data-tip data-for={`tooltip-sp-email-to-${comm.object.id}`}>
                                {status_info}
                            </span>
                        </div>
                        <ReactTooltip id={`tooltip-sp-email-to-${comm.object.id}`} effect="solid" place="left">
                            <div className="email-tooltip">
                                {comm.object.user ? (
                                    <>
                                        <div className="label">From</div>
                                        {comm.object.user.name}
                                        <br />
                                        <br />
                                        <div className="label">To</div>
                                        {comm.object.recipients_to}
                                        {comm.object.error || comm.object.status == 'failed' ? (
                                            <div className="red">
                                                <br />
                                                <div className="label red">Failure</div>
                                                {comm.object.error}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    'Automated session reminder'
                                )}
                            </div>
                        </ReactTooltip>
                    </EmailToParticipant>
                );
            } else if (comm.type == 'sms_received') {
                render_emails_from_to.push(
                    <EmailFromParticipant>
                        <div className="email-avatar-participant">
                            {helpers.getPersonInitials(this.state.study_person.person)}
                        </div>
                        <div className="sms-content sms-background">
                            <div className="sms-content-inside">{comm.object.message}</div>
                            <span className="sms-label fs-12">
                                <FiSmartphone className="sms-icon" /> Received as SMS
                            </span>
                        </div>
                        <div className="email-date">
                            <span data-tip data-for={`tooltip-sp-email-from-${comm.object.id}`}>
                                {moment.utc(comm.object.created_at).fromNow()} <FiInfo className="email-info-style" />
                            </span>
                        </div>
                        <ReactTooltip id={`tooltip-sp-email-from-${comm.object.id}`} effect="solid" place="right">
                            <div className="email-tooltip">
                                <div className="label">From</div>
                                {comm.object.from_number}
                            </div>
                        </ReactTooltip>
                    </EmailFromParticipant>
                );
            }
        });

        if (!render_emails_from_to.length) {
            render_emails_from_to.push(
                <Box sx={{ textAlign: 'center' }}>
                    <Box className="text-secondary-dark bold" sx={{ fontSize: '20px' }} mt={4}>
                        No messages.
                    </Box>
                    <Box className="text-secondary">Emails, SMSs, reminders will appear here.</Box>
                </Box>
            );
        }

        // paid
        // @todo

        // session scheduled
        // @todo

        if (this.state.study_person.created_at) {
            personActivity.push({
                type: `Added to study`,
                date: this.state.study_person.created_at
            });
        }

        // screener answered
        if (this.state.study_person.screener_answers) {
            //console.log('screener_answers', this.state.study_person.screener_answers)
            this.state.study_person.screener_answers.forEach(sa => {
                personActivity.push({
                    type: `Screener Answered (${this.getScreenerTitle(sa.study_screener_id)})`,
                    date: sa.created_at
                });
            });
        }

        personActivity.sort((a, b) => (a.date > b.date ? 1 : -1));

        const personActivityRender = [];
        personActivity.forEach(a => {
            personActivityRender.push(
                <>
                    <Box className="text-primary medium">{a.type}</Box>
                    <Box className="text-secondary-dark fs-12" mb={3}>
                        {moment
                            .utc(a.date)
                            .tz(this.state.study.timezone)
                            .format(MOMENT_HUMAN_DATE)}
                    </Box>
                </>
            );
        });

        // alpha sort participant status
        let participant_status_jsx = [];
        let participant_status_arr = Object.keys(SP_STATUS_KEY_TITLE).map(key => {
            return {
                id: key,
                label: SP_STATUS_KEY_TITLE[key]
            };
        });
        helpers.sortAlpha(participant_status_arr, 'label');

        participant_status_jsx = participant_status_arr.map(val => {
            return <option value={val.id}>{val.label}</option>;
        });

        const panelistEligibilityMore = (
            <Box>
                <Box sx={{ marginTop: '2px' }}>
                    <a
                        href="#"
                        className="fs-12 medium"
                        onClick={() => {
                            this.setState({ sp_tab_selected: 'pastpart' });
                        }}
                    >
                        View past participation
                    </a>
                </Box>
                <hr style={{ margin: '12px 0 10px' }} />

                <a href={`/settings#panelist-fatigue`} target="_blank">
                    <Button variant="transparent">
                        <FiSettings /> Fatigue settings
                    </Button>
                </a>
                <Button // className='pointer black bold underlined'
                    variant="transparent"
                    ml={'24px'}
                    onClick={() => {
                        if (
                            confirm(
                                `Are you sure you want to ${
                                    this.state.study_person.study_fatigue_exclude ? 'restore' : 'circumvent'
                                } this fatigue setting?`
                            )
                        ) {
                            this.xhrSpFatigueToggle();
                        }
                    }}
                >
                    {this.state.study_person.study_fatigue_exclude == true ? (
                        <>
                            <FiUserX /> Restore fatigue
                        </>
                    ) : (
                        <>
                            <FiUserCheck /> Circumvent fatigue
                        </>
                    )}
                </Button>
            </Box>
        );

        return (
            <>
                {this.state.study_person && this.state.study_person.id && (
                    <Flex style={{ overflow: 'hidden', height: '100%' }}>
                        <Box
                            width="660px"
                            style={{
                                padding: '0',
                                background: '#fff',
                                overflow: 'scroll',
                                borderRadius: '8px 0 0 8px'
                            }}
                        >
                            <Box sx={{ padding: '0 32px' }}>
                                {helpersStudy.isSpLocked(this.state.study_person) && (
                                    <Box className="viewed-by ellipsis text-primary bold">
                                        {/*<span style={{margin: '2px 4px 0 0', display: 'inline-block'}}><FiEye style={{margin: '3px 4px 0 0', verticalAlign: 'top'}}/></span>*/}
                                        {this.state.study_person.users_viewing.id == this.props.auth.user.id ? (
                                            'You are viewing'
                                        ) : (
                                            <>
                                                <span
                                                    style={{
                                                        margin: '2px 4px 0 0',
                                                        display: 'inline-block',
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    {this.state.study_person.users_viewing.name}
                                                </span>
                                                <span style={{ margin: '2px 0 0 0', display: 'inline-block' }}>
                                                    is viewing
                                                </span>
                                            </>
                                        )}
                                    </Box>
                                )}
                                <Box mt={3}>
                                    <Flex sx={{ gap: '8px' }}>
                                        {helpers.renderBlocklistLabel(this.state.study_person.person)}
                                        {helpers.personIsOptedOut(this.state.study_person.person) && <OptOutBadge />}
                                    </Flex>
                                    <NotesTitle>
                                        {!first_name && !!first_name_key.pii_masked && !this.state.unmaskedFirstName && (
                                            <ParticipantMaskedValue
                                                role={this.props.auth.account_role.type}
                                                participantId={this.state.study_person.person_id}
                                                setValue={value => {
                                                    const study_person = { ...this.state.study_person };
                                                    study_person.person.custom_data_values2[first_name_key.id] = value;
                                                    this.setState({ study_person, unmaskedFirstName: true });
                                                }}
                                                keyId={first_name_key.id}
                                                label={'first name'}
                                                style={{ marginTop: '10px', marginRight: '5px' }}
                                            />
                                        )}
                                        {(!!first_name || !!last_name) && (
                                            <Link
                                                className="muted"
                                                to={`/participants/${this.state.study_person.person_id}`}
                                            >
                                                {first_name} {last_name}
                                            </Link>
                                        )}
                                        {!last_name && !!last_name_key.pii_masked && !this.state.unmaskedLastName && (
                                            <ParticipantMaskedValue
                                                role={this.props.auth.account_role.type}
                                                participantId={this.state.study_person.person_id}
                                                setValue={value => {
                                                    const study_person = { ...this.state.study_person };
                                                    study_person.person.custom_data_values2[last_name_key.id] = value;
                                                    this.setState({ study_person, unmaskedLastName: true });
                                                }}
                                                keyId={last_name_key.id}
                                                label={'last name'}
                                                style={{ marginTop: '10px' }}
                                            />
                                        )}
                                        {!first_name &&
                                            (!first_name_key.pii_masked || this.state.unmaskedFirstName) &&
                                            !last_name &&
                                            (!last_name_key.pii_masked || this.state.unmaskedLastName) &&
                                            'Name missing'}
                                    </NotesTitle>
                                </Box>
                                <div style={{ marginTop: '3px' }}>
                                    {email ? (
                                        <a href={`mailto:${email}`} className="text-primary">
                                            {email}
                                        </a>
                                    ) : email_key.pii_masked && !this.state.unmaskedEmail ? (
                                        <ParticipantMaskedValue
                                            role={this.props.auth.account_role.type}
                                            participantId={this.state.study_person.person_id}
                                            setValue={value => {
                                                const study_person = { ...this.state.study_person };
                                                study_person.person.custom_data_values2[email_key.id] = value;
                                                this.setState({ study_person, unmaskedEmail: true });
                                            }}
                                            keyId={email_key.id}
                                            label={'email'}
                                        />
                                    ) : (
                                        <span className="text-secondary-dark">No email</span>
                                    )}
                                </div>
                                <div style={{ marginTop: '3px' }}>
                                    {phone_number ? (
                                        phone_number
                                    ) : phone_number_key.pii_masked && !this.state.unmaskedPhone ? (
                                        <ParticipantMaskedValue
                                            role={this.props.auth.account_role.type}
                                            participantId={this.state.study_person.person_id}
                                            setValue={value => {
                                                const study_person = { ...this.state.study_person };
                                                study_person.person.custom_data_values2[phone_number_key.id] = value;
                                                this.setState({ study_person, unmaskedPhone: true });
                                            }}
                                            keyId={phone_number_key.id}
                                            label={'phone number'}
                                        />
                                    ) : (
                                        <span className="text-secondary-dark">No phone</span>
                                    )}
                                    {bad_phone_number_error}
                                </div>
                                {dupe_answers_error}
                            </Box>

                            <Flex mt={'24px'} mb="24px" px={'32px'} className="subnav-3">
                                <Box
                                    className={`link hover-black ${
                                        this.state.sp_tab_selected == 'recruit' ? '' : 'inactive text-secondary-dark'
                                    }`}
                                    onClick={() => this.setState({ sp_tab_selected: 'recruit' })}
                                >
                                    <FiCalendar /> Recruit
                                </Box>
                                <Box
                                    className={`link hover-black ${
                                        this.state.sp_tab_selected == 'surveys' ? '' : 'inactive text-secondary-dark'
                                    }`}
                                    onClick={() => this.setState({ sp_tab_selected: 'surveys' })}
                                >
                                    <FiList /> Surveys
                                </Box>
                                <Box
                                    className={`link hover-black ${
                                        this.state.sp_tab_selected === 'files' ? '' : 'inactive text-secondary-dark'
                                    }`}
                                    onClick={() => this.setState({ sp_tab_selected: 'files' })}
                                >
                                    <FiFileText /> Files
                                </Box>
                                <Box
                                    className={`link hover-black ${
                                        this.state.sp_tab_selected == 'profile' ? '' : 'inactive text-secondary-dark'
                                    }`}
                                    onClick={() => this.setState({ sp_tab_selected: 'profile' })}
                                >
                                    <FiUser /> Profile
                                </Box>
                                <Box
                                    className={`link hover-black ${
                                        this.state.sp_tab_selected == 'pastpart' ? '' : 'inactive text-secondary-dark'
                                    }`}
                                    onClick={() => this.setState({ sp_tab_selected: 'pastpart' })}
                                >
                                    <FiRotateCcw /> Past Part
                                </Box>
                                <Box
                                    className={`link hover-black ${
                                        this.state.sp_tab_selected == 'activity' ? '' : 'inactive text-secondary-dark'
                                    }`}
                                    onClick={() => this.setState({ sp_tab_selected: 'activity' })}
                                >
                                    <FiActivity /> Activity
                                </Box>
                            </Flex>

                            {this.state.sp_tab_selected == 'recruit' && (
                                <Box mt={3} sx={{ padding: '0 32px' }}>
                                    {helpers.renderStudyEligibility(
                                        this.state.study_person.person,
                                        panelistEligibilityMore,
                                        this.state.study_person.study_fatigue_exclude
                                    )}

                                    {this.renderBackupPanelistParticipationWarning()}

                                    <IncentiveProgress
                                        currency={this.state.study_person.incentive_currency}
                                        paid={this.state.study_person.person.annual_incentive_amount}
                                        limit={
                                            this.state.study_person.person.account.settings.panelist_fatigue_incentive
                                        }
                                    />

                                    <Box className={`${SpDataLabelClassName} `}>Scheduled Session</Box>

                                    {this.state.study_person.spot ? (
                                        <div>
                                            <Flex sx={{ gridGap: 24, justifyContent: 'space-between' }}>
                                                <Box>
                                                    <Flex>
                                                        <Box
                                                            className="text-primary bold"
                                                            style={{ lineHeight: '32px' }}
                                                            mr={2}
                                                        >
                                                            {moment
                                                                .utc(this.state.study_person.spot)
                                                                .tz(
                                                                    this.state.study_person.timezone ||
                                                                        this.state.study.timezone
                                                                )
                                                                .format(MOMENT_HUMAN_DATE)}
                                                        </Box>
                                                        <Box>
                                                            {this.alreadyScheduledViaCalendly() ? (
                                                                <a
                                                                    href={this.state.schedulingLinks.reschedule}
                                                                    target="_blank"
                                                                >
                                                                    <Button variant="link">
                                                                        <FiEdit />
                                                                    </Button>
                                                                </a>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        variant="link"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                showSchedulingDropdown: true
                                                                            })
                                                                        }
                                                                    >
                                                                        <FiEdit />
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Flex>
                                                </Box>

                                                <Box>
                                                    {this.state.study.scheduling_type == 'panelfox' && (
                                                        <>
                                                            <TimezoneDropdown
                                                                timezones={this.state.timezones}
                                                                timezoneSelected={this.state.study_person.timezone}
                                                                displayStyle={'editIcon'}
                                                                onChange={tzVal => {
                                                                    this.updateTimezoneXHR({
                                                                        target: { name: 'timezone', value: tzVal }
                                                                    });
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </Box>
                                            </Flex>

                                            <Box sx={{ marginTop: '-4px' }}>
                                                {this.alreadyScheduledViaCalendly() ? (
                                                    <a href={this.state.schedulingLinks.cancel} target="_blank">
                                                        <Button variant="link" className="red fs-12 bold">
                                                            <FiTrash2 style={sessionCancelSvgStyle} /> Cancel Session
                                                        </Button>
                                                    </a>
                                                ) : (
                                                    <Button
                                                        variant="link"
                                                        className="red fs-12 bold"
                                                        onClick={() => {
                                                            if (confirm('Are you sure?')) {
                                                                this.cancelSpotXHR();
                                                            }
                                                        }}
                                                    >
                                                        <FiTrash2 style={sessionCancelSvgStyle} /> Cancel Session
                                                    </Button>
                                                )}
                                            </Box>
                                        </div>
                                    ) : (
                                        <div>
                                            {this.state.schedulingLinks.create && (
                                                <>
                                                    {this.state.study.scheduling_type == 'calendly' ? (
                                                        <>
                                                            <a href={this.state.schedulingLinks.create} target="_blank">
                                                                <Button variant="link">
                                                                    <FiExternalLink /> Add Session via Calendly
                                                                </Button>
                                                            </a>
                                                            <br />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <Button
                                                        variant="link"
                                                        className={
                                                            this.state.study.scheduling_type == 'panelfox'
                                                                ? ''
                                                                : 'quiet'
                                                        }
                                                        onClick={() => this.setState({ showSchedulingDropdown: true })}
                                                    >
                                                        <FiPlus /> Add Session{' '}
                                                        {this.state.study.scheduling_type == 'panelfox'
                                                            ? ''
                                                            : 'Manually'}
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    )}

                                    {this.state.showSchedulingDropdown == true && (
                                        <div style={{ position: 'relative' }}>
                                            <NiceDropdown
                                                width={200}
                                                positionLeft="0px"
                                                positionTop={this.state.study_person.spot ? '-26px' : '4px'}
                                                items={this.state.study_availability_array}
                                                onChange={(spot, event) =>
                                                    this.sessionSpotDropdownOnChange(spot, event)
                                                }
                                                onClose={() => this.setState({ showSchedulingDropdown: false })}
                                                renderBottomStickyButton={
                                                    <Button
                                                        variant="secondary"
                                                        onClick={() => {
                                                            analytics.track('study-sp-session-set_custom-click');
                                                            const spot_for_datepicker = this.state.study_person.spot
                                                                ? moment.utc(this.state.study_person.spot).toDate()
                                                                : '';

                                                            this.setState({
                                                                showModalSessionDatepicker: true,
                                                                session_custom_date: spot_for_datepicker
                                                            });
                                                        }}
                                                        mr={0}
                                                    >
                                                        <FiCalendar /> Set Custom
                                                    </Button>
                                                }
                                            />
                                        </div>
                                    )}
                                    {this.renderLocation()}
                                    <Box mt={2}>
                                        <Box className={SpDataLabelClassName}>
                                            Incentive ({CURRENCY_SYMBOL[this.state.study.incentive_currency]}{' '}
                                            {this.state.study.incentive_currency})
                                        </Box>
                                        {this.state.study_person.incentive_status == 'paid' ? (
                                            <>
                                                <div className="bold" style={{ marginTop: '4px', color: '#377F21' }}>
                                                    Paid{' '}
                                                    {helpers.renderCurrencyAmount(
                                                        this.state.study_person.incentive_currency,
                                                        this.state.study_person.incentive_total_paid
                                                    )}
                                                    {isPaymentEditable && (
                                                        <Button
                                                            variant="link"
                                                            ml={2}
                                                            onClick={() =>
                                                                this.setState({ isEditPaymentModalOpen: true })
                                                            }
                                                        >
                                                            <FiEdit />
                                                        </Button>
                                                    )}
                                                </div>
                                                {paymentPhrase && (
                                                    <Box as="p" className="text-secondary-dark fs-12">
                                                        {paymentPhrase}
                                                    </Box>
                                                )}
                                            </>
                                        ) : (
                                            <Button
                                                variant="link"
                                                onClick={() => this.setState({ isPaymentModalOpen: true })}
                                                mr={2}
                                            >
                                                + Pay Incentive
                                            </Button>
                                        )}
                                    </Box>
                                    <Box mt={2}>
                                        <Box className={SpDataLabelClassName}>Participant Status</Box>

                                        {this.state.study_person.status == SP_STATUS_KEY_CANCELLED &&
                                            this.state.study_person.spot && (
                                                <Box className="label-error" mb={2}>
                                                    Click Cancel on the "Scheduled Session" to fully cancel.
                                                </Box>
                                            )}
                                        <Box sx={{ marginTop: '12px' }}>
                                            <Select
                                                onChange={this.updateParticipantStatus}
                                                value={this.state.study_person.status}
                                                style={{ maxWidth: '120px' }}
                                            >
                                                {participant_status_jsx}
                                            </Select>
                                        </Box>
                                    </Box>
                                    {this.state.study.custom_sp_attributes.length > 0 && (
                                        <Box mt={'32px'}>
                                            <Box className={`${SpDataLabelClassName} sp-data-label-top-border`} mb={3}>
                                                <br />
                                                Custom Attributes
                                            </Box>
                                            {this.renderCustomAttributes()}
                                        </Box>
                                    )}

                                    <div className="RichEditor-root">
                                        {this.state.tagManager.show ? this.renderTagManager() : ''}

                                        <div
                                            id="editor-wrapper"
                                            className={className}
                                            onClick={this.focus}
                                            // onMouseUp={this.textSelected}
                                            // onDoubleClick={this.textSelected}
                                            // onKeyUp={this.textSelected}
                                        >
                                            <Box className={SpDataLabelClassName}>Notes</Box>
                                            <Editor
                                                blockStyleFn={getBlockStyle}
                                                customStyleMap={styleMap}
                                                editorState={this.state.editorState}
                                                // handleKeyCommand={this.handleKeyCommand}
                                                onChange={this.onNotesChange}
                                                onTab={this.onTab}
                                                // eslint-disable-next-line react/no-string-refs
                                                ref="editor"
                                                placeholder="Start typing notes for this participant..."
                                            />
                                        </div>
                                    </div>
                                </Box>
                            )}

                            {this.state.sp_tab_selected == 'surveys' && (
                                <Box sx={{ padding: '0 32px' }}>
                                    {!this.state.study_person.screener_answers.length && (
                                        <Box className="text-secondary-dark center medium">No surveys answered.</Box>
                                    )}

                                    {this.state.study_person.screener_answers.map(screener_response => {
                                        //console.log('answer_data', JSON.parse(screener_response.answers))
                                        const s_obj = this.getScreener(screener_response.study_screener_id);
                                        return (
                                            <CollapsibleContentContainer
                                                show={0}
                                                title={
                                                    <span>
                                                        {this.getScreenerTitle(screener_response.study_screener_id)}
                                                    </span>
                                                }
                                            >
                                                {/* only allow the multi-screeners to be editable */}
                                                {screener_response && screener_response.study_screener_id && (
                                                    <Box mt={'16px'}>
                                                        <a
                                                            href={helpersScreener.getScreenerLinkNewForPerson(
                                                                s_obj.uuid,
                                                                this.props.auth.account,
                                                                this.state.study_person,
                                                                { defaultDomain: true }
                                                            )}
                                                            target="_blank"
                                                        >
                                                            <Button
                                                                variant="link"
                                                                type="button"
                                                                className="link-small bold"
                                                                mr={'24px'}
                                                            >
                                                                <FiExternalLink /> Open in window
                                                            </Button>
                                                        </a>

                                                        {this.state.editAnswersScreenerId ==
                                                        screener_response.study_screener_id ? (
                                                            <Button
                                                                variant="link"
                                                                type="button"
                                                                className="red link-small bold"
                                                                onClick={e => {
                                                                    this.setState({ editAnswersScreenerId: null });
                                                                }}
                                                            >
                                                                <FiTrash2 /> Cancel edit
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant="link"
                                                                type="button"
                                                                className="link-small bold"
                                                                onClick={e => {
                                                                    this.setState({
                                                                        editAnswersScreenerId:
                                                                            screener_response.study_screener_id
                                                                    });
                                                                }}
                                                            >
                                                                <FiEdit /> Edit answers
                                                            </Button>
                                                        )}
                                                    </Box>
                                                )}

                                                <Box style={{ fontSize: '13px !important' }}>
                                                    <ReactFormGenerator
                                                        data={this.getDataForScreener(
                                                            screener_response.study_screener_id
                                                        )}
                                                        answer_data={JSON.parse(screener_response.answers)}
                                                        read_only={
                                                            this.state.editAnswersScreenerId ==
                                                            screener_response.study_screener_id
                                                                ? false
                                                                : true
                                                        }
                                                        hide_actions={
                                                            this.state.editAnswersScreenerId ==
                                                            screener_response.study_screener_id
                                                                ? false
                                                                : true
                                                        }
                                                        onSubmit={(formAnswers, isQualified) => {
                                                            this.onEditScreenerSubmit(
                                                                screener_response.study_screener_id,
                                                                screener_response.id,
                                                                formAnswers,
                                                                isQualified
                                                            );
                                                        }}
                                                        is_submitting={this.state.screener_edit_is_submitting}
                                                        //show_qualification={this.isAdminView()}
                                                    />
                                                </Box>
                                            </CollapsibleContentContainer>
                                        );
                                    })}
                                </Box>
                            )}

                            {this.state.sp_tab_selected == 'profile' && (
                                <Box sx={{ padding: '0 32px' }}>
                                    <ParticipantSettings
                                        parentCallback={person => {
                                            const sp = { ...this.state.study_person };
                                            sp.person = person;
                                            this.setState({ study_person: sp });
                                        }}
                                        participant={this.state.study_person.person}
                                        user={this.props.auth.user}
                                        role={this.props.auth.account_role.type}
                                    />
                                </Box>
                            )}

                            {this.state.sp_tab_selected === 'files' && (
                                <Box sx={{ padding: '0 32px' }}>
                                    <AttachedFilesSection
                                        personId={this.state.study_person.person_id}
                                        studyId={this.state.study.id}
                                    />
                                </Box>
                            )}

                            {this.state.sp_tab_selected == 'pastpart' && (
                                <Box sx={{ padding: '0 32px' }}>
                                    <PastParticipation
                                        person_id={this.state.study_person.person_id}
                                        fetch_person={true}
                                    />
                                </Box>
                            )}

                            {this.state.sp_tab_selected == 'activity' && (
                                <Box sx={{ padding: '0 32px' }}>
                                    {personActivityRender.length ? personActivityRender : 'No activity'}
                                </Box>
                            )}
                        </Box>
                        <Box
                            width="480px"
                            style={{ position: 'relative', background: '#f7f7f7', borderRadius: '0 8px 8px 0' }}
                        >
                            <Flex style={{ flexDirection: 'column', height: '100%' }}>
                                {/*<Box
                                    style={{
                                        padding: '16px 16px 12px 16px',
                                        borderBottom: '1px solid #E7E8F1'
                                    }}
                                >
                                    <strong className="text-primary">Email Communication</strong>
                                </Box>*/}
                                <Box
                                    style={{ padding: '16px', overflow: 'scroll', fontSize: '14px', flexGrow: 1 }}
                                    ref={this.messagesEndRef}
                                >
                                    {render_emails_from_to}
                                </Box>
                                <Box style={{ padding: '0 16px 16px 16px' }}>
                                    {this.renderCommunicationStatus(this.state.study_person.person)}
                                    <Textarea
                                        className="theme-input-dropshadow"
                                        placeholder="Start typing to send a message"
                                        value={this.state.sendEmail_content}
                                        onChange={this.onChange_sendEmail_content}
                                        disabled={this.notAllowedToCommunicate(this.state.study_person.person)}
                                    ></Textarea>
                                    {!hasEmailingFeatureEnabled && (
                                        <Box mt={2} className="danger-box-small">
                                            <FiInfo /> {NOTIFICATION_TITLE_DISABLED_EMAILING}.{' '}
                                            {NOTIFICATION_CONTENT_DISABLED_EMAILING}
                                        </Box>
                                    )}
                                    {this.state.sendEmail_attachments.length > 0 && (
                                        <Box mt={2} fontSize={1}>
                                            {this.state.sendEmail_attachments.map((a, index) => {
                                                return (
                                                    <Flex className="fs-13">
                                                        <Flex
                                                            className="va-top"
                                                            style={{ marginTop: '8px', maxWidth: '75%' }}
                                                        >
                                                            <Box className="ellipsis">{a.filename}</Box>
                                                            <Box ml={1} style={{ color: '#999', flexShrink: 0 }}>
                                                                ({helpers.humanFileSize(a.filesize)})
                                                            </Box>
                                                        </Flex>
                                                        <Box>
                                                            <Button
                                                                type="button"
                                                                variant="transparent-icon"
                                                                ml={2}
                                                                onClick={() => {
                                                                    let { sendEmail_attachments } = this.state;
                                                                    sendEmail_attachments.splice(index, 1);
                                                                    this.setState({ sendEmail_attachments });
                                                                }}
                                                            >
                                                                <FiXCircle />
                                                            </Button>
                                                        </Box>
                                                    </Flex>
                                                );
                                            })}
                                        </Box>
                                    )}
                                    <Box mt={3} style={{ textAlign: 'right' }}>
                                        {helpersStudy.shouldShowEmailAttachmentButton(this.props.auth.account) ==
                                            true && (
                                            <Button
                                                type="button"
                                                variant="secondary-gray"
                                                className="secondary-icon va-top"
                                                mr={2}
                                                disabled={
                                                    this.state.email_attachment_isUploading ||
                                                    this.notAllowedToCommunicate(this.state.study_person.person)
                                                }
                                                onClick={this.emailAttachmentUploadButton}
                                            >
                                                {this.state.email_attachment_isUploading ? (
                                                    <LoadingIndicator relativePosition={true} />
                                                ) : (
                                                    <FiPaperclip />
                                                )}
                                            </Button>
                                        )}
                                        <input type="file" id="email_attachment_upload" hidden />
                                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                            <Button
                                                type="button"
                                                variant="secondary-gray"
                                                mr={0}
                                                onClick={() => {
                                                    this.setState({ showMsgOptions: true });
                                                }}
                                                disabled={
                                                    !!(
                                                        !this.state.sendEmail_content ||
                                                        !this.state.sendEmail_content.trim() ||
                                                        this.state.sendEmail_sending ||
                                                        this.notAllowedToCommunicate(this.state.study_person.person)
                                                    )
                                                }
                                            >
                                                Send message <FiChevronUp style={{ marginRight: 0 }} />
                                            </Button>
                                            {this.state.showMsgOptions === true && (
                                                <NiceDropdown
                                                    width="240px"
                                                    positionTop="auto"
                                                    positionBottom="38px"
                                                    positionRight="0px"
                                                    onClose={() => {
                                                        this.setState({ showMsgOptions: false });
                                                    }}
                                                    items={msgOptions}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                )}

                <NiceModal
                    isOpen={this.state.showModalSessionDatepicker}
                    onRequestClose={() => this.setState({ showModalSessionDatepicker: false })}
                    style={{ content: { width: 'auto', maxWidth: '1100px' } }}
                    title="Choose a date & time"
                >
                    <Box>
                        <DatePicker
                            inline
                            className={`theme-input`}
                            style={{ width: '100%' }}
                            showTimeInput
                            onChange={date => {
                                console.log('date', date);
                                this.setState({ session_custom_date: date });
                            }}
                            selected={this.state.session_custom_date}
                            timeInputLabel={`Time (${moment.tz(this.state.study.timezone).format('z')}):`}
                        />
                    </Box>
                    <Box className="modal-actions">
                        <Button
                            type="button"
                            variant="secondary-gray"
                            mr={3}
                            onClick={() => {
                                this.setState({ showModalSessionDatepicker: false });
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            type="submit"
                            variant="primary"
                            sx={{ marginRight: 0 }}
                            onClick={() => {
                                const formatted_date = moment(this.state.session_custom_date).format(
                                    'YYYY-MM-DD HH:mm'
                                );

                                this.sessionSpotDropdownOnChange(formatted_date);
                                this.setState({ showModalSessionDatepicker: false });
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </NiceModal>
                {this.state.isEditPaymentModalOpen && (
                    <ManualPaymentModal
                        isOpen
                        onClose={() => this.setState({ isEditPaymentModalOpen: false })}
                        title="Edit Payment"
                        person={this.state.study_person.person}
                        onSave={data => this.updateManualPayment(data)}
                        defaultValues={{
                            amount: this.state.study_person.incentive_total_paid,
                            date: this.state.study_person.incentive_sent_at
                        }}
                    />
                )}

                <PaymentModal
                    auth={this.props.auth}
                    isOpen={this.state.isPaymentModalOpen}
                    onClose={() => this.setState({ isPaymentModalOpen: false })}
                    onPay={data => this.payParticipant(data)}
                    title="Pay Incentive"
                    study={this.state.study}
                    payees={[this.state.study_person.person]}
                    defaultValues={{
                        amount: this.state.study.incentive
                    }}
                />
            </>
        );
    }
}

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2
    },
    HIGHLIGHT: {
        backgroundColor: '#faed27'
    },
    STRIKETHROUGH: {
        textDecoration: 'line-through'
    }
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote';
        default:
            return null;
    }
}

// eslint-disable-next-line react/no-multi-comp
// class StyleButton extends React.Component {
//     constructor() {
//         super();
//         this.onToggle = e => {
//             e.preventDefault();
//             this.props.onToggle(this.props.style);
//         };
//     }

//     render() {
//         let className = 'RichEditor-styleButton';
//         if (this.props.active) {
//             className += ' RichEditor-activeButton';
//         }
//         return (
//             <span className={className} onMouseDown={this.onToggle}>
//                 {this.props.label}
//             </span>
//         );
//     }
// }

// const BLOCK_TYPES = [
//     {
//         label: 'H1',
//         style: 'header-one'
//     },
//     {
//         label: 'H2',
//         style: 'header-two'
//     },
//     {
//         label: 'H3',
//         style: 'header-three'
//     },
//     {
//         label: 'Blockquote',
//         style: 'blockquote'
//     },
//     {
//         label: 'UL',
//         style: 'unordered-list-item'
//     },
//     {
//         label: 'OL',
//         style: 'ordered-list-item'
//     },
//     {
//         label: 'Code Block',
//         style: 'code-block'
//     }
// ];

// const BlockStyleControls = props => {
//     const { editorState } = props;
//     const selection = editorState.getSelection();
//     const blockType = editorState
//         .getCurrentContent()
//         .getBlockForKey(selection.getStartKey())
//         .getType();
//     return (
//         <span className="RichEditor-controls">
//             {BLOCK_TYPES.map(type => (
//                 <StyleButton
//                     key={type.label}
//                     active={type.style === blockType}
//                     label={type.label}
//                     onToggle={props.onToggle}
//                     style={type.style}
//                 />
//             ))}
//         </span>
//     );
// };

// const INLINE_STYLES = [
//     {
//         label: 'Bold',
//         style: 'BOLD'
//     },
//     {
//         label: 'Italic',
//         style: 'ITALIC'
//     },
//     {
//         label: 'Underline',
//         style: 'UNDERLINE'
//     },
//     {
//         label: 'Monospace',
//         style: 'CODE'
//     },
//     {
//         label: 'Highlight',
//         style: 'HIGHLIGHT'
//     },
//     {
//         label: 'Strikethrough',
//         style: 'STRIKETHROUGH'
//     }
// ];

// const InlineStyleControls = props => {
//     const currentStyle = props.editorState.getCurrentInlineStyle();
//     return (
//         <span className="RichEditor-controls">
//             {INLINE_STYLES.map(type => (
//                 <StyleButton
//                     key={type.label}
//                     active={currentStyle.has(type.style)}
//                     label={type.label}
//                     onToggle={props.onToggle}
//                     style={type.style}
//                 />
//             ))}
//         </span>
//     );
// };

export default withToastManager(ParticipantNotes);
